import { logError } from '../Error/errorService'
import AuthenticationResult from './AuthenticationResult'
import { getEnvironmentVariables } from './getEnvironmentVariables'

export const resetPassword = async (
	email: string
): Promise<AuthenticationResult> => {
	try {
		const { clientId, resetPasswordUrl, connection } = getEnvironmentVariables()

		const details = {
			client_id: clientId,
			email,
			connection,
		}

		const response = await fetch(resetPasswordUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(details),
		})

		if (!response.ok) {
			const { error_description } = await response.json()
			return {
				success: false,
				message: error_description,
			}
		}

		const message = await response.text()

		return { success: true, message }
	} catch (e) {
		logError({
			message: e.message,
			name: e.name,
		})
		const { message } = e
		return {
			success: false,
			message,
		}
	}
}
