import { getAccessToken } from '../authentication'
import { AuthenticationError } from './AuthenticationError'

export const authenticatedFetch = async (
	input: RequestInfo,
	init?: RequestInit
): Promise<Response> => {
	const accessToken = await getAccessToken()

	if (accessToken === null) {
		throw new AuthenticationError()
	}

	const oldInit = init || {}
	const oldHeaders = init?.headers || {}

	const response = await fetch(input, {
		...oldInit,
		headers: {
			...oldHeaders,
			Authorization: `Bearer ${accessToken}`,
		},
	})

	if (response.status === 401) {
		throw new AuthenticationError()
	}

	return response
}
