import React from 'react'
import { useSelector } from 'react-redux'
import { selectCoordinates } from '../../../redux/assessment/flow/selectors'
import { UserLocationResult } from '../../../services/location/locationService'

export const LocationWarning: React.FunctionComponent = () => {
	const location = useSelector(selectCoordinates)

	if (location.result === UserLocationResult.PermissionDenied) {
		return (
			<p className="text-error">
				You have declined location permission. This will prevent submission of
				the form.
			</p>
		)
	}

	if (location.result === UserLocationResult.None) {
		return (
			<p className="text-error">
				Attempting to get your location, please wait before submitting. Please
				note that location permission must be provided if supported by your
				device.
			</p>
		)
	}

	return null
}
