import React from 'react'
import PageBanner, { PageBannerProps } from '../../components/PageBanner'
import withAsyncAction from '../../components/withAsyncAction'
import { withLoading } from '../../components/FullscreenLoading'
import { withError } from '../../components/FullscreenError'
import RecentRiskAssessment from '../../redux/assessment/recentAssessments/RecentRiskAssessments'
import { useSelector } from 'react-redux'
import { selectPdfState } from '../../redux/assessment/pdfAssessment/selectors'
import { getGreeting } from './date'
import RecentAssessments from './recent-assessments/RecentAssessments'
import RecentIncidents from './recent-incidents/RecentIncidents'
import RecentIncidentReport from '../../redux/incident/recentIncidents/RecentIncidentReport'
import { requestRecentRiskAssessmentsAndRecentIncidents } from '../../redux/actions'
import { formatDate } from '../../format/date'

interface Props {
	data: {
		riskAssessments: RecentRiskAssessment[]
		incidentReports: RecentIncidentReport[]
	}
}

export const Home: React.FunctionComponent<Props> = ({ data }: Props) => {
	const today = new Date()
	const { fetchError } = useSelector(selectPdfState)

	return (
		<div className="flex flex-col h-full">
			<PageBanner
				title={getGreeting(today)}
				subtitle={formatDate(today)}
				colorClass="secondary-7"
				subtitleColorClass="primary-2"
			/>
			<div className="h-full overflow-auto">
				<RecentAssessments recentAssessments={data.riskAssessments} />
				<RecentIncidents recentIncidents={data.incidentReports} />
			</div>
			{fetchError && fetchError.length > 0 && (
				<div className="flex flex-col items-center mb-6">
					<div className="body-sm text-error text-center mt-3 px-5">
						{fetchError}
					</div>
				</div>
			)}
		</div>
	)
}

const bannerProps: PageBannerProps = {
	title: getGreeting(new Date()),
	subtitle: formatDate(new Date()),
	colorClass: 'secondary-2',
	subtitleColorClass: 'primary-2',
}

export default withAsyncAction<{
	riskAssessments: RecentRiskAssessment[]
	incidentReports: RecentIncidentReport[]
}>({
	fetchAction: () => requestRecentRiskAssessmentsAndRecentIncidents(),
	loadingComponent: withLoading(bannerProps),
	errorComponent: withError({
		bannerProps,
		formatError: (error) => `Failed to load Home Page - ${error.toLowerCase()}`,
	}),
	dataComponent: Home,
	selectState: (state) => ({
		errorMessage:
			state.assessment.recentRiskAssessments.fetchError ||
			state.incident.recentIncidentReports.error,
		inProgress:
			state.assessment.recentRiskAssessments.isFetching ||
			state.incident.recentIncidentReports.isFetching,
		data: {
			riskAssessments: state.assessment.recentRiskAssessments.riskAssessments,
			incidentReports: state.incident.recentIncidentReports.incidentReports,
		},
	}),
})
