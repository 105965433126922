import React from 'react'
import Colors from '../Colors'

// eslint-disable-next-line @typescript-eslint/ban-types
export type IconComponent<T = {}> = React.FunctionComponent<
	React.SVGProps<SVGSVGElement> & { thickness?: number } & T
>

// eslint-disable-next-line @typescript-eslint/ban-types
export const wrapIcon = <T extends Record<string, unknown> = {}>(
	icon: IconComponent<T>
): IconComponent<T> => {
	const Icon = icon

	const WrappedIcon: IconComponent<T> = (props) => (
		<Icon
			fill={props.fill || 'none'}
			stroke={props.stroke || Colors.primary[1]}
			{...props}
		/>
	)
	return WrappedIcon
}

export const calculateStrokeWidth = (
	thickness = 1,
	multiplier = 32
): string => {
	return `${thickness * multiplier * 0.5}px`
}
