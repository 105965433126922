import { combineReducers } from 'redux'
import metadata from './metadata/reducer'
import flow from './flow/reducer'
import recentRiskAssessments from './recentAssessments/reducer'
import pdfAssessment from './pdfAssessment/reducer'
import { SET_ASSESSMENT_STAGE, RESET_ASSESSMENT_STAGE } from './actionTypes'
import { AssessmentStage, ASSESSMENT_STAGE_CONFIG } from './AssessmentStage'
import Action from '../Action'

export interface AssessmentRootState {
	stage: AssessmentStage
}

const initialState: AssessmentRootState = {
	stage: ASSESSMENT_STAGE_CONFIG,
}

export const assessmentRoot = (
	state = initialState,
	action: Action<AssessmentStage>
): AssessmentRootState => {
	switch (action.type) {
		case SET_ASSESSMENT_STAGE: {
			return {
				...state,
				stage: action.payload as AssessmentStage,
			}
		}
		case RESET_ASSESSMENT_STAGE: {
			return initialState
		}
		default: {
			return state
		}
	}
}

const assessment = combineReducers({
	metadata,
	flow,
	recentRiskAssessments,
	pdfAssessment,
	assessmentRoot,
})

export default assessment
