type SynchronisedPromiseResolver<T> = (result: T) => void
type SynchronisedPromiseRejecter<T> = (reason: T) => void

export class SynchronisedPromise<T> {
	running = false

	private resolvers: SynchronisedPromiseResolver<T>[] = []
	private rejecters: SynchronisedPromiseRejecter<T>[] = []

	spawn(): Promise<T> {
		return new Promise<T>((resolve) => {
			this.resolvers.push(resolve)
		})
	}

	start(): void {
		this.running = true
	}

	resolve(value: T): void {
		for (const resolver of this.resolvers) {
			resolver(value)
		}

		this.cleanup()
	}

	reject(value: T): void {
		for (const rejecter of this.rejecters) {
			rejecter(value)
		}

		this.cleanup()
	}

	private cleanup() {
		this.resolvers = []
		this.rejecters = []
		this.running = false
	}
}
