import React from 'react'
import SafeForm from '../../components/SafeForm'
import useListState from '../../hooks/useListState'
import { useValueWithValidator } from '../../hooks/useValueWithError'
import FolderIcon from '../../icons/FolderIcon'
import PencilIcon from '../../icons/PencilIcon'
import EmailsEntry, { EmailData } from './EmailsEntry'
import RiskAssessmentBanner from './RiskAssessmentBanner'
import SuggestionFormField from './SuggestionFormField'
import * as validation from './validation'
import {
	HSRep,
	MetadataValues,
} from '../../redux/assessment/metadata/MetadataState'
import Profile from '../profile/Profile'
import { EmailedStatus } from '../../redux/emailedStatus'
import { getLocationRepresentative } from '../profile/lookupContact'

const RiskAssessmentConfigTemplate: React.FunctionComponent<{
	profile: Profile
	referenceSuggestions: string[]
	titleSuggestions: string[]
	emailSuggestions: string[]
	metadataValues: MetadataValues
	handleSubmit: (
		reference: string,
		title: string,
		forwardingEmails: string[],
		hsRep: HSRep,
		status: EmailedStatus,
		failedEmails: string[]
	) => void
	maxSuggestions?: number
	maxEmails?: number
}> = ({
	profile,
	referenceSuggestions,
	titleSuggestions,
	emailSuggestions,
	metadataValues,
	handleSubmit,
	maxSuggestions = 5,
	maxEmails = 5,
}) => {
	const [
		reference,
		setReference,
		referenceError,
		setReferenceError,
		validateReference,
	] = useValueWithValidator<string>(
		metadataValues.projectReference,
		validation.validateProjectReference
	)
	const [
		title,
		setTitle,
		titleError,
		setTitleError,
		validateTitle,
	] = useValueWithValidator<string>(
		metadataValues.title,
		validation.validateTitle
	)

	const status = EmailedStatus.PENDING
	const failedEmails: string[] = []

	const createEmailData = (): EmailData[] => {
		let emailList: EmailData[] = []
		if (metadataValues.forwardingEmails.length === 0) {
			emailList.push({
				email: '',
				error: '',
				key: '',
			})
		} else {
			emailList = metadataValues.forwardingEmails.map((email) => ({
				email: email,
				error: '',
				key: email,
			}))
		}
		return emailList
	}

	const [emails, setEmails, listActions] = useListState(
		createEmailData(),
		(entry, entries) => [entry, ...entries]
	)

	const validateEmails = (): boolean => {
		const uniqueEmails = new Set<string>()

		const newEmailData = emails.map((data) => {
			const error = validation.validateForwardingEmail(data, uniqueEmails)
			return {
				...data,
				error: error || '',
			}
		})
		setEmails(newEmailData)
		return !newEmailData.some((data) => data.error.length > 0)
	}
	const validateData = (): boolean => {
		const referenceValidated = validateReference()
		const titleValidated = validateTitle()
		const emailsValidated = validateEmails()
		return referenceValidated && titleValidated && emailsValidated
	}

	const notUsedEmailSuggestions = emailSuggestions.filter(
		(suggestion) =>
			!emails.some(
				(data) => data.email.toLowerCase().trim() === suggestion.toLowerCase()
			)
	)
	const hsRep = getLocationRepresentative(
		profile.operatingUnit,
		profile.primaryWorkLocation
	)

	return (
		<div className="flex flex-col h-full">
			<RiskAssessmentBanner />
			<SafeForm
				className="h-full overflow-auto"
				onSubmit={() => {
					if (validateData()) {
						handleSubmit(
							reference,
							title,
							emails.map((data) => data.email),
							hsRep || { name: '', email: '', phoneNumber: '' },
							status,
							failedEmails
						)
					}
				}}
				noValidate
			>
				<div className="flex-1">
					<div className="p-4 mt-4">
						<h2 className="body-lg">Project Reference</h2>
						<div className="w-48 mb-8">
							<SuggestionFormField
								icon={FolderIcon}
								value={reference}
								setValue={setReference}
								error={referenceError}
								setError={setReferenceError}
								suggestions={referenceSuggestions}
								maxSuggestions={maxSuggestions}
								inputProps={{
									type: 'text',
									name: 'project-reference',
								}}
							/>
						</div>

						<h2 className="body-lg">Title</h2>
						<div className="mr-8 mb-12">
							<SuggestionFormField
								icon={PencilIcon}
								value={title}
								setValue={setTitle}
								error={titleError}
								setError={setTitleError}
								suggestions={titleSuggestions}
								maxSuggestions={maxSuggestions}
								inputProps={{
									type: 'text',
									name: 'project-title',
								}}
							/>
						</div>

						<h2 className="body-lg">Forwarding email address</h2>
						<h3 className="body-xs-thin">
							Enter the project manager and relevant BMT employees email
							address. The HSE Rep will automatically be emailed.
						</h3>
						<EmailsEntry
							emails={emails}
							actions={listActions}
							maxItems={maxEmails}
						/>
					</div>
				</div>
				<h3 className="text-center flex flex-col mb-3 text-secondary-10 body-xs-thin">
					Please ensure you haven&apos;t entered any sensitive information
				</h3>
				<div className="flex flex-col items-center">
					<button className="btn-primary mb-10">Next</button>
				</div>
			</SafeForm>
		</div>
	)
}

export default RiskAssessmentConfigTemplate
