import { call, put, select, takeEvery } from 'redux-saga/effects'
import { logError } from '../../../services/Error/errorService'
import { getRecentIncidentReports } from '../../../services/incident/incidentService'
import { serviceRequestSaga } from '../../services/sagas'
import { selectIsWorkingOffline } from '../../users/selectors'
import {
	setFetchingRecentIncidentReports,
	setFetchRecentIncidentReportsError,
	setRecentIncidentReports,
} from './actions'
import { FETCH_RECENT_INCIDENT_REPORTS } from './actionTypes'
import RecentIncidentReport from './RecentIncidentReport'

export function* fetchRecentIncidentReports(): Generator<
	unknown,
	void,
	unknown
> {
	yield put(setFetchingRecentIncidentReports(true))
	yield put(setFetchRecentIncidentReportsError(''))

	const isOffline = (yield select(selectIsWorkingOffline) as unknown) as boolean
	try {
		if (isOffline) {
			yield put(setRecentIncidentReports([]))
		} else {
			const recentIncidentReports = (yield call(
				serviceRequestSaga,
				getRecentIncidentReports
			) as unknown) as RecentIncidentReport[]

			yield put(setRecentIncidentReports(recentIncidentReports))
		}
	} catch (e) {
		logError({
			message: e.message,
			name: e.name,
		})
		yield put(setFetchRecentIncidentReportsError(e.message))
		yield put(setRecentIncidentReports([]))
	} finally {
		yield put(setFetchingRecentIncidentReports(false))
	}
}

export default [
	takeEvery(FETCH_RECENT_INCIDENT_REPORTS, fetchRecentIncidentReports),
]
