import Profile from '../../screens/profile/Profile'
import Action from '../Action'
import { SET_INCIDENT_REPORT_SUBMISSION_ERROR } from '../incident/submission/actionType'
import {
	SAVE_DETAILS_ERROR,
	SET_SUBMITTING,
	SET_USER_PROFILE,
	SET_FETCHING_PROFILE,
	SET_FETCH_ERROR,
	SET_USER_PROFILE_EMAIL,
} from './actionTypes'
import SaveDetailsErrorPayload from './SaveDetailsErrorPayload'
import SubmittingPayload from './SubmittingPayload'
import UserProfileState from './userProfileState'

const initialState: UserProfileState = {
	profile: null,
	fetchError: null,
	isFetching: false,
	error: null,
	isSubmitting: false,
}

const userProfile = (
	state = initialState,
	action: Action<unknown>
): UserProfileState => {
	switch (action.type) {
		case SAVE_DETAILS_ERROR: {
			const { message } = action.payload as SaveDetailsErrorPayload
			return {
				...state,
				error: message,
			}
		}
		case SET_USER_PROFILE: {
			const profile = action.payload as Profile

			return {
				...state,
				profile,
			}
		}
		case SET_USER_PROFILE_EMAIL: {
			let profile: Profile | null = state.profile
			const email = action.payload as string

			if (profile === null) {
				profile = {
					name: '',
					email,
					countryCode: '',
					phoneNumber: '',
					operatingUnit: '',
					primaryWorkLocation: '',
				}
			} else {
				profile.email = email
			}

			return {
				...state,
				profile,
			}
		}
		case SET_FETCHING_PROFILE: {
			return {
				...state,
				isFetching: action.payload as boolean,
			}
		}
		case SET_FETCH_ERROR: {
			return {
				...state,
				fetchError: action.payload as string | null,
			}
		}
		case SET_SUBMITTING: {
			const { submitting } = action.payload as SubmittingPayload
			return {
				...state,
				isSubmitting: submitting,
			}
		}
		default: {
			return state
		}
	}
}

export default userProfile
