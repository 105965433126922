import {
	START_AUTHENTICATION,
	AUTHENTICATION_SUCCEEDED,
	LOG_OUT_SUCCEEDED,
	AUTHENTICATION_FAILED,
	START_RESET_PASSWORD,
	RESET_PASSWORD_SUCCEEDED,
	RESET_PASSWORD_FAILED,
	START_CHANGE_PASSWORD,
	CHANGE_PASSWORD_SUCCEEDED,
	CHANGE_PASSWORD_FAILED,
	CLEAR_ERROR_MESSAGE,
	CLEAR_RESET_PASSWORD,
	WORKING_OFFLINE,
	SET_HAS_CONNECTIVITY,
	REFRESH_TOKEN_EXPIRED,
	SIGN_UP_FAILED,
	SIGN_UP_SUCCEEDED,
	START_SIGN_UP,
	RESET_REGISTER_PAGE,
} from './actionTypes'
import Action from '../Action'
import RequestFailedPayload from './RequestFailedPayload'
import UserState from './UserState'

const initialState = (): UserState => ({
	isLoggedIn: false,
	isTokenValid: false,
	hasConnectivity: false,
	isLoading: false,
	isResetting: false,
	isReset: false,
	signUpSuccess: false,
	error: null,
})

const users = (state = initialState(), action: Action<unknown>): UserState => {
	switch (action.type) {
		case START_AUTHENTICATION: {
			return {
				...state,
				isLoggedIn: false,
				isLoading: true,
				error: null,
			}
		}
		case AUTHENTICATION_SUCCEEDED: {
			return {
				...state,
				isLoggedIn: true,
				isTokenValid: true,
				isLoading: false,
				error: null,
			}
		}
		case LOG_OUT_SUCCEEDED: {
			return {
				...state,
				isLoggedIn: false,
				isLoading: false,
				isTokenValid: false,
			}
		}
		case AUTHENTICATION_FAILED: {
			const { message } = action.payload as RequestFailedPayload
			return {
				...state,
				isLoggedIn: false,
				isTokenValid: false,
				isLoading: false,
				error: message,
			}
		}
		case REFRESH_TOKEN_EXPIRED: {
			return {
				...state,
				isLoggedIn: false,
				isTokenValid: false,
				isLoading: false,
				error: 'Your session has expired. Please log in again.',
			}
		}
		case CLEAR_ERROR_MESSAGE: {
			return {
				...state,
				error: null,
			}
		}
		case START_RESET_PASSWORD: {
			return {
				...state,
				isResetting: true,
				isReset: false,
				error: null,
			}
		}
		case RESET_PASSWORD_SUCCEEDED: {
			return {
				...state,
				isResetting: false,
				isReset: true,
				error: null,
			}
		}
		case RESET_PASSWORD_FAILED: {
			const { message } = action.payload as RequestFailedPayload
			return {
				...state,
				isResetting: false,
				isReset: false,
				error: message,
			}
		}
		case CLEAR_RESET_PASSWORD: {
			return {
				...state,
				isResetting: false,
				isReset: false,
				error: null,
			}
		}
		case START_CHANGE_PASSWORD: {
			return {
				...state,
				isLoading: true,
				error: null,
			}
		}
		case CHANGE_PASSWORD_SUCCEEDED: {
			return {
				...state,
				isLoading: false,
				error: null,
			}
		}
		case CHANGE_PASSWORD_FAILED: {
			const { message } = action.payload as RequestFailedPayload
			return {
				...state,
				isLoading: false,
				error: message,
			}
		}
		case SET_HAS_CONNECTIVITY: {
			const newHasConnectivity = action.payload as boolean

			return {
				...state,
				hasConnectivity: newHasConnectivity,
			}
		}
		case WORKING_OFFLINE: {
			return {
				...state,
				isLoggedIn: true,
			}
		}
		case START_SIGN_UP: {
			return {
				...state,
				isLoading: true,
				error: null,
				signUpSuccess: false,
			}
		}
		case SIGN_UP_FAILED: {
			const { message } = action.payload as RequestFailedPayload
			return {
				...state,
				isLoading: false,
				error: message,
			}
		}
		case SIGN_UP_SUCCEEDED: {
			return {
				...state,
				isLoading: false,
				signUpSuccess: true,
			}
		}
		case RESET_REGISTER_PAGE: {
			return {
				...state,
				signUpSuccess: false,
			}
		}
		default: {
			return state
		}
	}
}

export default users
