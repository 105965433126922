import AuthenticationResult from './AuthenticationResult'
import { getEnvironmentVariables } from './getEnvironmentVariables'
import { authenticate } from './authenticate'
import {
	INVALID_PASSWORD,
	INVALID_SIGNUP,
	INVALID_PASSWORD_TEXT,
} from './constants'

export const register = async (
	username: string,
	email: string,
	password: string
): Promise<AuthenticationResult> => {
	try {
		const { clientId, signupUrl, connection } = getEnvironmentVariables()

		const details = {
			client_id: clientId,
			username,
			email,
			password,
			connection,
		}

		const response = await fetch(signupUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(details),
		})

		if (!response.ok) {
			const { code, error } = await response.json()

			if (code === INVALID_PASSWORD) {
				return {
					success: false,
					message: INVALID_PASSWORD_TEXT,
				}
			}

			if (code === INVALID_SIGNUP) {
				return {
					success: false,
					message: 'User already exists, do you wish to log in?',
				}
			}

			return {
				success: false,
				message: error,
			}
		}

		return {
			success: true,
			message:
				'User successfully created. Please verify your email address and then log in',
		}
	} catch (e) {
		const { message } = e
		return {
			success: false,
			message,
		}
	}
}
