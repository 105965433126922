import emailValidator from 'email-validator'
import { EmailData } from './EmailsEntry'

export const BMT_DOMAINS = [
	'@bmtdas.co.uk',
	'@bmtdp.com',
	'@dandp.com',
	'@bmtdt.com.au',
	'@fleetech.com',
	'@bmtglobal.com',
	'@lateral.engineering',
	'@bmtdsl.co.uk',
]

export const validateForwardingEmail = (
	data: EmailData,
	uniqueEmails: Set<string>
): string | null => {
	const { email } = data
	const trimmedEmail = email.trim().toLowerCase()

	if (uniqueEmails.has(trimmedEmail)) {
		return 'Please specify a unique email address'
	}
	uniqueEmails.add(trimmedEmail)

	if (trimmedEmail.length === 0) {
		return 'Please enter an email address'
	}
	if (!emailValidator.validate(email)) {
		return 'Please enter a valid email address'
	}
	if (!BMT_DOMAINS.some((domain) => trimmedEmail.endsWith(domain))) {
		return 'Please enter a BMT email address'
	}
	return null
}

export const validateProjectReference = (
	projectReference: string
): string | null => {
	if (projectReference.trim().length === 0) {
		return 'Please enter a project reference'
	}
	return null
}

export const VALIDATION_ERROR_TITLE_MISSING = 'Please enter a project title'
export const VALIDATION_ERROR_TITLE_TOO_LONG =
	'Title has a limit of 70 characters'
export const VALIDATION_TITLE_MAX_LENGTH = 70

export const validateTitle = (title: string): string | null => {
	const trimmed = title.trim()
	if (trimmed.length === 0) {
		return VALIDATION_ERROR_TITLE_MISSING
	} else if (trimmed.length > VALIDATION_TITLE_MAX_LENGTH) {
		return VALIDATION_ERROR_TITLE_TOO_LONG
	}

	return null
}
