import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '../../../components/Dialog'
import { closeLocationPrompt } from '../../../redux/assessment/flow/actions'
import { RiskAssessmentFlowState } from '../../../redux/assessment/flow/FlowState'
import RootState from '../../../redux/RootState'

export const LocationPrompt: React.FunctionComponent = () => {
	const dispatch = useDispatch()
	const flow = useSelector<RootState, RiskAssessmentFlowState | null>(
		(x) => x.assessment.flow
	)
	const shouldShowLocationPrompt = flow?.showLocationPrompt

	const onClick = useCallback(() => {
		if (flow === null) {
			return
		}

		dispatch(closeLocationPrompt())
	}, [dispatch, flow])

	if (shouldShowLocationPrompt !== true) {
		return null
	}

	return (
		<Dialog title="Location required" bannerColorClass="secondary-2">
			<p className="text-primary-1 m-4 mt-4">
				This assessment can&apos;t be submitted as location data has been
				disabled. <br />
				Please enable location data before trying again.
			</p>

			<div className="flex justify-center mt-4 mb-4">
				<button
					className="btn-primary-popup"
					onClick={onClick}
					aria-label="Save"
					role="button"
				>
					Save
				</button>
			</div>
		</Dialog>
	)
}
