import { SelectOptions } from '../../../components/Selector'

export const getIncidentIllnesses = (): SelectOptions =>
	incidentIllnessList.map((incidentIllness) => ({
		value: incidentIllness,
		label: incidentIllness.length ? incidentIllness : 'Nature of illness',
	}))

export enum IncidentIllness {
	CompressionOrDecompression = 'Compression or decompression',
	CardiovascularDisease = 'Cardiovascular disease',
	Fatigue = 'Fatigue',
	HandarmVibrationSyndrome = 'Hand-arm vibration syndrome (HAVS)',
	HearingImpairment = 'Hearing Impairment',
	HeatExposureOrHeatExhaustionOrHeatstroke = 'Heat exposure, heat exhaustion, heatstroke',
	MusculoskeletalDisorders = 'Musculoskeletal Disorders',
	Poisoning = 'Poisoning',
	PostTraumaticStressInjury = 'Post Traumatic Stress Injury (PTSI)',
	RespiratoryConditionsDueToParticulateAgents = 'Respiratory conditions due to particulate agents',
	SkinDiseasesAndOrDermatitis = 'Skin diseases and or dermatitis',
	VisionImpairment = 'Vision impairment',
	Viruses = 'Viruses',
}

const incidentIllnessList: string[] = ['', ...Object.values(IncidentIllness)]
