import React from 'react'
import { IconComponent } from '../icons/IconComponent'

const styleDark: React.CSSProperties = {
	fill: 'white',
}

const styleLight: React.CSSProperties = {
	fill: '#24b8c9',
}

const BMTLogoDark: IconComponent = (props) => (
	<svg {...props} viewBox="0 0 73 32">
		<path
			style={styleDark}
			d="M9.6 5.879c-1.488 0.558-2.828 1.34-3.944 2.419l-0.074 0.037v0.074c-0.074 1.898 0.186 3.646 0.707 5.209l0.074 0.186 0.149-0.112c0.558-0.372 1.154-0.633 1.823-0.781l0.112-0.037v-0.112c0.037-0.781 0.112-1.563 0.223-2.344 0.223-1.451 0.595-2.94 1.079-4.354l0.112-0.298-0.26 0.112z"
		></path>
		<path
			style={styleDark}
			d="M4.577 9.451c-1.079 1.451-1.898 3.014-2.381 4.688v0.074l0.037 0.074c0.223 0.372 0.484 0.744 0.781 1.079 0.335 0.372 0.707 0.744 1.079 1.079l0.149 0.112 0.074-0.186c0.335-0.744 0.781-1.414 1.377-1.972l0.074-0.074-0.037-0.186-0.074-0.186c-0.484-1.34-0.744-2.828-0.819-4.428v-0.447l-0.26 0.372z"
		></path>
		<path
			style={styleDark}
			d="M1.86 15.516c-0.149 0.744-0.223 1.526-0.223 2.27 0 0.335 0 0.707 0.037 1.042v0.112l0.112 0.037c0.595 0.223 1.228 0.372 1.86 0.447l0.186 0.037-0.037-0.67c0-0.447 0.037-0.893 0.149-1.34l0.037-0.112-0.074-0.074c-0.521-0.409-1.042-0.856-1.451-1.377l-0.521-0.633-0.074 0.26z"
		></path>
		<path
			style={styleDark}
			d="M1.86 20.019c0.112 0.633 0.298 1.265 0.521 1.86l0.037 0.149 0.149-0.074c0.521-0.223 1.042-0.447 1.526-0.67l0.112-0.074-0.037-0.149c-0.074-0.26-0.149-0.521-0.223-0.819l-0.037-0.112h-0.112c-0.595-0.074-1.191-0.186-1.786-0.335l-0.26-0.074 0.112 0.298z"
		></path>
		<path
			style={styleDark}
			d="M17.488 5.619c-1.6 1.786-3.014 3.609-4.167 5.433-0.447 0.707-0.856 1.414-1.228 2.121l-0.074 0.149 0.149 0.074c0.558 0.298 1.079 0.67 1.526 1.153l0.112 0.112 0.112-0.074c1.674-1.34 3.386-2.642 5.098-3.907 1.191-0.856 2.419-1.712 3.646-2.53l0.186-0.112-0.186-0.112c-1.526-1.079-3.237-1.86-5.023-2.307l-0.074-0.037-0.074 0.037z"
		></path>
		<path
			style={styleDark}
			d="M13.47 5.135c-0.893 0.037-1.749 0.149-2.605 0.372h-0.074l-0.037 0.074c-0.633 1.637-1.079 3.274-1.34 4.912-0.112 0.707-0.186 1.414-0.223 2.121v0.149h0.409c0.521 0 1.079 0.074 1.6 0.223l0.112 0.037 0.074-0.112c0.409-0.744 0.819-1.488 1.302-2.233 1.079-1.712 2.381-3.46 3.87-5.135l0.186-0.223-0.26-0.037c-1.042-0.149-2.046-0.186-3.014-0.149z"
		></path>
		<path
			style={styleDark}
			d="M4.391 22.028c-0.484 0.223-1.005 0.447-1.526 0.633l-0.149 0.074 0.074 0.149c0.26 0.521 0.558 1.005 0.856 1.488l0.112 0.186 0.149-0.186c0.335-0.484 0.707-0.93 1.116-1.377l0.074-0.074-0.074-0.112c-0.149-0.223-0.298-0.484-0.409-0.707l-0.074-0.149-0.149 0.074z"
		></path>
		<path
			style={styleDark}
			d="M9.526 26.233c0.112 0.633 0.223 1.265 0.372 1.898l0.186 0.707h0.112c1.377 0.26 2.753 0.223 4.093-0.074l0.223-0.037-0.149-0.186c-0.558-0.744-1.116-1.526-1.637-2.307l-0.037-0.074h-0.112c-0.409 0.074-0.856 0.074-1.34 0.074-0.521 0-1.042-0.037-1.563-0.149l-0.223-0.037 0.074 0.186z"
		></path>
		<path
			style={styleDark}
			d="M6.995 25.116c-0.186 0.67-0.335 1.377-0.447 2.121v0.112l0.074 0.074c0.26 0.186 0.484 0.335 0.744 0.521v0 0c0.521 0.26 1.079 0.484 1.637 0.633l0.26 0.074-0.112-0.409c-0.186-0.781-0.335-1.6-0.447-2.381v-0.074l-0.074-0.037c-0.484-0.186-0.967-0.409-1.377-0.707l-0.186-0.112-0.074 0.186z"
		></path>
		<path
			style={styleDark}
			d="M5.433 23.665c-0.372 0.447-0.744 0.93-1.116 1.414l-0.074 0.112 0.074 0.112c0.409 0.484 0.856 0.93 1.34 1.377l0.186 0.186 0.037-0.26c0.112-0.67 0.298-1.34 0.447-1.972 0 0 0-0.037 0-0.037l0.037-0.112-0.074-0.074-0.372-0.335-0.447-0.484-0.037 0.074z"
		></path>
		<path
			style={styleDark}
			d="M21.135 0.447c-0.707 0.633-1.34 1.228-1.972 1.86-0.67 0.67-1.34 1.34-1.972 2.046-1.265-0.26-2.53-0.372-3.758-0.335-0.558 0.037-1.116 0.074-1.674 0.186 0.298-0.67 0.633-1.34 1.005-1.972s0.744-1.302 1.191-1.935c2.307-0.447 4.8-0.372 7.181 0.149z"
		></path>
		<path
			style={styleDark}
			d="M4.837 5.209c0 0 0.037-0.037 0.037-0.037-0.112 0.558-0.186 1.154-0.26 1.712-0.037 0.298-0.074 0.633-0.074 0.967-0.223 0.223-0.409 0.409-0.595 0.633v0c0 0 0 0 0 0-0.967 1.191-1.712 2.456-2.27 3.833-0.149-0.409-0.26-0.819-0.372-1.265 0.819-2.084 2.009-4.056 3.535-5.842z"
		></path>
		<path
			style={styleDark}
			d="M0.558 13.358c0.149 0.335 0.298 0.67 0.446 0.967-0.298 1.153-0.447 2.307-0.447 3.46 0 0.074 0 0.149 0 0.223-0.186-0.112-0.372-0.223-0.558-0.372 0-1.414 0.186-2.865 0.558-4.279z"
		></path>
		<path
			style={styleDark}
			d="M11.907 0.781c-0.149 0.26-0.298 0.521-0.447 0.744-0.558 1.005-1.042 2.009-1.488 3.051-1.414 0.409-2.716 1.042-3.87 1.898 0.149-0.967 0.335-1.972 0.633-3.014 1.526-1.228 3.274-2.121 5.172-2.679z"
		></path>
		<path
			style={styleDark}
			d="M1.86 23.442c0.298 0.595 0.633 1.191 1.042 1.749-0.186 0.223-0.335 0.484-0.484 0.707-0.446-0.707-0.856-1.451-1.191-2.195 0.186-0.074 0.447-0.186 0.633-0.26z"
		></path>
		<path
			style={styleDark}
			d="M0.112 19.423c0.186 0.112 0.409 0.223 0.595 0.298 0 0.112 0.037 0.26 0.037 0.26v0c0.112 0.707 0.298 1.414 0.558 2.121-0.186 0.074-0.409 0.149-0.595 0.223-0.298-0.93-0.484-1.935-0.595-2.902z"
		></path>
		<path
			style={styleDark}
			d="M20.205 3.312c0.819-0.819 1.712-1.6 2.642-2.419 2.158 0.67 4.13 1.749 5.916 3.126-1.712 0.967-3.46 2.009-5.209 3.126-1.451-1.079-3.051-1.86-4.763-2.419 0.447-0.484 0.93-0.967 1.414-1.414z"
		></path>
		<path
			style={styleDark}
			d="M12.242 30.14c0.893-0.037 1.786-0.149 2.679-0.409 0.112 0.149 0.595 0.707 0.595 0.707 0.335 0.372 0.67 0.744 1.005 1.116-1.637 0.447-3.312 0.558-4.986 0.335-0.26-0.595-0.484-1.228-0.707-1.823 0.484 0.074 0.93 0.074 1.414 0.074z"
		></path>
		<path
			style={styleDark}
			d="M6.698 28.8c0.074 0.037 0.149 0.112 0.149 0.112s0.037 0 0.037 0h0.037c0.744 0.372 1.563 0.67 2.344 0.893 0.186 0.595 0.409 1.191 0.633 1.786-0.967-0.26-1.898-0.595-2.828-1.079 0 0-0.372-0.26-0.484-0.335 0-0.484 0.037-0.93 0.112-1.377z"
		></path>
		<path
			style={styleDark}
			d="M3.833 26.381c0.484 0.521 0.967 1.005 1.526 1.451-0.074 0.409-0.112 0.856-0.149 1.302-0.707-0.595-1.34-1.265-1.935-1.972 0.186-0.26 0.372-0.521 0.558-0.781z"
		></path>
		<path
			style={styleDark}
			d="M39.702 28.763c1.377 0 2.047-0.149 2.047-1.228 0-1.005-0.633-1.34-2.047-1.34h-3.907v2.567h3.907zM35.795 21.842v2.27h3.46c1.377 0 2.047-0.223 2.047-1.191 0-0.893-0.633-1.116-2.047-1.116l-3.46 0.037zM32.856 19.46h7.553c2.605 0 3.944 0.967 3.944 2.902 0 1.116-0.558 2.009-1.6 2.567 1.34 0.447 2.047 1.414 2.047 2.865 0 2.009-1.377 3.349-3.795 3.349h-8.149v-11.684z"
		></path>
		<path
			style={styleDark}
			d="M45.991 19.46h3.795l3.758 8.372 3.87-8.372h3.758v11.684h-2.828v-7.814l-3.609 7.814h-2.456l-3.46-7.926v7.926h-2.828v-11.684z"
		></path>
		<path
			style={styleDark}
			d="M69.284 31.144h-2.902v-9.302h-3.981v-2.381h10.902v2.381h-3.981v9.302z"
		></path>
		<path
			style={styleLight}
			d="M17.34 22.772c-0.781 1.637-1.972 2.679-3.646 3.163l-0.223 0.074 0.112 0.186c0.521 0.744 1.042 1.488 1.6 2.233l0.074 0.074 0.112-0.037c0.409-0.149 0.819-0.335 1.191-0.521 2.046-1.079 3.535-2.791 4.093-4.763l0.037-0.186h-0.186c-1.079-0.037-2.084-0.149-3.051-0.26h-0.112v0.037z"
		></path>
		<path
			style={styleLight}
			d="M23.405 8.558c-1.34 0.856-2.642 1.786-3.944 2.716-1.712 1.228-3.386 2.53-5.060 3.87l-0.112 0.074 0.074 0.112c0.409 0.633 0.707 1.302 0.893 2.047l0.037 0.149 0.149-0.037c1.712-0.298 3.46-0.67 5.246-1.079 2.27-0.521 4.614-1.153 6.958-1.86l0.149-0.037-0.074-0.149c-0.67-1.786-1.637-3.349-2.902-4.688-0.372-0.409-0.819-0.781-1.228-1.116l-0.074-0.074-0.112 0.074z"
		></path>
		<path
			style={styleLight}
			d="M27.87 15.293c-2.381 0.707-4.8 1.34-7.070 1.898-1.786 0.409-3.572 0.781-5.284 1.116l-0.149 0.037v0.484c0 0.707-0.186 1.563-0.484 2.419l-0.074 0.149 0.149 0.037c0.558 0.149 1.154 0.26 1.786 0.372 3.163 0.521 7.107 0.558 11.758 0.074h0.112v-0.149c0.037-0.447 0.037-0.93 0.037-1.414 0-1.749-0.223-3.386-0.67-4.949l0.037-0.112-0.149 0.037z"
		></path>
		<path
			style={styleLight}
			d="M25.377 23.33c-0.819 3.423-3.609 6.4-7.33 7.814-0.484-0.521-0.967-1.042-1.414-1.563 0 0-0.149-0.186-0.26-0.335 0.26-0.112 0.484-0.223 0.707-0.335 2.344-1.228 4.056-3.237 4.651-5.507 0.781 0 2.121-0.037 3.646-0.074z"
		></path>
		<path
			style={styleLight}
			d="M29.953 5.023c0.112 0.112 0.372 0.335 0.372 0.335 1.86 1.823 3.237 4.056 4.167 6.623-1.935 0.67-3.87 1.302-5.767 1.898-0.707-1.898-1.749-3.572-3.126-4.986v0c-0.26-0.26-0.558-0.521-0.819-0.781 1.712-1.116 3.46-2.158 5.172-3.088z"
		></path>
	</svg>
)

export default BMTLogoDark
