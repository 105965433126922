import {
	LOG_IN,
	LOG_OUT_SUCCEEDED,
	LOG_OUT,
	SIGN_UP,
	START_AUTHENTICATION,
	AUTHENTICATION_SUCCEEDED,
	AUTHENTICATION_FAILED,
	RESET_PASSWORD,
	START_RESET_PASSWORD,
	RESET_PASSWORD_SUCCEEDED,
	RESET_PASSWORD_FAILED,
	CHANGE_PASSWORD,
	START_CHANGE_PASSWORD,
	CHANGE_PASSWORD_SUCCEEDED,
	CHANGE_PASSWORD_FAILED,
	SET_SUBMITTING,
	CLEAR_ERROR_MESSAGE,
	CLEAR_RESET_PASSWORD,
	VALIDATE_TOKEN,
	WORKING_OFFLINE,
	SET_HAS_CONNECTIVITY,
	REFRESH_TOKEN_EXPIRED,
	START_SIGN_UP,
	SIGN_UP_FAILED,
	SIGN_UP_SUCCEEDED,
	RESET_REGISTER_PAGE,
	TRIGGER_AUTO_SUBMISSION,
} from './actionTypes'
import LogInPayload from './LogInPayload'
import Action from '../Action'
import RequestFailedPayload from './RequestFailedPayload'
import SignUpPayload from './SignUpPayload'
import ResetPasswordPayload from './ResetPasswordPayload'
import Profile from '../../screens/profile/Profile'
import SubmittingPayload from './SubmittingPayload'

export const signUp = (
	profile: Profile,
	password: string,
	confirm: string
): Action<SignUpPayload> => ({
	type: SIGN_UP,
	payload: { profile, password, confirm },
})

export const logIn = (
	username: string,
	password: string
): Action<LogInPayload> => ({
	type: LOG_IN,
	payload: { username, password },
})

export const startAuthentication = (): Action => ({
	type: START_AUTHENTICATION,
})

export const authenticationSucceeded = (): Action => ({
	type: AUTHENTICATION_SUCCEEDED,
})

export const authenticationFailed = (
	message: string
): Action<RequestFailedPayload> => ({
	type: AUTHENTICATION_FAILED,
	payload: { message },
})

export const startSignUp = (): Action => ({
	type: START_SIGN_UP,
})

export const signUpSucceeded = (): Action => ({
	type: SIGN_UP_SUCCEEDED,
})

export const signUpFailed = (
	message: string
): Action<RequestFailedPayload> => ({
	type: SIGN_UP_FAILED,
	payload: { message },
})

export const clearErrorMessage = (): Action => ({
	type: CLEAR_ERROR_MESSAGE,
})

export const logOut = (): Action => ({
	type: LOG_OUT,
})

export const logoutSucceeded = (): Action => ({
	type: LOG_OUT_SUCCEEDED,
})

export const resetPassword = (email: string): Action<ResetPasswordPayload> => ({
	type: RESET_PASSWORD,
	payload: { email },
})

export const startResetPassword = (): Action => ({
	type: START_RESET_PASSWORD,
})

export const resetPasswordSuccess = (): Action => ({
	type: RESET_PASSWORD_SUCCEEDED,
})

export const clearPasswordReset = (): Action => ({
	type: CLEAR_RESET_PASSWORD,
})

export const resetPasswordFailed = (
	message: string
): Action<RequestFailedPayload> => ({
	type: RESET_PASSWORD_FAILED,
	payload: { message },
})

export const changePassword = (
	currentPassword: string,
	newPassword: string,
	confirmPassword: string
): Action => ({
	type: CHANGE_PASSWORD,
	payload: {
		currentPassword,
		newPassword,
		confirmPassword,
	},
})

export const startChangePassword = (): Action => ({
	type: START_CHANGE_PASSWORD,
})

export const changePasswordSucceded = (): Action => ({
	type: CHANGE_PASSWORD_SUCCEEDED,
})

export const changePasswordFailed = (message: string): Action => ({
	type: CHANGE_PASSWORD_FAILED,
	payload: {
		message,
	},
})

export const setSubmitting = (
	submitting: boolean
): Action<SubmittingPayload> => ({
	type: SET_SUBMITTING,
	payload: {
		submitting,
	},
})

export const validateToken = (): Action => ({
	type: VALIDATE_TOKEN,
})

export const setHasConnectivity = (
	hasConnectivity: boolean
): Action<boolean> => ({
	type: SET_HAS_CONNECTIVITY,
	payload: hasConnectivity,
})

export const workingOffline = (status: boolean): Action<boolean> => ({
	type: WORKING_OFFLINE,
	payload: status,
})

export const refreshTokenExpired = (): Action<never> => ({
	type: REFRESH_TOKEN_EXPIRED,
})

export const resetRegisterPage = (): Action => ({
	type: RESET_REGISTER_PAGE,
})

export const triggerAutoSubmission = (): Action => ({
	type: TRIGGER_AUTO_SUBMISSION,
})
