import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { refreshTokenExpired, validateToken } from '../redux/users/actions'
import { tokenExpiry } from '../services/authentication/getAccessToken'

export const TokenExpirySubscriber: React.FunctionComponent = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		const handler = () => {
			dispatch(refreshTokenExpired())
		}

		tokenExpiry.subscribe(handler)
		return () => tokenExpiry.unsubscribe(handler)
	}, [dispatch])

	return null
}
