import React from 'react'
import PlusIcon from '../../icons/PlusIcon'

const AddPhotoRow: React.FunctionComponent<{
	onClick: () => void
}> = ({ onClick }) => {
	return (
		<div className="flex items-center mr-4 mb-2 border-secondary-6 border-b p-1 cursor-pointer">
			<div className="flex-1 mr2 text-secondary-6"> Upload up to 5 photos </div>
			<div className="w-6">
				<PlusIcon className="w-6" onClick={onClick} />
			</div>
		</div>
	)
}

export default AddPhotoRow
