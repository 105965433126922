import { SelectOptions } from '../../../components/Selector'
import { getLocationList } from './LocationList'

export interface Locations {
	location: Record<string, SubLocations>
}

export interface SubLocations {
	subLocation: string[]
}

export const getIncidentLocations = (): SelectOptions =>
	Object.keys(getLocationList().location).map((name) => ({
		value: name,
		label: name.length !== 0 ? name : 'Location',
	}))

export const getSubLocations = (name: string): string[] | null => {
	const subLocations = getLocationList().location[name]?.subLocation
	if (!subLocations) {
		return null
	}
	return subLocations
}

export const getIncidentSubLocations = (name: string): SelectOptions => {
	const subLocations = getSubLocations(name) || []
	return subLocations.map((name) => ({
		value: name,
		label: name.length !== 0 ? name : 'Sub location',
	}))
}
