import { SelectOptions } from '../../../components/Selector'
import { IncidentType } from './IncidentTypes'

export const getIncidentCauses = (incidentType: string): SelectOptions => {
	const causeList =
		incidentType === IncidentType.Environmental
			? incidentCauseEnvironmentList
			: incidentCauseList
	return causeList.map((incidentCause) => ({
		value: incidentCause,
		label: incidentCause.length ? incidentCause : 'Incident cause',
	}))
}

export enum IncidentCause {
	Burns = 'Burns/Scald',
	ContactwithElectricity = 'Contact with Electricity',
	ContagiousDiseases = 'Contagious diseases',
	DangerousGoods = 'Dangerous Goods',
	ExplosionOrFire = 'Explosion / Fire',
	Fallfromheight = 'Fall from height',
	HarmfulSubstance = 'Harmful substance',
	HeatExposure = 'Heat exposure',
	MachineryOrEquipment = 'Machinery/equipment',
	ManualHandling = 'Manual handling',
	Noise = 'Noise',
	NotwearingPPE = 'Not wearing PPE',
	Others = 'Other',
	PressureSystemOrPressurisedEquipment = 'Pressure system / Pressurised equipment',
	ProcedureNotFollowed = 'Procedure not followed',
	RoadTrafficOrDriving = 'Road Traffic / Driving',
	SharpObjectsOrCuts = 'Sharp objects/cuts',
	SlipsAndTrips = 'Slips and trips',
	StruckByOrAgainst = 'Struck by/against',
	Trapping = 'Trapping',
	Wildlife = 'Wildlife',
	WorkTravel = 'Work travel (non-driving)',
}

const incidentCauseList: string[] = ['', ...Object.values(IncidentCause)]

enum IncidentCauseEnvironment {
	AirPollution = 'Air pollution',
	BlackSmoke = 'Black smoke',
	CarbonEmission = 'Carbon emission',
	ChemicalSpill = 'Chemical spill',
	OilSpill = 'Oil spill',
	OtherSpill = 'Other spill',
	Flooding = 'Flooding',
	FlyTipping = 'Fly tipping (land)',
	DischargeOfItemsOrChemicalsIntoTheSea = 'Discharge of items or chemicals into the sea',
	SewageLeak = 'Sewage leak',
	DamageToALocalHabitat = 'Damage to a local habitat',
	DamageToLand = 'Damage to land',
	ExplosionOrFire = 'Explosion / Fire',
	NoisePollution = 'Noise pollution',
	IncorrectWasteDisposal = 'Incorrect waste disposal',
	OveruseOfElectricity = 'Overuse of electricity',
	Other = 'Other',
}

const incidentCauseEnvironmentList: string[] = [
	'',
	...Object.values(IncidentCauseEnvironment),
]
