export const ADD_OFFLINE_INCIDENT_REPORT = 'ADD_OFFLINE_INCIDENT_REPORT'
export const DELETE_OFFLINE_INCIDENT_REPORT = 'DELETE_OFFLINE_INCIDENT_REPORT'
export const OFFLINE_INCIDENT_REPORT_SUBMISSION_FAILED =
	'OFFLINE_INCIDENT_REPORT_SUBMISSION_FAILED'
export const TRIGGER_INCIDENT_AUTO_SUBMISSION =
	'TRIGGER_INCIDENT_AUTO_SUBMISSION'
export const RESET_OFFLINE_INCIDENT_REPORTS = 'RESET_OFFLINE_INCIDENT_REPORTS'

export const UPSERT_SAVED_INCIDENT_REPORT = 'UPSERT_SAVED_INCIDENT_REPORT'
export const DELETE_SAVED_INCIDENT_REPORT = 'DELETE_SAVED_INCIDENT_REPORT'
