import Action from '../../Action'
import { SET_INCIDENT_DETAILS, RESET_INCIDENT_DETAILS } from './actionType'
import { DetailsState } from './DetailsState'

const initialState: DetailsState = {
	location: '',
	subLocation: '',
	personInvolved: '',
	personName: '',
	natureOfIncident: '',
	wasInjured: '',
	injuryType: '',
	treatmentRequired: '',
	treatmentType: '',
	environmentalIncident: '',
	uploadPhoto: '',
	photos: [],
	additionalMedia: '',
	additionalInformation: '',
}

const details = (
	state: DetailsState = initialState,
	action: Action<unknown>
): DetailsState => {
	switch (action.type) {
		case SET_INCIDENT_DETAILS: {
			return action.payload as DetailsState
		}
		case RESET_INCIDENT_DETAILS: {
			return Object.assign({}, initialState)
		}
		default: {
			return state
		}
	}
}

export default details
