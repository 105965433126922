import RootState from '../RootState'
import Profile from '../../screens/profile/Profile'

export const selectUserProfile = (state: RootState): Profile | null => {
	return state.userProfile.profile
}

export const selectError = (state: RootState): string | null => {
	return state.userProfile.error
}

export const selectIsSubmitting = (state: RootState): boolean =>
	state.userProfile.isSubmitting
