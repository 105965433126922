import { SelectOptions } from '../../components/Selector'
import { getLocations } from './getLocations'
import { HSRep } from '../../redux/assessment/metadata/MetadataState'

export const getOperatingUnitOptions = (): SelectOptions =>
	Object.keys(getLocations().operatingUnits).map((name) => ({
		value: name,
		label: name,
	}))

export const getWorkLocations = (operatingUnit: string): string[] | null => {
	const representativesByLocation = getLocations().operatingUnits[operatingUnit]
		?.representatives
	if (!representativesByLocation) {
		return null
	}
	return Object.keys(representativesByLocation)
}

export const getWorkLocationOptions = (
	operatingUnit: string
): SelectOptions => {
	const locations = getWorkLocations(operatingUnit) ?? []
	return locations.map((loc) => ({
		value: loc,
		label: loc,
	}))
}
