import React, { useState } from 'react'
import styled from 'styled-components'
import { IconComponent } from '../../icons/IconComponent'

const StyledSuggestionBox = styled.div`
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	border-radius: 0px 0px 4px 4px;
`

const SuggestionFormField: React.FunctionComponent<{
	icon: IconComponent
	value: string
	setValue: (newValue: string) => void
	error: string
	setError: (newError: string) => void
	suggestions: string[]
	maxSuggestions: number
	inputProps?: React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	>
}> = ({
	icon,
	value,
	setValue,
	error,
	setError,
	suggestions,
	maxSuggestions,
	inputProps = {},
}) => {
	const Icon = icon
	const [showSuggestions, setShowSuggestions] = useState(false)

	const filteredSuggestions = suggestions
		.filter(
			(suggestion) => suggestion.toLowerCase().indexOf(value.toLowerCase()) >= 0
		)
		.slice(0, maxSuggestions)

	const ref = React.createRef<HTMLInputElement>()

	return (
		<>
			<div className="w-full mt-2">
				<div className="flex flex-row items-end w-full border-secondary-6 border-b-2 p-1">
					<Icon
						className="mr-2 w-6 h-6"
						onClick={() => {
							if (ref.current) {
								const element = ref.current
								if (element) {
									element.focus()
								}
							}
						}}
					/>
					<input
						{...inputProps}
						ref={ref}
						autoComplete="off"
						className={`${inputProps.className || ''} w-full`}
						value={value}
						onChange={(e) => {
							inputProps.onChange?.call(undefined, e)

							setError('')
							setShowSuggestions(true)
							setValue(e.target.value)
						}}
						onClick={(e) => {
							inputProps.onClick?.call(undefined, e)
							setShowSuggestions(true)
						}}
						onBlur={(e) => {
							inputProps.onBlur?.call(undefined, e)
							setShowSuggestions(false)
						}}
						onKeyDown={(e) => {
							if (e.keyCode === 9 && filteredSuggestions.length === 1) {
								setValue(filteredSuggestions[0])
								e.preventDefault()
							} else {
								inputProps.onKeyDown?.call(undefined, e)
							}
						}}
					/>
				</div>
			</div>
			{showSuggestions && filteredSuggestions.length > 0 && (
				<div
					className="relative w-full"
					onMouseDown={(e) => e.preventDefault()}
				>
					<StyledSuggestionBox className="absolute z-50 top-0 bg-background w-full border-l border-r border-gray-2 py-1 overflow-auto">
						{filteredSuggestions.map((suggestion) => (
							<div
								key={suggestion}
								className="pl-10 cursor-pointer"
								onClick={() => {
									setValue(suggestion)
									setShowSuggestions(false)
								}}
							>
								{suggestion}
							</div>
						))}
					</StyledSuggestionBox>
				</div>
			)}
			<div className="text-error body-xs-thin h-4">{error}</div>
		</>
	)
}

export default SuggestionFormField
