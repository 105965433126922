import Action from '../../Action'
import {
	RESET_INCIDENT_REPORT,
	SET_INCIDENT_REPORT_SUBMISSION_ERROR,
	SET_INCIDENT_REPORT_SUBMITTING,
	SHOW_INCIDENT_REPORT_CALL_PROMPT,
	SHOW_INCIDENT_REPORT_SUBMISSION_PROMPT,
	SUBMIT_INCIDENT_REPORT,
} from './actionType'

export const setIncidentReportSubmissionError = (
	error: string | null
): Action<string | null> => ({
	type: SET_INCIDENT_REPORT_SUBMISSION_ERROR,
	payload: error,
})

export const setIncidentReportSubmitting = (
	submitting: boolean
): Action<boolean> => ({
	type: SET_INCIDENT_REPORT_SUBMITTING,
	payload: submitting,
})

export const submitIncidentReport = (): Action => ({
	type: SUBMIT_INCIDENT_REPORT,
})

export const showIncidentReportCallPrompt = (
	show: boolean
): Action<boolean> => ({
	type: SHOW_INCIDENT_REPORT_CALL_PROMPT,
	payload: show,
})

export const showIncidentReportSubmissionPrompt = (
	show: boolean
): Action<boolean> => ({
	type: SHOW_INCIDENT_REPORT_SUBMISSION_PROMPT,
	payload: show,
})

export const resetIncidentReport = (): Action => ({
	type: RESET_INCIDENT_REPORT,
})
