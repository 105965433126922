import React from 'react'
import { IconComponent, wrapIcon, calculateStrokeWidth } from './IconComponent'

const DateIcon: IconComponent = wrapIcon(({ ...props }) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<rect
			x="48"
			y="80"
			width="416"
			height="384"
			rx="48"
			fill={props.fill}
			stroke={props.stroke}
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<circle
			cx="296"
			cy="232"
			r="24"
			fill={props.stroke}
			stroke={props.stroke}
		/>
		<circle
			cx="376"
			cy="232"
			r="24"
			fill={props.stroke}
			stroke={props.stroke}
		/>
		<circle
			cx="296"
			cy="312"
			r="24"
			fill={props.stroke}
			stroke={props.stroke}
		/>
		<circle
			cx="376"
			cy="312"
			r="24"
			fill={props.stroke}
			stroke={props.stroke}
		/>
		<circle
			cx="136"
			cy="312"
			r="24"
			fill={props.stroke}
			stroke={props.stroke}
		/>
		<circle
			cx="216"
			cy="312"
			r="24"
			fill={props.stroke}
			stroke={props.stroke}
		/>
		<circle
			cx="136"
			cy="392"
			r="24"
			fill={props.stroke}
			stroke={props.stroke}
		/>
		<circle
			cx="216"
			cy="392"
			r="24"
			fill={props.stroke}
			stroke={props.stroke}
		/>
		<circle
			cx="296"
			cy="392"
			r="24"
			fill={props.stroke}
			stroke={props.stroke}
		/>
		<path
			d="M128 48v32M384 48v32"
			fill={props.stroke}
			stroke={props.stroke}
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<path
			d="M464 160H48"
			fill={props.stroke}
			stroke={props.stroke}
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
	</svg>
))

export default DateIcon
