import { CountryCode } from 'libphonenumber-js'
import React, { useState } from 'react'
import { BeatLoader } from 'react-spinners'
import styled from 'styled-components'
import Colors from '../../Colors'
import FormField from '../../components/IconFormField'
import PageBanner from '../../components/PageBanner'
import SafeForm from '../../components/SafeForm'
import BusinessIcon from '../../icons/BusinessIcon'
import CallIcon from '../../icons/CallIcon'
import KeypadIcon from '../../icons/KeypadIcon'
import LocationIcon from '../../icons/LocationIcon'
import LockClosedIcon from '../../icons/LockClosedIcon'
import MailIcon from '../../icons/MailIcon'
import MedKitIcon from '../../icons/MedKitIcon'
import PersonIcon from '../../icons/PersonIcon'
import Selector from '../../components/Selector'
import { formatPhoneNumber, getDiaingCodeOptions } from './getDialingCodes'
import {
	getOperatingUnitOptions,
	getWorkLocationOptions,
	getWorkLocations,
} from './getOperatingUnits'
import Profile from './Profile'
import { getLocationRepresentative } from './lookupContact'

export interface ProfileProps {
	profile: Profile
	handleSubmit: (
		profile: Profile,
		password?: {
			password: string
			confirm: string
		}
	) => void
	handleCancel: () => void
	error: string | null
	message?: string
	saveButtonText: string
	promptText: string
	loading?: boolean
	includePasswords?: boolean
}

const calculateIntials = (name: string): string => {
	const parts = name.trim().split(' ')
	return parts
		.filter((_, index) => index === 0 || index === parts.length - 1)
		.map((part) => (part.length === 0 ? '' : part[0].toUpperCase()))
		.join('')
		.trim()
}

const StyledInitialsDiv = styled.div`
	top: -3rem;
`

const dialingCodeOptions = getDiaingCodeOptions()

const EditProfileTemplate: React.FunctionComponent<ProfileProps> = (props) => {
	const {
		handleSubmit,
		handleCancel,
		promptText,
		saveButtonText,
		loading = false,
		error,
		message,
		includePasswords = false,
	} = props

	const [profile, setProfile] = useState(props.profile)

	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')

	const changeOperatingUnit = (newUnit: string) => {
		const locations = getWorkLocations(newUnit)
		const newWorkLoc =
			locations && locations.indexOf(profile.primaryWorkLocation) < 0
				? locations[0]
				: profile.primaryWorkLocation

		setProfile({
			...profile,
			operatingUnit: newUnit,
			primaryWorkLocation: newWorkLoc,
		})
	}

	return (
		<div className="flex flex-col h-full">
			<PageBanner title={promptText} colorClass="primary-2" />

			<div className="relative w-full">
				<StyledInitialsDiv className="absolute w-full flex justify-center">
					<div className="text-primary-1 bg-background border-secondary-6 border rounded-full mt-2 w-24 h-24 flex items-center justify-center text-4xl font-medium fixed">
						{calculateIntials(profile.name)}
					</div>
				</StyledInitialsDiv>
			</div>

			<SafeForm
				className="overflow-auto"
				onSubmit={() => {
					if (includePasswords) {
						handleSubmit(profile, {
							password: password,
							confirm: confirmPassword,
						})
					} else {
						handleSubmit(profile)
					}
				}}
			>
				<div className="flex flex-col w-full mt-16">
					<FormField icon={PersonIcon}>
						<input
							name="name"
							className="w-full"
							type="text"
							autoComplete="off"
							placeholder="Full name"
							value={profile.name}
							onChange={(e) => setProfile({ ...profile, name: e.target.value })}
							required
						/>
					</FormField>

					<FormField icon={MailIcon}>
						<input
							name="email"
							className="w-full"
							type="email"
							autoComplete="off"
							placeholder="Email address"
							value={profile.email}
							onChange={(e) =>
								setProfile({ ...profile, email: e.target.value })
							}
							required
						/>
					</FormField>

					<FormField icon={KeypadIcon}>
						<Selector
							name="dialingCode"
							options={dialingCodeOptions}
							placeholder="Dialling code"
							value={profile.countryCode}
							setValue={(newValue) =>
								setProfile({
									...profile,
									countryCode: newValue as CountryCode,
								})
							}
						/>
					</FormField>

					<FormField icon={CallIcon}>
						<input
							name="phoneNumber"
							className="w-full"
							type="tel"
							autoComplete="off"
							placeholder="Mobile number"
							value={formatPhoneNumber(profile)}
							onChange={(e) =>
								setProfile({ ...profile, phoneNumber: e.target.value })
							}
						/>
					</FormField>

					<FormField icon={BusinessIcon}>
						<Selector
							name="operatingUnit"
							placeholder="Operating unit"
							options={getOperatingUnitOptions()}
							value={profile.operatingUnit}
							setValue={changeOperatingUnit}
						/>
					</FormField>

					<FormField icon={LocationIcon}>
						<Selector
							name="primaryWorkLocation"
							placeholder="Primary work location"
							options={getWorkLocationOptions(profile.operatingUnit)}
							value={profile.primaryWorkLocation}
							setValue={(newLoc) =>
								setProfile({ ...profile, primaryWorkLocation: newLoc })
							}
						/>
					</FormField>

					<FormField icon={MedKitIcon}>
						<p className="w-full text-secondary-6">
							{getLocationRepresentative(
								profile.operatingUnit,
								profile.primaryWorkLocation
							)?.name || 'Local HSE Representative'}
						</p>
					</FormField>

					{includePasswords && (
						<>
							<FormField icon={LockClosedIcon}>
								<input
									name="password"
									className="w-full"
									type="password"
									placeholder="Password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									required
								/>
							</FormField>

							<FormField icon={LockClosedIcon}>
								<input
									name="confirmPassword"
									className="w-full"
									type="password"
									placeholder="Retype Password"
									value={confirmPassword}
									onChange={(e) => setConfirmPassword(e.target.value)}
									required
								/>
							</FormField>
						</>
					)}
				</div>
				<div className="flex flex-col items-center mb-6">
					{message && (
						<div className="body-sm text-success text-center mt-3 mb-3 px-6">
							{message}
						</div>
					)}
					<div className="body-sm text-error text-center mt-3 mb-3 px-5">
						{error}
					</div>
					<button className="btn-primary" disabled={loading}>
						{saveButtonText}
					</button>
					{loading && <BeatLoader size={12} color={Colors.primary[1]} />}
					<button
						className="btn-secondary mt-8"
						disabled={loading}
						onClick={(e) => {
							e.preventDefault()
							handleCancel()
						}}
					>
						Cancel
					</button>
				</div>
			</SafeForm>
		</div>
	)
}

export default EditProfileTemplate
