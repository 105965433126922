import React from 'react'
import { useSelector } from 'react-redux'
import { HashRouter, Switch } from 'react-router-dom'
import 'typeface-roboto'
import AuthRoute from './components/AuthRoute'
import NonAuthRoute from './components/NonAuthRoute'
import { selectIsLoggedIn } from './redux/users/selectors'
import LoginPage from './screens/auth/login/LoginPage'
import ChangePassword from './screens/changepassword/ChangePassword'
import MenuContent from './screens/menu/MenuContent'
import MenuControl from './screens/menu/MenuControl'
import MENUS from './screens/menu/Menus'
import EditProfile from './screens/profile/EditProfile'
import Register from './screens/profile/Register'
import ForgotPassword from './screens/forgotpassword/ForgotPassword'
import LogErrors from './screens/logErrors/LogErrors'
import AutoSaveRiskAssessment from './screens/assessment/AutoSaveRiskAssessment'
import FailedEmailScreen from './screens/failedemail/FailedEmailScreen'
import { OnlinePoller } from './components/OnlinePoller'
import { TokenExpirySubscriber } from './components/TokenExpirySubscriber'
import { AutoSubmissionTrigger } from './components/AutoSubmissionTrigger'
import AutoSaveIncidentReport from './screens/incident/AutoSaveIncidentReport'

const App: React.FunctionComponent = () => {
	const isLoggedIn = useSelector(selectIsLoggedIn)

	return (
		<div className="h-screen">
			<HashRouter>
				{isLoggedIn && <MenuControl menus={MENUS} />}
				<Switch>
					<NonAuthRoute exact path="/login">
						<LoginPage />
					</NonAuthRoute>
					<NonAuthRoute exact path="/register">
						<Register />
					</NonAuthRoute>
					<NonAuthRoute exact path="/forgot-password">
						<ForgotPassword />
					</NonAuthRoute>

					<AuthRoute exact path="/error-log">
						<LogErrors />
					</AuthRoute>
					<AuthRoute exact path="/failed-email">
						<FailedEmailScreen />
					</AuthRoute>

					<MenuContent menus={MENUS}>
						<AuthRoute exact path="/settings/edit-profile">
							<EditProfile />
						</AuthRoute>
						<AuthRoute exact path="/settings/change-password">
							<ChangePassword />
						</AuthRoute>
					</MenuContent>
				</Switch>
			</HashRouter>
			<OnlinePoller />
			<AutoSaveRiskAssessment />
			<AutoSaveIncidentReport />
			<TokenExpirySubscriber />
			<AutoSubmissionTrigger />
		</div>
	)
}

export default App
