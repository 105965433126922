import Action from '../../Action'
import { EmailedStatus } from '../../emailedStatus'
import {
	SET_ASSESSMENT_METADATA_SUGGESTIONS,
	SET_ASSESSMENT_METADATA_VALUES,
	RESET_METADATA_VALUES,
} from './actionTypes'
import MetadataState, {
	MetadataSuggestions,
	MetadataValues,
} from './MetadataState'

const initialState: MetadataState = {
	values: {
		localId: '',
		projectReference: '',
		title: '',
		forwardingEmails: [],
		hsRep: { name: '', email: '', phoneNumber: '' },
		status: EmailedStatus.PENDING,
		failedEmails: [],
	},
	suggestions: {
		projectReferences: [],
		titles: [],
		forwardingEmails: [],
	},
}

const metadata = (
	state = initialState,
	action: Action<unknown>
): MetadataState => {
	switch (action.type) {
		case SET_ASSESSMENT_METADATA_VALUES: {
			return {
				...state,
				values: action.payload as MetadataValues,
			}
		}
		case SET_ASSESSMENT_METADATA_SUGGESTIONS: {
			return {
				...state,
				suggestions: action.payload as MetadataSuggestions,
			}
		}
		case RESET_METADATA_VALUES: {
			return initialState
		}
		default: {
			return state
		}
	}
}

export default metadata
