const Colors = {
	primary: {
		1: '#005581',
		2: '#1ABDC9',
	},
	secondary: {
		1: '#D6E9E0',
		2: '#E20177',
		3: '#D4D00F',
		4: '#00B6DD',
		5: '#7ED1E1',
		6: '#D8D0C7',
		7: '#B9E0F7',
		8: '#8B9B93',
		9: '#887665',
		10: '#4F5650',
	},
	gray: {
		1: '#F5F5F5',
		2: '#E0E0E0',
		3: '#BDBDBD',
		4: '#828282',
		5: '#4F4F4F',
		6: '#333333',
	},
	'nav-background': 'EDEEED',
	background: '#FFFFFF',
	error: '#D4351C',
}

export default Colors
