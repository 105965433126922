import React from 'react'
import { Link } from 'react-router-dom'
import { NumericDate } from '../../../components/NumericDate'
import { EmailedStatus } from '../../../redux/emailedStatus'
import RecentIncidentReport from '../../../redux/incident/recentIncidents/RecentIncidentReport'
import { FailedEmailHistoryState } from '../../failedemail/FailedEmailScreen'
import { Status, StatusType } from '../Status'

const mousePointerCss = (openHandler?: () => void): string =>
	openHandler ? 'cursor-pointer' : ''

type Props = {
	incident: RecentIncidentReport
	status: StatusType
	onOpen?: () => void
}

const IncidentRow: React.FunctionComponent<Props> = ({
	incident: { config, details, submissionDate, emailedStatus, failedEmails },
	onOpen,
	status,
}: Props) => {
	return (
		<div className="flex h-auto border-solid border-b border-secondary-6 pr-4 max-h-20">
			<div id="fileDownloaderSpace" className="m-1 w-12 h-16 flex-none"></div>
			<div
				className={`flex flex-grow pt-4 pl-2 overflow-hidden h-full ${mousePointerCss(
					onOpen
				)}`}
				onClick={onOpen}
			>
				<div className="flex flex-col flex-1">
					<div className="body-base line-clamp-1 break-all">
						{config.incidentCause.length > 0
							? config.incidentCause
							: config.incidentType}
					</div>
					<div className="body-xs-thin line-clamp-1 break-all -mt-1">
						{details.subLocation}
					</div>
				</div>
			</div>
			<div
				className={`flex flex-col flex-none pt-4 pl-2 ${mousePointerCss(
					onOpen
				)}`}
				onClick={onOpen}
			>
				<div className="body-xs-thin text-gray-5">
					{submissionDate !== undefined && (
						<NumericDate date={submissionDate} />
					)}
					{submissionDate === undefined && 'N/A'}
				</div>
				{emailedStatus === EmailedStatus.FAIL && (
					<Link
						to={{
							pathname: '/failed-email',
							state: {
								title: `${config.incidentCause} - ${details.subLocation}`,
								emailAddresses: config.forwardingEmails,
								failedEmails: failedEmails,
							} as FailedEmailHistoryState,
						}}
					>
						<div className="text-error body-base">
							Failed{' '}
							{failedEmails.length > 0
								? failedEmails.length
								: config.forwardingEmails.length}{' '}
							Addresses
						</div>
					</Link>
				)}

				<div className="text-right -mt-1">
					{emailedStatus !== EmailedStatus.FAIL && <Status status={status} />}
				</div>
			</div>
		</div>
	)
}

export default IncidentRow
