import { all } from 'redux-saga/effects'
import userWatchers from './users/sagas'
import userProfileWatchers from './userProfile/sagas'
import metadataSagas from './assessment/metadata/sagas'
import flowSagas from './assessment/flow/sagas'
import recentRiskAssessmentSagas from './assessment/recentAssessments/sagas'
import riskAssessmentPdfSagas from './assessment/pdfAssessment/sagas'
import errorSagas from './error/sagas'
import incidentSubmissionSagas from './incident/submission/sagas'
import recentIncidentReportSagas from './incident/recentIncidents/sagas'
import offlineSagas from './offline/sagas'
import offlineRiskAssessmentSagas from './offline/assessment/sagas'
import offlineIncidentReportSagas from './offline/incident/sagas'
import Sagas from './sagas'

export default function* rootSaga(): Generator {
	yield all([
		...userWatchers,
		...userProfileWatchers,
		...metadataSagas,
		...flowSagas,
		...recentRiskAssessmentSagas,
		...riskAssessmentPdfSagas,
		...errorSagas,
		...incidentSubmissionSagas,
		...recentIncidentReportSagas,
		...offlineSagas,
		...offlineRiskAssessmentSagas,
		...offlineIncidentReportSagas,
		...Sagas,
	])
}
