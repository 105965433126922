import { getDate } from '../../testing/date'
import { AuthTokenResult } from './authenticate'
import { LOCAL_STORAGE_TOKEN_KEY } from './constants'

export type AuthTokens = {
	accessToken: string
	refreshToken: string
	idToken: string
	expiryDate: Date
}

type SerialisedTokens = Omit<AuthTokens, 'expiryDate'> & {
	expiryDate: string
}

export const getStoredTokens = (): AuthTokens | null => {
	const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)

	if (!token) {
		return null
	}

	const serialised = JSON.parse(token) as SerialisedTokens

	return {
		...serialised,
		expiryDate: new Date(serialised.expiryDate),
	}
}

export const setStoredTokens = (tokens: AuthTokens): void =>
	localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, JSON.stringify(tokens))

export const deleteStoredTokens = (): void =>
	localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY)

export const parseAuthTokenResult = (tokens: AuthTokenResult): AuthTokens => {
	const expiryDate = getDate()
	expiryDate.setSeconds(expiryDate.getSeconds() + tokens.expires_in)

	return {
		accessToken: tokens.access_token,
		refreshToken: tokens.refresh_token,
		idToken: tokens.id_token,
		expiryDate,
	}
}
