import React, { useState } from 'react'
import styled from 'styled-components'
import backgroundImage from './background.jpg'
import logo from './logo.png'
import { useDispatch, useSelector } from 'react-redux'
import { logIn, clearErrorMessage } from '../../../redux/users/actions'
import { selectAuthState } from '../../../redux/users/selectors'
import { IconComponent } from '../../../icons/IconComponent'
import LockClosedIcon from '../../../icons/LockClosedIcon'
import MailIcon from '../../../icons/MailIcon'
import { push } from 'connected-react-router'
import SafeForm from '../../../components/SafeForm'
import WorkOffline from '../../../components/WorkOffline'
import { getEnvironmentVariables } from '../../../services/authentication/getEnvironmentVariables'
import { selectOfflineEmail } from '../../../redux/offline/selectors'

const StyledBackground = styled.div`
	background-image: url(${backgroundImage});
	background-blend-mode: multiply;
	background-color: rgba(0, 0, 0, 0.15);
	background-repeat: no-repeat;
	background-size: cover;
`

const LoginContainer = styled.div`
	margin-top: 10vh;
`

const StyledInputWrapper = styled.div`
	& input {
		&::placeholder {
			color: black;
		}
	}
`

const StyledLoginButton = styled.button`
	color: #005581;
	line-height: 180%;
	box-shadow: 8px 8px 5px rgba(0, 0, 0, 0.5);
`

const InputWrapper: React.FunctionComponent<{
	icon: IconComponent
	className?: string
}> = ({ icon, children, className = '' }) => {
	const Icon = icon
	const ref = React.createRef<HTMLDivElement>()

	return (
		<StyledInputWrapper
			className={`flex flex-row items-center border-black border-b text-xl w-full ${className}`}
		>
			<div>
				<Icon className="w-6 h-6" color="#333333" />
			</div>
			<div
				className="w-full"
				ref={ref}
				onClick={() => {
					if (ref.current) {
						const element = ref.current.querySelector(
							'input'
						) as HTMLInputElement
						if (element) {
							element.focus()
						}
					}
				}}
			>
				{children}
			</div>
		</StyledInputWrapper>
	)
}

const LoginPage: React.FunctionComponent = () => {
	const dispatch = useDispatch()
	const offlineEmail = useSelector(selectOfflineEmail)
	const [email, setEmail] = useState(offlineEmail)
	const [password, setPassword] = useState('')
	const { isLoading, error } = useSelector(selectAuthState)
	const { versionNumber } = getEnvironmentVariables()

	return (
		<StyledBackground className="flex flex-col items-center h-screen bg-cover">
			<LoginContainer className="w-full flex justify-center">
				<SafeForm
					className="flex flex-col items-center w-3/5 max-w-lg"
					onSubmit={() => {
						if (!isLoading) {
							dispatch(logIn(email, password))
						}
					}}
				>
					<img className="w-32 mb-12" src={logo} alt="logo" />
					<InputWrapper className="mb-12" icon={MailIcon}>
						<input
							className="ml-2 border-none bg-transparent text-black w-full"
							type="email"
							name="email"
							placeholder="Email Address"
							value={email}
							onChange={(event) => setEmail(event.target.value)}
							disabled={isLoading && true}
						/>
					</InputWrapper>
					<InputWrapper className="mb-2" icon={LockClosedIcon}>
						<input
							className="ml-2 border-none bg-transparent text-black w-full"
							type="password"
							name="password"
							placeholder="Password"
							value={password}
							onChange={(event) => setPassword(event.target.value)}
							disabled={isLoading && true}
						/>
					</InputWrapper>
					<span
						className="mt-1 mb-12 cursor-pointer text-primary-1"
						onClick={(e) => {
							e.preventDefault()
							dispatch(push('/forgot-password'))
						}}
					>
						Forgot password?
					</span>

					{error && <div className="text-error">{error}</div>}

					<StyledLoginButton
						className="bg-background w-full pb-1 body-lg rounded-md font-semibold h-8"
						disabled={isLoading && true}
					>
						Log In
					</StyledLoginButton>

					<div className="fixed bottom-0 mb-48">
						New user?{' '}
						<span
							className="text-primary-1 font-bold"
							onClick={(e) => {
								e.preventDefault()
								dispatch(push('/register'))
								dispatch(clearErrorMessage())
							}}
						>
							Sign up here
						</span>
					</div>

					<WorkOffline email={email} />

					<div className="fixed bottom-0 mb-2 text-white">
						{`V ${versionNumber}`}
					</div>
				</SafeForm>
			</LoginContainer>
		</StyledBackground>
	)
}

export default LoginPage
