import { ListActions } from '../../hooks/useListState'
import React from 'react'
import MailIcon from '../../icons/MailIcon'
import PlusIcon from '../../icons/PlusIcon'
import MinusIcon from '../../icons/MinusIcon'
import { UserEmailSelect } from '../../components/UserEmailSelect'

export interface EmailData {
	email: string
	error: string
	key: string
}

enum EmailType {
	ADD,
	REMOVE,
	NOTHING,
}

const getEmailType = (
	email: EmailData,
	index: number,
	emails: EmailData[],
	maxItems: number
): EmailType => {
	if (
		index === 0 &&
		email.email.trim().length > 0 &&
		emails.length < maxItems
	) {
		return EmailType.ADD
	}
	if (emails.length !== 1) {
		return EmailType.REMOVE
	}
	return EmailType.NOTHING
}

const createEmailEmpty: () => EmailData = () => ({
	email: '',
	error: '',
	key: Math.random().toString(),
})

const EmailsEntry: React.FunctionComponent<{
	emails: EmailData[]
	actions: ListActions<EmailData>
	maxItems: number
}> = ({
	emails,
	actions: { addEntry, updateEntry, removeEntry },
	maxItems,
}) => {
	const list = emails.map((data, index) => {
		const type = getEmailType(data, index, emails, maxItems)

		return (
			<div key={data.key} className="">
				<div className="items-center flex flex-1 mr-2 mb-2 w-full border-b border-secondary-6">
					<MailIcon className="mr-2 w-6 h-6" />
					<div className="flex flex-col flex-1">
						<UserEmailSelect
							defaultValue={data.email}
							onSelectionChanged={(email?: string) => {
								updateEntry({
									...data,
									email: email ?? '',
									error: '',
								})
							}}
						/>
					</div>
					<div className="w-6">
						{type === EmailType.ADD && (
							<PlusIcon
								className="w-6"
								onClick={() => addEntry(createEmailEmpty())}
							/>
						)}
						{type === EmailType.REMOVE && (
							<MinusIcon className="w-6" onClick={() => removeEntry(data)} />
						)}
					</div>
				</div>
				{data.error && (
					<div className="text-error body-xs-thin h-4">{data.error}</div>
				)}
			</div>
		)
	})
	return <div>{list}</div>
}

export default EmailsEntry
