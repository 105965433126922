import React from 'react'
import { IconComponent, wrapIcon, calculateStrokeWidth } from './IconComponent'

const IncidentCauseIcon: IconComponent = wrapIcon(({ ...props }) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<path
			d="M256 80c-8.66 0-16.58 7.36-16 16l8 216a8 8 0 008 8h0a8 8 0 008-8l8-216c.58-8.64-7.34-16-16-16z"
			fill={props.fill}
			stroke={props.stroke}
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<circle
			cx="256"
			cy="416"
			r="16"
			fill={props.fill}
			stroke={props.stroke}
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
	</svg>
))

export default IncidentCauseIcon
