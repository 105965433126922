import AuthenticationResult from './AuthenticationResult'
import {
	PASSWORD_TOO_WEAK_RESPONSE,
	INVALID_PASSWORD_TEXT,
	WRONG_EMAIL_OR_PASSWORD,
	INCORRECT_PASSWORD,
} from './constants'
import getAPIUrl from '../getAPIUrl'
import { getAccessToken } from './getAccessToken'
import { AuthenticationError } from '../fetch/AuthenticationError'
import { logError } from '../Error/errorService'

export const changePassword = async (
	previousPassword: string,
	newPassword: string
): Promise<AuthenticationResult> => {
	try {
		const changePasswordUrl = getAPIUrl('changePassword')
		const accessToken = await getAccessToken()

		if (accessToken === null) {
			throw new AuthenticationError()
		}

		const response = await fetch(changePasswordUrl, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			body: JSON.stringify({ previousPassword, newPassword }),
		})

		if (!response.ok) {
			let serverResult: AuthenticationResult

			try {
				serverResult = {
					...(await response.json()),
					success: false,
				}
			} catch (error) {
				serverResult = {
					success: false,
					message: 'The request to the server failed.',
				}
			}

			if (serverResult.message === WRONG_EMAIL_OR_PASSWORD) {
				return {
					success: false,
					message: INCORRECT_PASSWORD,
				}
			} else if (serverResult.message === PASSWORD_TOO_WEAK_RESPONSE) {
				return {
					success: false,
					message: INVALID_PASSWORD_TEXT,
				}
			}

			return serverResult
		}

		return { success: true }
	} catch (e) {
		logError({
			message: e.message,
			name: e.name,
		})
		return {
			success: false,
			message: e.message,
		}
	}
}
