import React from 'react'
import { IconComponent, wrapIcon, calculateStrokeWidth } from './IconComponent'

const MailIcon: IconComponent = wrapIcon((props) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<rect
			x="48"
			y="96"
			width="416"
			height="320"
			rx="40"
			ry="40"
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<polyline
			points="112 160 256 272 400 160"
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
	</svg>
))

export default MailIcon
