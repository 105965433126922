import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PageBanner from '../../components/PageBanner'
import ChevronForwardIcon from '../../icons/ChevronForwardIcon'
import { IconComponent } from '../../icons/IconComponent'
import LockClosedIcon from '../../icons/LockClosedIcon'
import LogoutIcon from '../../icons/LogoutIcon'
import PencilIcon from '../../icons/PencilIcon'
import { selectIsWorkingOffline } from '../../redux/users/selectors'
import LogoutConfirm from './LogoutConfirm'

const SettingsSection: React.FunctionComponent<{
	disabled?: boolean
	icon: IconComponent
	text: string
	action: () => void
	hideArrow?: boolean
}> = ({ disabled, icon, text, action, hideArrow = false }) => {
	const Icon = icon
	const isDisabled = disabled === true
	const variableClassName = isDisabled ? 'opacity-50' : 'cursor-pointer'

	return (
		<div
			className={`flex items-center pl-4 pr-4 mb-2 border-secondary-6 border-b h-16 ${variableClassName}`}
			onClick={isDisabled ? undefined : action}
		>
			<Icon className="w-8 mr-6" />
			<div className="flex-1">{text}</div>
			{!hideArrow && <ChevronForwardIcon className="w-8" thickness={2} />}
		</div>
	)
}

const Settings: React.FunctionComponent = () => {
	const history = useHistory()
	const [showLogout, setShowLogout] = useState(false)
	const isOffline = useSelector(selectIsWorkingOffline)

	return (
		<div>
			{showLogout && <LogoutConfirm close={() => setShowLogout(false)} />}
			<div>
				<PageBanner title="Settings" colorClass="secondary-4" />
				<SettingsSection
					disabled={isOffline}
					icon={PencilIcon}
					text="Edit profile"
					action={() => history.push('/settings/edit-profile')}
				/>
				<SettingsSection
					disabled={isOffline}
					icon={LockClosedIcon}
					text="Change Password"
					action={() => history.push('/settings/change-password')}
				/>
				<SettingsSection
					icon={LogoutIcon}
					text="Log out"
					action={() => setShowLogout(true)}
					hideArrow
				/>
			</div>
		</div>
	)
}
export default Settings
