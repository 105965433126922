import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setCoordinates } from '../../../redux/assessment/flow/actions'
import {
	getLocation,
	UserLocationResult,
} from '../../../services/location/locationService'

type Props = {
	intervalMs: number
}

export const LocationPoller: React.FunctionComponent<Props> = ({
	intervalMs,
}: Props) => {
	const dispatch = useDispatch()

	const poll = useCallback(async () => {
		const location = await getLocation()
		dispatch(setCoordinates(location))

		return location
	}, [dispatch])

	useEffect(() => {
		const handle = setInterval(async () => {
			const location = await poll()

			if (
				location.result === UserLocationResult.Success ||
				location.result === UserLocationResult.NotSupported
			) {
				clearInterval(handle)
			}
		}, intervalMs)

		return () => clearInterval(handle)
	}, [intervalMs, poll])

	return null
}
