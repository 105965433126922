import Action from '../../Action'
import {
	DELETE_SAVED_INCIDENT_REPORT,
	UPSERT_SAVED_INCIDENT_REPORT,
	ADD_OFFLINE_INCIDENT_REPORT,
	OFFLINE_INCIDENT_REPORT_SUBMISSION_FAILED,
	TRIGGER_INCIDENT_AUTO_SUBMISSION,
	DELETE_OFFLINE_INCIDENT_REPORT,
	RESET_OFFLINE_INCIDENT_REPORTS,
} from './actionTypes'
import IncidentReportOfflineSubmission from './IncidentReportOffline'
import { SavedIncidentReport } from './IncidentReportsOfflineState'

export const addIncidentReportSubmittedOffline = (
	incident: IncidentReportOfflineSubmission
): Action<IncidentReportOfflineSubmission> => ({
	type: ADD_OFFLINE_INCIDENT_REPORT,
	payload: incident,
})

export const deleteIncidentReportSubmittedOffline = (
	id: string
): Action<string> => ({
	type: DELETE_OFFLINE_INCIDENT_REPORT,
	payload: id,
})

export const offlineIncidentReportSubmissionFailed = (
	id: string
): Action<string> => ({
	type: OFFLINE_INCIDENT_REPORT_SUBMISSION_FAILED,
	payload: id,
})

export const triggerIncidentAutoSubmission = (): Action => ({
	type: TRIGGER_INCIDENT_AUTO_SUBMISSION,
})

export const resetOfflineIncidentReports = (): Action => ({
	type: RESET_OFFLINE_INCIDENT_REPORTS,
})

export const upsertSavedIncidentReport = (
	incident: SavedIncidentReport
): Action<SavedIncidentReport> => ({
	type: UPSERT_SAVED_INCIDENT_REPORT,
	payload: incident,
})

export const deleteSavedIncidentReport = (id: string): Action<string> => ({
	type: DELETE_SAVED_INCIDENT_REPORT,
	payload: id,
})
