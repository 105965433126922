import { useCallback, useEffect, useState } from 'react'
import { getDate } from '../testing/date'

export const useThrottledCallback = <TArgs, TResult>(
	method: (...parameters: TArgs[]) => Promise<TResult>,
	updateIntervalMs = 1000
): ((...parameters: TArgs[]) => Promise<TResult>) => {
	const [lastCall, setLastCall] = useState(0)
	const [currentParameters, setCurrentParameters] = useState<TArgs[]>([])

	const [resultPromise, setResultPromise] = useState<Promise<TResult>>()
	const [promiseResolver, setPromiseResolver] = useState<
		(value: TResult) => void
	>()

	useEffect(() => {
		if (resultPromise === undefined || promiseResolver === undefined) {
			return
		}

		const elapsed = getDate().getTime() - lastCall
		const remaining = Math.max(0, updateIntervalMs - elapsed)

		const handle = setTimeout(async () => {
			setLastCall(getDate().getTime())
			setResultPromise(undefined)
			setCurrentParameters([])
			promiseResolver(await method(...currentParameters))
		}, remaining)

		return () => clearTimeout(handle)
	}, [
		currentParameters,
		lastCall,
		method,
		promiseResolver,
		resultPromise,
		updateIntervalMs,
	])

	return useCallback(
		(...parameters: TArgs[]): Promise<TResult> => {
			setCurrentParameters(parameters)

			if (resultPromise === undefined) {
				const newPromise = new Promise((resolve) => {
					setPromiseResolver(() => resolve)
				}) as Promise<TResult>

				setResultPromise(() => newPromise)
				return newPromise
			}

			return resultPromise
		},
		[resultPromise]
	)
}
