import React from 'react'
import { useDispatch } from 'react-redux'
import { goBack } from 'connected-react-router'
import PageBanner, { PageBannerProps } from './PageBanner'

export interface FullscreenErrorProps {
	bannerProps: PageBannerProps
	error: string
	goBackText?: string
	goBackAction?: () => void
}

const FullscreenError: React.FunctionComponent<FullscreenErrorProps> = ({
	error,
	bannerProps,
	goBackText = 'Back',
	goBackAction,
}) => {
	const dispatch = useDispatch()
	const goBackClick = goBackAction || (() => dispatch(goBack()))

	return (
		<div className="w-full h-full overflow-auto flex flex-col">
			<PageBanner {...bannerProps} />
			<div className="text-error flex-1 p-2">
				{error.split('\n').map((text, index) => (
					<div key={index}>{text}</div>
				))}
			</div>
			<button
				className="btn-secondary self-center mt-8 mb-10"
				onClick={goBackClick}
			>
				{goBackText}
			</button>
		</div>
	)
}

export const withError = (
	options: Omit<FullscreenErrorProps, 'error'> & {
		formatError?: (error: string) => string
	}
): React.FunctionComponent<{ error: string }> => {
	const errorFormatting = options.formatError || ((error) => error)

	const Error: React.FunctionComponent<{ error: string }> = ({ error }) => (
		<FullscreenError {...options} error={errorFormatting(error)} />
	)
	return Error
}

export default FullscreenError
