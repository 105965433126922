import React from 'react'
import Dialog from '../../../components/Dialog'

const AlertPrompt: React.FunctionComponent<{
	setAlert: (status: boolean) => void
}> = ({ setAlert }) => {
	return (
		<Dialog title="Alert" bannerColorClass="secondary-2">
			<p className="text-center text-primary-1 mt-4">
				Injuries such as fractures (excluding fingers/toes), dislocations, loss
				of sight, amputations or any injury where A&amp;E and/or hospitilisation
				is expected.
			</p>
			<div className="flex justify-center mt-4 mb-4">
				<button
					className="btn-primary-popup mr-3 disabled:opacity-50"
					onClick={() => setAlert(true)}
				>
					Confirm
				</button>
				<button className="btn-secondary-popup" onClick={() => setAlert(false)}>
					Cancel
				</button>
			</div>
		</Dialog>
	)
}

export default AlertPrompt
