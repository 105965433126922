import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import {
	components,
	InputActionMeta,
	InputProps,
	SingleValueProps,
	ValueType,
} from 'react-select'
import AsyncSelect from 'react-select/async'
import { useThrottledCallback } from '../hooks/throttle'
import { selectIsWorkingOffline } from '../redux/users/selectors'
import { logError } from '../services/Error/errorService'
import { lookupUsers, UserLookupResult } from '../services/user/lookup'

export type UserSelection = Pick<UserLookupResult, 'mail' | 'displayName'> & {
	id?: string
	custom: boolean
}

// Internal representation for selectable options. Note the inclusion of value, without which the control becomes buggy
type InternalUserSelection = {
	item: UserSelection
	value: string
}

export const USER_SELECT_ARIA_LABEL = 'User Selection Dropdown'
export const USER_SELECT_SINGLE_VALUE_TEST_ID = 'user-select-single-value'

type Props = {
	defaultValue?: UserSelection
	onCustomValueEntered: (value?: string) => void
	onSelectionChanged: (value?: UserSelection) => void
	placeholder?: string
	getOptionLabel: (option: UserSelection) => string
}

const load = async (inputValue: string): Promise<UserLookupResult[]> => {
	try {
		return await lookupUsers(inputValue)
	} catch (err) {
		try {
			logError(err)
		} catch {
			// Possibly a network issue. Either way, we cannot log the error.
		}

		return []
	}
}

const UserSelectInput = (props: InputProps) => (
	<components.Input {...props} isHidden={false} />
)

const UserSelectSingleValue = (
	props: SingleValueProps<InternalUserSelection>
) => (
	<div data-testid="user-select-single-value">
		<components.SingleValue {...props} />
	</div>
)

export const UserSelect: React.FunctionComponent<Props> = ({
	defaultValue,
	getOptionLabel,
	onCustomValueEntered,
	onSelectionChanged,
	placeholder,
}: Props) => {
	const [selectedUser, setSelectedUser] = useState<
		ValueType<UserSelection, false>
	>(defaultValue)
	const [loading, setLoading] = useState(false)
	const [customValue, setCustomValue] = useState<string>()
	const isWorkingOffline = useSelector(selectIsWorkingOffline)

	const loadOptions = useThrottledCallback(load)

	const onLoadRequired = useCallback(
		async (inputValue: string): Promise<InternalUserSelection[]> => {
			if (isWorkingOffline || inputValue.trim().length === 0) {
				return []
			}

			setLoading(true)

			try {
				const items = await loadOptions(inputValue)
				return items.map(({ displayName, id, mail }) => ({
					item: {
						custom: false,
						displayName,
						id,
						mail,
					},
					value: id,
				}))
			} catch (err) {
				return []
			} finally {
				setLoading(false)
			}
		},
		[isWorkingOffline, loadOptions]
	)

	const onChange = useCallback(
		(option: ValueType<InternalUserSelection, false>) => {
			setSelectedUser(option?.item)
			setCustomValue(undefined)
			onSelectionChanged(option?.item ?? undefined)
		},
		[onSelectionChanged]
	)

	const onInputChange = useCallback(
		(value: string, action: InputActionMeta) => {
			if (action.action === 'input-change') {
				if (value.length === 0) {
					setSelectedUser(undefined)
					setCustomValue(undefined)

					onCustomValueEntered(undefined)
					return
				}

				setCustomValue(value)
				onCustomValueEntered(value)
			}
		},
		[onCustomValueEntered]
	)

	return (
		<AsyncSelect<InternalUserSelection, false>
			components={{
				Input: UserSelectInput,
				SingleValue: UserSelectSingleValue,
			}}
			loadOptions={onLoadRequired}
			placeholder={placeholder}
			onChange={onChange}
			onInputChange={onInputChange}
			isClearable
			inputValue={customValue}
			value={selectedUser ? { item: selectedUser, value: '' } : undefined}
			isLoading={loading}
			styles={{
				container: (base: React.CSSProperties) => ({
					...base,
					flexGrow: 1,
					borderWidth: 0,
				}),
				control: (base: React.CSSProperties) => ({
					...base,
					borderWidth: 0,
				}),
				input: (base: React.CSSProperties) => ({
					...base,
					margin: 0,
					padding: 0,
				}),
				valueContainer: (base: React.CSSProperties) => ({
					...base,
					padding: 0,
				}),
			}}
			aria-label={USER_SELECT_ARIA_LABEL}
			getOptionLabel={(item: InternalUserSelection) =>
				getOptionLabel(item.item)
			}
		/>
	)
}
