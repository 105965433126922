import React from 'react'
import { useDispatch } from 'react-redux'
import { logOut } from '../../redux/users/actions'
import Dialog from '../../components/Dialog'

const LogoutConfirm: React.FunctionComponent<{ close: () => void }> = ({
	close,
}) => {
	const dispatch = useDispatch()

	return (
		<Dialog close={close} title="Log out" bannerColorClass="secondary-2">
			<h3 className="p-6 text-primary-1 font-medium">
				Are you sure you wish to log out?
			</h3>
			<div className="flex items-center justify-evenly mt-4">
				<button
					className="btn-primary-popup"
					onClick={() => dispatch(logOut())}
				>
					Yes, log out
				</button>
				<button className="btn-secondary-popup" onClick={close}>
					Stay logged in
				</button>
			</div>
		</Dialog>
	)
}

export default LogoutConfirm
