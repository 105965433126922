import { getQuestions, Question } from '../data/IncidentQuestions'
import { Photo } from '../../../components/Photos/UploadPhotos'
import { DetailsState } from '../../../redux/incident/details/DetailsState'
import { IncidentTypeOrEmpty } from '../data/IncidentTypes'

export interface IncidentErrorState {
	[name: string]: string
}

export const getErrorState = (
	incidentType: IncidentTypeOrEmpty
): IncidentErrorState => {
	const errorState: IncidentErrorState = {}
	const questions = getQuestions(incidentType)
	questions.forEach((question) => {
		errorState[question.error] = ''
	})
	return errorState
}

export const validateErrorState = (
	incidentType: IncidentTypeOrEmpty,
	offline: boolean,
	allValues: DetailsState
): IncidentErrorState => {
	const validState: IncidentErrorState = {}
	const questions = getQuestions(incidentType)
	questions.forEach((question) => {
		if (
			question.validation &&
			checkConditionQuestion(offline, question, allValues)
		) {
			validState[question.error] = question.validation.method(
				getValidationValue(allValues, question.validation.param)
			)
		} else {
			validState[question.error] = ''
		}
	})
	return validState
}

const getValidationValue = (
	allValues: DetailsState,
	matchKey: string
): number => {
	const value = getStateValue(allValues, matchKey)
	return typeof value === 'string' ? value.trim().length : value.length
}

export const getValue = (allValues: DetailsState, matchKey: string): string => {
	const value = getStateValue(allValues, matchKey)
	return typeof value === 'string' ? value : ''
}

export const getPhotos = (
	allValues: DetailsState,
	matchKey: string
): Photo[] => {
	const value = getStateValue(allValues, matchKey)
	return typeof value !== 'string' ? value : []
}

const getStateValue = (
	allValues: DetailsState,
	matchKey: string
): string | Photo[] => allValues[matchKey]

export const checkConditionQuestion = (
	offline: boolean,
	question: Question,
	allValues: DetailsState
): boolean => {
	const condition = true
	if (question.conditionQuestion) {
		const conditionValue = getValue(
			allValues,
			question.conditionQuestion.favourableAnswer
		)

		if (
			question.conditionQuestion.equals &&
			question.conditionQuestion.offline !== undefined
		) {
			return (
				conditionValue === question.conditionQuestion.equals &&
				question.conditionQuestion.offline === offline
			)
		}

		if (question.conditionQuestion.equals) {
			return conditionValue === question.conditionQuestion.equals
		}

		return question.conditionQuestion.notEquals
			? conditionValue !== question.conditionQuestion.notEquals
			: conditionValue.length > 0
	}
	return condition
}
