import React from 'react'
import { useSelector } from 'react-redux'
import PageBanner, { PageBannerProps } from '../../components/PageBanner'
import Configuration from './config/Configuration'
import Details from './details/Details'
import { fetchUserProfile } from '../../redux/userProfile/actions'
import withAsyncAction from '../../components/withAsyncAction'
import { withLoading } from '../../components/FullscreenLoading'
import { withError } from '../../components/FullscreenError'
import Profile from '../profile/Profile'
import { selectStage } from '../../redux/incident/selectors'
import {
	INCIDENT_STAGE_CONFIG,
	INCIDENT_STAGE_DETAILS,
} from '../../redux/incident/IncidentStage'

const IncidentReporting: React.FunctionComponent<{ data: Profile | null }> = ({
	data: profile,
}) => {
	const stage = useSelector(selectStage)

	const loadComponentStage = () => {
		switch (stage) {
			case INCIDENT_STAGE_CONFIG:
				return <Configuration profile={profile} />
			case INCIDENT_STAGE_DETAILS:
				return <Details />
			default:
				throw Error('Unknown Incident Stage')
		}
	}

	return (
		<div className="flex flex-col h-full ">
			<PageBanner title="Incident Reporting" colorClass="secondary-3" />
			{profile ? loadComponentStage() : false}
		</div>
	)
}

const bannerProps: PageBannerProps = {
	title: 'Incident Reporting',
	colorClass: 'secondary-3',
}

export default withAsyncAction<Profile | null>({
	fetchAction: () => fetchUserProfile(),
	loadingComponent: withLoading(bannerProps),
	errorComponent: withError({
		bannerProps,
		formatError: (error) =>
			`Failed to load your profile - ${error.toLowerCase()}`,
	}),
	dataComponent: IncidentReporting,
	selectState: (state) => ({
		errorMessage: state.userProfile.fetchError,
		inProgress: state.userProfile.isFetching,
		data: state.userProfile.profile,
	}),
})
