import React from 'react'
import { BarLoader } from 'react-spinners'
import Colors from '../Colors'
import PageBanner, { PageBannerProps } from './PageBanner'

const FullscreenLoading: React.FunctionComponent<{
	bannerProps: PageBannerProps
}> = ({ bannerProps }) => (
	<div className="w-full h-full">
		<PageBanner {...bannerProps} />
		<BarLoader color={Colors.primary[1]} width="100%" height="0.5rem" />
	</div>
)

export const withLoading = (
	bannerProps: PageBannerProps
): React.FunctionComponent => {
	const Loading: React.FunctionComponent = () => (
		<FullscreenLoading bannerProps={bannerProps} />
	)
	return Loading
}

export default FullscreenLoading
