import { SavedRiskAssessment } from '../offline/assessment/RiskAssessmentsOfflineState'
import RootState from '../RootState'
import Selector from '../Selector'
import { AssessmentStage } from './AssessmentStage'

export const selectStage: Selector<AssessmentStage> = (state: RootState) =>
	state.assessment.assessmentRoot.stage

export const selectSaveableRiskAssessment: Selector<SavedRiskAssessment> = (
	state: RootState
) => {
	const {
		assessment: {
			flow,
			metadata: { values: metadataValues },
			assessmentRoot,
		},
	} = state

	return {
		flow,
		metadataValues,
		assessmentRoot,
	} as SavedRiskAssessment
}
