import { authenticatedFetch } from '../fetch/fetchService'
import RiskAssessmentSubmission from '../../redux/assessment/flow/submission/RiskAssessmentSubmission'
import getAPIUrl from '../getAPIUrl'
import RecentRiskAssessment, {
	ApiRecentRiskAssessment,
} from '../../redux/assessment/recentAssessments/RecentRiskAssessments'
import RiskAssessmentPdf from '../../redux/assessment/pdfAssessment/RiskAssessmentPdf'
import { getErrors, logError } from '../Error/errorService'

export interface MetadataSuggestionsResult {
	projectReferences: string[]
	forwardingEmails: string[]
}
export const getMetadataSuggestions = async (): Promise<
	MetadataSuggestionsResult
> => {
	const response = await authenticatedFetch(
		getAPIUrl('assessment/metadataSuggestions'),
		{
			method: 'GET',
		}
	)

	const result = await response.json()
	if (!response.ok) {
		logError({
			message: result.message,
			name: 'Get Metadata Suggestions Error',
		})
		throw new Error(result.message)
	}
	return result as MetadataSuggestionsResult
}

export const submitAssessment = async (
	assessment: RiskAssessmentSubmission
): Promise<void> => {
	const response = await authenticatedFetch(getAPIUrl('assessment'), {
		method: 'POST',
		body: JSON.stringify(assessment),
	})

	if (!response.ok) {
		const responseJson = await response.json()
		logError({
			message: responseJson.message,
			name: 'Submit Assessment Error',
		})
		throw new Error(responseJson.message)
	}
}

export const getRecentRiskAssessments = async (): Promise<
	RecentRiskAssessment[]
> => {
	const response = await authenticatedFetch(
		getAPIUrl('assessment/recentAssessments'),
		{
			method: 'GET',
		}
	)
	const result = await response.json()
	if (!response.ok) {
		logError({
			message: result.message,
			name: 'Get Recent Assessments Error',
		})
		throw new Error(result.message)
	}

	const castResults = result as ApiRecentRiskAssessment[]

	return castResults.map(
		(result) =>
			({
				...result,
				submissionDate:
					result.submissionDate !== undefined
						? new Date(result.submissionDate)
						: new Date(),
			} as RecentRiskAssessment)
	)
}

export const getRiskAssessmentPdf = async (
	assessmentId: string
): Promise<RiskAssessmentPdf> => {
	const response = await authenticatedFetch(
		getAPIUrl(`assessment/pdfAssessment?id=${assessmentId}`),
		{
			method: 'GET',
		}
	)
	const result = await response.json()
	if (!response.ok) {
		logError({
			message: result.message,
			name: 'Get Assessment PDF Error',
		})
		throw new Error(result.message)
	}
	return result as RiskAssessmentPdf
}
