import { replace } from 'connected-react-router'
import { all, call, put, takeEvery, takeLeading } from 'redux-saga/effects'
import * as api from '../../services/authentication'
import AuthenticationResult from '../../services/authentication/AuthenticationResult'
import { isTokenValid } from '../../services/authentication/isTokenValid'
import { logError } from '../../services/Error/errorService'
import { insertProfile } from '../../services/profile/profileService'
import Action from '../Action'
import { checkAndResetOfflineAssessmentAndIncidents } from '../offline/action'
import { autoAssessmentSubmission } from '../offline/assessment/sagas'
import { autoIncidentSubmission } from '../offline/incident/sagas'
import { serviceRequestSaga } from '../services/sagas'
import { validateProfile } from '../validateProfile'
import {
	authenticationFailed,
	authenticationSucceeded,
	changePasswordFailed,
	changePasswordSucceded,
	logoutSucceeded as logOutSucceeded,
	resetPasswordFailed,
	resetPasswordSuccess as resetPasswordSucceeded,
	setSubmitting,
	signUpFailed,
	signUpSucceeded,
	startAuthentication,
	startChangePassword,
	startResetPassword,
	startSignUp,
} from './actions'
import {
	CHANGE_PASSWORD,
	LOG_IN,
	LOG_OUT,
	RESET_PASSWORD,
	SIGN_UP,
	TRIGGER_AUTO_SUBMISSION,
	VALIDATE_TOKEN,
} from './actionTypes'
import ChangePasswordPayload from './ChangePasswordPayload'
import LogInPayload from './LogInPayload'
import ResetPasswordPayload from './ResetPasswordPayload'
import SignUpPayload from './SignUpPayload'

export function* logInSaga(
	action: Action<LogInPayload>
): Generator<unknown, void, unknown> {
	yield put(startAuthentication())

	try {
		const { username, password } = action.payload as LogInPayload

		if (!username) {
			yield put(authenticationFailed('Please enter a username'))
			return
		}
		if (!password) {
			yield put(authenticationFailed('Please enter a password'))
			return
		}

		const { success, message } = (yield call(
			api.authenticate,
			username,
			password
		)) as AuthenticationResult

		if (success) {
			yield put(checkAndResetOfflineAssessmentAndIncidents(username))
			yield put(authenticationSucceeded())
		} else {
			yield put(authenticationFailed(message as string))
		}
	} catch (e) {
		yield put(authenticationFailed(e.message))
	}
}

export function* logOutSaga(): Generator<unknown, void, unknown> {
	yield call(api.clearToken)
	yield put(logOutSucceeded())
	yield put(replace('/'))
}

export function* signUpSaga(
	action: Action<SignUpPayload>
): Generator<unknown, void, unknown> {
	yield put(startSignUp())

	try {
		const { profile, password, confirm } = action.payload as SignUpPayload

		const validateMessage = validateProfile(profile)

		if (validateMessage) {
			yield put(signUpFailed(validateMessage))
			return
		}

		if (!password) {
			yield put(signUpFailed('Please enter a password'))
			return
		}
		if (password !== confirm) {
			yield put(signUpFailed('Entered passwords do not match'))
			return
		}

		const { success, message } = (yield call(
			api.register,
			profile.name,
			profile.email,
			password
		)) as AuthenticationResult

		if (success) {
			yield call(serviceRequestSaga as never, insertProfile, profile, password)
			yield put(setSubmitting(true))
			yield put(signUpSucceeded())
			yield put(replace('/'))
		} else {
			yield put(signUpFailed(message as string))
		}
	} catch (e) {
		logError({
			message: e.message,
			name: e.name,
		})
		yield put(signUpFailed(e.message))
	} finally {
		yield put(setSubmitting(false))
	}
}

export function* resetPasswordSaga(
	action: Action<ResetPasswordPayload>
): Generator<unknown, void, unknown> {
	yield put(startResetPassword())

	try {
		const { email } = action.payload as ResetPasswordPayload

		if (!email) {
			yield put(resetPasswordFailed('Please enter an email address'))
			return
		}

		const { success, message } = (yield call(
			serviceRequestSaga as never,
			api.resetPassword,
			email
		)) as AuthenticationResult

		if (success) {
			yield put(resetPasswordSucceeded())
		} else {
			yield put(resetPasswordFailed(message as string))
		}
	} catch (e) {
		logError({
			message: e.message,
			name: e.name,
		})
		yield put(resetPasswordFailed(e.message))
	}
}

export function* changePasswordSaga(
	action: Action<ChangePasswordPayload>
): Generator<unknown, void, unknown> {
	yield put(startChangePassword())

	const {
		currentPassword,
		newPassword,
		confirmPassword,
	} = action.payload as ChangePasswordPayload

	try {
		if (!currentPassword) {
			yield put(changePasswordFailed('Please enter your password'))
			return
		}
		if (!newPassword) {
			yield put(changePasswordFailed('Please enter a new password'))
			return
		}
		if (newPassword !== confirmPassword) {
			yield put(changePasswordFailed('Entered passwords do not match'))
			return
		}

		const { success, message } = (yield call(
			serviceRequestSaga as never,
			api.changePassword,
			currentPassword,
			newPassword
		)) as AuthenticationResult

		if (!success) {
			yield put(changePasswordFailed(message as string))
			return
		}

		yield put(changePasswordSucceded())
		yield put(replace('/'))
	} catch (e) {
		logError({
			message: e.message,
			name: e.name,
		})
		yield put(changePasswordFailed(e.message))
	}
}

export function* validateTokenSaga(): Generator<unknown, void, unknown> {
	const token = yield call(api.getAccessToken)
	const isValid = yield call((token) => isTokenValid(token), token)
	if (isValid) {
		return
	}

	yield call(logOutSaga)
}

export function* autoSubmission(): Generator<unknown, void, unknown> {
	yield all([call(autoAssessmentSubmission), call(autoIncidentSubmission)])
}

export default [
	takeEvery(LOG_IN, logInSaga),
	takeEvery(LOG_OUT, logOutSaga),
	takeEvery(SIGN_UP, signUpSaga),
	takeEvery(RESET_PASSWORD, resetPasswordSaga),
	takeEvery(CHANGE_PASSWORD, changePasswordSaga),
	takeLeading(VALIDATE_TOKEN, validateTokenSaga),
	takeEvery(TRIGGER_AUTO_SUBMISSION, autoSubmission),
]
