import { AnswerType, Question, QuestionBlock } from './constants/schema'
import Action from '../../Action'
import {
	SET_RISK_ASSESSMENT_QUESTIONS,
	SET_RISK_ASSESSMENT_QUESTION_ERROR,
	SET_RISK_ASSESSMENT_QUESTION_VALUE,
	SET_RISK_ASSESSMENT_DOCUMENTATION,
	SET_RISK_ASSESSMENT_DOCUMENTATION_ERROR,
	SET_RISK_ASSESSMENT_ADDITIONAL_INFORMATION_VALUE,
	SET_RISK_ASSESSMENT_ADDITIONAL_INFORMATION_ERROR,
	SET_RISK_ASSESSMENT_SUBMISSION_ERROR,
	SET_RISK_ASSESSMENT_SUBMITTING,
	SHOW_CONFIRMATION_PROMPT,
	SET_COORDINATES,
	SHOW_LOCATION_PROMPT,
	SET_RISK_ASSESSMENT_FLOW,
	RESET_RISK_ASSESSMENT_FLOW,
} from './actionTypes'
import {
	BaseFlowUIData,
	DocumentationUIData,
	RiskAssessmentFlowState,
	QuestionUIData,
} from './FlowState'
import SetQuestionErrorPayload from './SetQuestionErrorPayload'
import SetQuestionValuePayload from './SetQuestionValuePayload'
import {
	UserLocation,
	UserLocationResult,
} from '../../../services/location/locationService'
import { logError } from '../../../services/Error/errorService'

const initialState: RiskAssessmentFlowState | null = null

const setQuestionData = <T extends BaseFlowUIData<unknown>>(
	state: RiskAssessmentFlowState,
	matches: (item: T) => boolean,
	modification: (item: T) => T,
	stateKey: keyof RiskAssessmentFlowState
): RiskAssessmentFlowState => {
	const stateData = (state[stateKey] as unknown) as Record<string, T>
	const newData: Record<string, T> = {}
	Object.entries(stateData).forEach(([key, item]) => {
		newData[key] = matches(item) ? modification(item) : item
	})

	return {
		...state,
		[stateKey]: newData,
	}
}

const matchQuestion = (questionId: string) => (
	data: QuestionUIData | DocumentationUIData
) => questionId === data.questionId

const getCurrentState = (
	state: RiskAssessmentFlowState | null
): RiskAssessmentFlowState => {
	if (state === null) {
		logError({
			message: 'No risk assessment flow in state',
			name: 'Get Current State Error',
		})
		throw new Error('No risk assessment flow in state')
	}
	return state
}

export const riskAssessmentFlow = (
	state: RiskAssessmentFlowState | null = initialState,
	action: Action<unknown>
): RiskAssessmentFlowState | null => {
	switch (action.type) {
		case SET_RISK_ASSESSMENT_QUESTIONS: {
			const questionBlock = action.payload as QuestionBlock
			return {
				...mapQuestionsToUIData(questionBlock.questions),
				additionalInformation: {
					type: 'additional-information',
					value: '',
					error: null,
				},
				questionBlock,
				submissionError: null,
				isSubmitting: false,
				showConfirmationPrompt: false,
				showLocationPrompt: false,
				coordinates: {
					latitude: 0,
					longitude: 0,
					result: UserLocationResult.None,
					error: '',
				},
			}
		}
		case SET_RISK_ASSESSMENT_QUESTION_VALUE: {
			const payload = action.payload as SetQuestionValuePayload<AnswerType | null>
			return setQuestionData<QuestionUIData>(
				getCurrentState(state),
				matchQuestion(payload.questionId),
				(question) => ({
					...question,
					value: payload.value,
					error: null,
				}),
				'questions'
			)
		}
		case SET_RISK_ASSESSMENT_DOCUMENTATION: {
			const payload = action.payload as SetQuestionValuePayload<string>
			return setQuestionData<DocumentationUIData>(
				getCurrentState(state),
				matchQuestion(payload.questionId),
				(documentation) => ({
					...documentation,
					value: payload.value,
					error: null,
				}),
				'documentation'
			)
		}
		case SET_RISK_ASSESSMENT_QUESTION_ERROR: {
			const payload = action.payload as SetQuestionErrorPayload
			return setQuestionData<QuestionUIData>(
				getCurrentState(state),
				matchQuestion(payload.questionId),
				(question) => ({
					...question,
					error: payload.error,
				}),
				'questions'
			)
		}
		case SET_RISK_ASSESSMENT_DOCUMENTATION_ERROR: {
			const payload = action.payload as SetQuestionErrorPayload
			return setQuestionData<DocumentationUIData>(
				getCurrentState(state),
				matchQuestion(payload.questionId),
				(documentation) => ({
					...documentation,
					error: payload.error,
				}),
				'documentation'
			)
		}
		case SET_RISK_ASSESSMENT_ADDITIONAL_INFORMATION_VALUE: {
			return {
				...getCurrentState(state),
				additionalInformation: {
					...getCurrentState(state).additionalInformation,
					value: action.payload as string,
					error: null,
				},
			}
		}
		case SET_RISK_ASSESSMENT_ADDITIONAL_INFORMATION_ERROR: {
			return {
				...getCurrentState(state),
				additionalInformation: {
					...getCurrentState(state).additionalInformation,
					error: action.payload as string | null,
				},
			}
		}
		case SET_RISK_ASSESSMENT_SUBMISSION_ERROR: {
			return {
				...getCurrentState(state),
				submissionError: action.payload as string | null,
			}
		}
		case SET_RISK_ASSESSMENT_SUBMITTING: {
			return {
				...getCurrentState(state),
				isSubmitting: action.payload as boolean,
			}
		}
		case SHOW_CONFIRMATION_PROMPT: {
			return {
				...getCurrentState(state),
				showConfirmationPrompt: action.payload as boolean,
			}
		}
		case SHOW_LOCATION_PROMPT: {
			return {
				...getCurrentState(state),
				showLocationPrompt: action.payload as boolean,
			}
		}
		case SET_COORDINATES: {
			return {
				...getCurrentState(state),
				coordinates: action.payload as UserLocation,
			}
		}
		case SET_RISK_ASSESSMENT_FLOW: {
			return action.payload as RiskAssessmentFlowState
		}
		case RESET_RISK_ASSESSMENT_FLOW: {
			return initialState
		}
		default: {
			return state
		}
	}
}

export const mapQuestionsToUIData = (
	questions: Question[]
): {
	questions: Record<string, QuestionUIData>
	documentation: Record<string, DocumentationUIData>
} => {
	const questionsResult: Record<string, QuestionUIData> = {}
	const documentationResult: Record<string, DocumentationUIData> = {}

	const processQuestion = (question: Question, parentId: string | null) => {
		const questionUIData: QuestionUIData = {
			type: 'question',
			error: null,
			question,
			value: null,
			questionId: parentId
				? parentId + '.' + question.questionId
				: question.questionId,
			...(parentId ? { parentId } : {}),
		}
		questionsResult[questionUIData.questionId] = questionUIData

		if (question.documentation) {
			documentationResult[questionUIData.questionId] = {
				type: 'documentation',
				documentation: question.documentation,
				error: null,
				value: '',
				questionId: questionUIData.questionId,
			}
		}

		if (question.followUpQuestion) {
			processQuestion(question.followUpQuestion, questionUIData.questionId)
		}
		return questionUIData
	}
	questions.forEach((question) => processQuestion(question, null))

	return {
		questions: questionsResult,
		documentation: documentationResult,
	}
}

export default riskAssessmentFlow
