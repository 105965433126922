import { call, put, takeEvery } from 'redux-saga/effects'
import Profile from '../../screens/profile/Profile'
import {
	getProfile,
	upsertProfile,
} from '../../services/profile/profileService'
import Action from '../Action'
import { LOG_OUT_SUCCEEDED } from '../users/actionTypes'
import {
	saveDetailsError,
	setSubmtiting,
	setUserProfile,
	setFetchingUserProfile,
	setFetchUserProfileError,
} from './actions'
import { FETCH_USER_PROFILE, SAVE_DETAILS } from './actionTypes'
import { goBack } from 'connected-react-router'
import { validateProfile } from '../validateProfile'
import { serviceRequestSaga } from '../services/sagas'
import { select } from 'redux-saga/effects'
import { logError } from '../../services/Error/errorService'
import { selectIsWorkingOffline } from '../users/selectors'
import { selectUserProfile } from './selectors'

function* saveDetailsSaga(action: Action<Profile>) {
	const profile = action.payload as Profile

	const validateMessage = validateProfile(profile)

	if (validateMessage) {
		yield put(saveDetailsError(null))
		return
	}

	try {
		yield put(saveDetailsError(null))
		yield put(setSubmtiting(true))
		yield call(serviceRequestSaga as never, upsertProfile, profile)
		yield put(setUserProfile(profile))
		yield put(goBack())
	} catch (e) {
		logError({
			message: e.message,
			name: e.name,
		})
		yield put(saveDetailsError(e.message))
	} finally {
		yield put(setSubmtiting(false))
	}
}

function* fetchUserProfile() {
	const userProfile = (yield select(selectUserProfile) as unknown) as Profile
	if (
		userProfile === null ||
		userProfile.operatingUnit === undefined ||
		userProfile.name.length === 0
	) {
		yield put(setFetchingUserProfile(true))
		yield put(setFetchUserProfileError(null))
		try {
			const isOffline = (yield select(
				selectIsWorkingOffline
			) as unknown) as boolean
			let profile: Profile = {} as Profile
			if (!isOffline) {
				profile = (yield call(
					serviceRequestSaga,
					getProfile
				) as unknown) as Profile
			}
			yield put(setUserProfile(profile))
		} catch (e) {
			logError({
				message: e.message,
				name: e.name,
			})
			yield put(setFetchUserProfileError(e.message))
		} finally {
			yield put(setFetchingUserProfile(false))
		}
	}
}

function* clearUserProfile() {
	yield put(setUserProfile(null))
}

export default [
	takeEvery(SAVE_DETAILS, saveDetailsSaga),
	takeEvery(FETCH_USER_PROFILE, fetchUserProfile),
	takeEvery(LOG_OUT_SUCCEEDED, clearUserProfile),
]
