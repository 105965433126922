import Action from '../../Action'
import { SET_INCIDENT_CONFIG, RESET_INCIDENT_CONFIG } from './actionType'
import ConfigState from './ConfigState'

const initialState: ConfigState = {
	incidentDate: '',
	operatingUnit: '',
	primaryWorkLocation: '',
	incidentType: '',
	incidentCause: '',
	forwardingEmails: [],
}

const config = (state = initialState, action: Action<unknown>): ConfigState => {
	switch (action.type) {
		case SET_INCIDENT_CONFIG: {
			return action.payload as ConfigState
		}
		case RESET_INCIDENT_CONFIG: {
			return Object.assign({}, initialState)
		}
		default: {
			return state
		}
	}
}

export default config
