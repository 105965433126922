export const validateIncidentType = (incidentType: string): string => {
	if (incidentType.trim().length === 0) {
		return 'Please select an incident type'
	}
	return ''
}

export const validateIncidentCause = (incidentCause: string): string => {
	if (incidentCause.trim().length === 0) {
		return 'Please select an incident cause'
	}
	return ''
}

export const validateOperatingUnit = (operatingUnit: string): string => {
	if (!operatingUnit || operatingUnit.trim().length === 0) {
		return 'Please select an operating unit'
	}
	return ''
}

export const validateOfficeLocation = (officeLocation: string): string => {
	if (officeLocation.trim().length === 0) {
		return 'Please select an office location'
	}
	return ''
}
