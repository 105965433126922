import { routerMiddleware } from 'connected-react-router'
import { createHashHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import createRouteReducer from './rootReducer'
import rootSaga from './rootSaga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { persistStoreTransform } from './transform'
import hardSet from 'redux-persist/es/stateReconciler/hardSet'

const sagaMiddleware = createSagaMiddleware()
export const history = createHashHistory()
const persistConfig = {
	key: 'root',
	storage: storage,
	blacklist: ['navigation', 'assessment', 'incident', 'router'],
	whitelist: ['users', 'userProfile', 'offline'],
	transforms: [persistStoreTransform],
	stateReconciler: hardSet,
}
const pReducer = persistReducer(persistConfig, createRouteReducer(history))

export const store = createStore(
	pReducer,
	{} as never,
	applyMiddleware(routerMiddleware(history), sagaMiddleware)
)
export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
