import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectIsLoggedIn } from '../redux/users/selectors'

const NonAuthRoute: React.FunctionComponent<RouteProps> = (
	props: RouteProps
) => {
	const isLoggedIn = useSelector(selectIsLoggedIn)

	if (isLoggedIn) {
		return <Redirect to="/" />
	}
	return <Route {...props}>{props.children}</Route>
}

export default NonAuthRoute
