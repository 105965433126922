import Action from '../../Action'
import {
	SET_FETCHING_RECENT_INCIDENT_REPORTS,
	SET_FETCH_INCIDENT_REPORTS_ERROR,
	SET_RECENT_INCIDENT_REPORTS,
} from './actionTypes'
import RecentIncidentReport from './RecentIncidentReport'
import RecentIncidentReportsState from './recentIncidentReportsState'

const initialState: RecentIncidentReportsState = {
	incidentReports: [],
	isFetching: false,
	error: '',
}

export const recentIncidentReports = (
	state = initialState,
	action: Action<unknown>
): RecentIncidentReportsState => {
	switch (action.type) {
		case SET_RECENT_INCIDENT_REPORTS: {
			const incidentReports = action.payload as RecentIncidentReport[]

			return {
				...state,
				incidentReports,
			}
		}
		case SET_FETCHING_RECENT_INCIDENT_REPORTS: {
			return {
				...state,
				isFetching: action.payload as boolean,
			}
		}
		case SET_FETCH_INCIDENT_REPORTS_ERROR: {
			return {
				...state,
				error: action.payload as string | null,
			}
		}
		default: {
			return state
		}
	}
}

export default recentIncidentReports
