import React from 'react'
import Modal from './Modal'

const Dialog: React.FunctionComponent<{
	bannerColorClass: string
	title: string
	close?: () => void
}> = ({ close, bannerColorClass, title, children }) => {
	return (
		<Modal close={close}>
			<div className="w-11/12 min-h-64 flex flex-col bg-background">
				<div className="bg-primary-1 w-full h-18 px-6 flex items-center">
					<h2 className="headline-lg text-background font-medium">{title}</h2>
				</div>
				<div className={`h-2 bg-${bannerColorClass} w-full`}></div>
				{children}
			</div>
		</Modal>
	)
}

export default Dialog
