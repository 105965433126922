import Action from '../../Action'
import { SET_INCIDENT_CONFIG, RESET_INCIDENT_CONFIG } from './actionType'
import ConfigState from './ConfigState'

export const setIncidentConfig = (
	config: ConfigState
): Action<ConfigState> => ({
	type: SET_INCIDENT_CONFIG,
	payload: config,
})

export const resetIncidentConfig = (): Action => ({
	type: RESET_INCIDENT_CONFIG,
})
