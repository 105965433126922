import Action from '../../Action'
import {
	SET_FETCHING_RISK_ASSESSMENT_PDF,
	SET_RISK_ASSESSMENT_PDF,
	FETCH_RISK_ASSESSMENT_PDF,
	SET_FETCH_PDF_ERROR,
} from './actionTypes'
import RiskAssessmentPdf from './RiskAssessmentPdf'

export const RequestRiskAssessmentPdf = (
	assessmentId: string
): Action<string> => ({
	type: FETCH_RISK_ASSESSMENT_PDF,
	payload: assessmentId,
})

export const setFetchingRiskAssessmentPdf = (
	fetching: boolean
): Action<boolean> => ({
	type: SET_FETCHING_RISK_ASSESSMENT_PDF,
	payload: fetching,
})

export const setRiskAssessmentPdfError = (
	error: string | null
): Action<string | null> => ({
	type: SET_FETCH_PDF_ERROR,
	payload: error,
})

export const setRiskAssessmentPdf = (
	assessmentPdf: RiskAssessmentPdf
): Action<RiskAssessmentPdf> => ({
	type: SET_RISK_ASSESSMENT_PDF,
	payload: assessmentPdf,
})
