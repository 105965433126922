import jwksClient from 'jwks-rsa'

let signingKey: string

const client = jwksClient({
	rateLimit: true,
	jwksRequestsPerMinute: 1,
	jwksUri: process.env.REACT_APP_JWKS_URI || '',
})

const kid = process.env.REACT_APP_PUBLIC_KEY_ID || ''
const fetchKey = async (): Promise<string> =>
	new Promise((resolve, reject) => {
		client.getSigningKey(kid, (err, key) => {
			if (err) {
				reject()
				return
			}
			signingKey = key.getPublicKey()
			resolve(signingKey)
		})
	})

export const getKey = async (): Promise<string> => {
	if (signingKey) {
		return signingKey
	}
	return fetchKey()
}
