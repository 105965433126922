import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import RootState from '../redux/RootState'
import Action from '../redux/Action'

interface AsyncActionData<T> {
	inProgress: boolean
	errorMessage: string | null
	data: T
}

interface WithAsyncActionData<T, P, A> {
	fetchAction?: (props: P) => Action<A>
	loadingComponent: React.FunctionComponent<{
		passedProps: React.PropsWithChildren<P>
	}>
	errorComponent: React.FunctionComponent<{
		error: string
		passedProps: React.PropsWithChildren<P>
	}>
	dataComponent: React.FunctionComponent<{
		data: T
		passedProps: React.PropsWithChildren<P>
	}>
	selectState: (state: RootState) => AsyncActionData<T>
	getDependencies?: (props: P) => React.DependencyList
}

// eslint-disable-next-line @typescript-eslint/ban-types
const withAsyncAction = <T, P = {}, A = unknown>({
	fetchAction,
	loadingComponent: LoadingComponent,
	errorComponent: ErrorComponent,
	dataComponent: DataComponent,
	selectState,
	getDependencies = () => [],
}: WithAsyncActionData<T, P, A>): React.FunctionComponent<P> => {
	const WithAsyncAction: React.FunctionComponent<P> = (props) => {
		const dispatch = useDispatch()

		useEffect(() => {
			if (fetchAction) {
				dispatch(fetchAction(props))
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, getDependencies(props))

		const state = useSelector(selectState)

		if (state.inProgress) {
			return <LoadingComponent passedProps={props} />
		}
		if (state.errorMessage) {
			return <ErrorComponent error={state.errorMessage} passedProps={props} />
		}
		return <DataComponent data={state.data} passedProps={props} />
	}
	return WithAsyncAction
}

export default withAsyncAction
