import React, { useCallback } from 'react'
import { UserSelect, UserSelection } from './UserSelect'

interface Props {
	defaultValue?: string
	onSelectionChanged: (value?: string) => void
}

const getOptionLabel = (option: UserSelection) => option.displayName

export const UserNameSelect: React.FunctionComponent<Props> = ({
	defaultValue,
	onSelectionChanged,
}: Props) => {
	const realDefaultValue: UserSelection | undefined = defaultValue
		? {
				custom: true,
				displayName: defaultValue,
				mail: '',
		  }
		: undefined

	const realOnSelectionChanged = useCallback(
		(value?: UserSelection) => onSelectionChanged(value?.displayName),
		[onSelectionChanged]
	)

	const onCustomValueEntered = useCallback(
		(value?: string) => onSelectionChanged(value),
		[onSelectionChanged]
	)

	return (
		<UserSelect
			defaultValue={realDefaultValue}
			getOptionLabel={getOptionLabel}
			onCustomValueEntered={onCustomValueEntered}
			onSelectionChanged={realOnSelectionChanged}
			placeholder="Search by name"
		/>
	)
}
