import React from 'react'

interface Props {
	address: string
}

const FailedEmailList: React.FunctionComponent<Props> = ({
	address,
}: Props) => {
	return (
		<div className="flex h-auto relative border-solid border-b border-secondary-6">
			<div className="pl-2 mt-4 ml-10 h-10">
				<div className="absolute body-base">{address}</div>
			</div>
		</div>
	)
}

export default FailedEmailList
