import React from 'react'
import { useSelector } from 'react-redux'
import { selectStage } from '../../redux/assessment/selectors'
import RiskAssessmentFlow from './flow/RiskAssessmentFlow'
import VisitCompleted from './flow/VisitCompleted'
import RiskAssessmentConfig from './RiskAssessmentConfig'

const RiskAssessment: React.FunctionComponent<unknown> = () => {
	const stage = useSelector(selectStage)

	switch (stage) {
		case 'ASSESSMENT_STAGE_CONFIG': {
			return <RiskAssessmentConfig />
		}
		case 'ASSESSMENT_STAGE_INITIAL': {
			return <VisitCompleted />
		}
		case 'ASSESSMENT_STAGE_FLOW': {
			return <RiskAssessmentFlow />
		}
		default: {
			throw Error('Unknown Assessment Stage')
		}
	}
}

export default RiskAssessment
