export const LOG_IN = 'LOG_IN'
export const SIGN_UP = 'SIGN_UP'
export const START_SIGN_UP = 'START_SIGN_UP'
export const SIGN_UP_SUCCEEDED = 'SIGN_UP_SUCCEEDED'
export const SIGN_UP_FAILED = 'SIGN_UP_FAILED'

export const START_AUTHENTICATION = 'START_AUTHENTICATION'
export const AUTHENTICATION_SUCCEEDED = 'AUTHENTICATION_SUCCEEDED'
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED'
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE'

export const LOG_OUT = 'LOG_OUT'
export const LOG_OUT_SUCCEEDED = 'LOG_OUT_SUCCEEDED'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const START_RESET_PASSWORD = 'START_RESET_PASSWORD'
export const RESET_PASSWORD_SUCCEEDED = 'RESET_PASSWORD_SUCCEEDED'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'
export const CLEAR_RESET_PASSWORD = 'CLEAR_RESET_PASSWORD'

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const START_CHANGE_PASSWORD = 'START_CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCEEDED = 'CHANGE_PASSWORD_SUCCEEDED'
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'

export const SET_SUBMITTING = 'SET_SUBMITTING'

export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'

export const WORKING_OFFLINE = 'WORKING_OFFLINE'

export const SET_HAS_CONNECTIVITY = 'SET_HAS_CONNECTIVITY'
export const TRIGGER_AUTO_SUBMISSION = 'TRIGGER_AUTO_SUBMISSION'
export const REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED'

export const RESET_REGISTER_PAGE = 'LOAD_REGISTER_PAGE'
