import React from 'react'
import { DocumentationUIData } from '../../../redux/assessment/flow/FlowState'
import { useDispatch } from 'react-redux'
import { setRiskAssessmentDocumentationValue } from '../../../redux/assessment/flow/actions'
import TextualQuestion from './TextualQuestion'
import { QuestionSize } from './YesNoQuestion'

const DocumentDetails: React.FunctionComponent<{
	documentation: DocumentationUIData
}> = ({ documentation }) => {
	const dispatch = useDispatch()

	return (
		<TextualQuestion
			prompt={documentation.documentation.prompt}
			value={documentation.value}
			setValue={(newValue) =>
				dispatch(setRiskAssessmentDocumentationValue(documentation, newValue))
			}
			error={documentation.error}
			size={QuestionSize.SMALL}
		/>
	)
}

export default DocumentDetails
