import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { replace } from 'connected-react-router'
import { setAssessmentStage } from '../../../redux/assessment/actions'
import { setRiskAssessmentFlow } from '../../../redux/assessment/flow/actions'
import { setMetadataValues } from '../../../redux/assessment/metadata/actions'
import { SavedRiskAssessment } from '../../../redux/offline/assessment/RiskAssessmentsOfflineState'
import { AssessmentRow } from './AssessmentRow'
import { StatusType } from '../Status'

interface Props {
	savedAssessment: SavedRiskAssessment
}

export const SavedRiskAssessmentRow: React.FunctionComponent<Props> = ({
	savedAssessment: { flow, metadataValues: metadata, assessmentRoot },
}: Props) => {
	const dispatch = useDispatch()

	const onOpen = useCallback(() => {
		dispatch(setRiskAssessmentFlow(flow))
		dispatch(setMetadataValues(metadata))
		dispatch(setAssessmentStage(assessmentRoot.stage))
		dispatch(replace('/assessment'))
	}, [dispatch, flow, metadata, assessmentRoot])

	return (
		<AssessmentRow
			assessment={{
				id: metadata.localId as string,
				metadata,
				stopWork: false,
			}}
			status={StatusType.Saved}
			onOpen={onOpen}
		/>
	)
}
