import { createTransform } from 'redux-persist'
import deepcopy from 'deepcopy'

// Solves issue where dates are stringified one way but this is not performed in reverse on load: https://github.com/rt2zz/redux-persist/issues/82
const dateIndicator = '_date:'

const recursiveDehydrate = (value: unknown) => {
	if (value instanceof Date) {
		return `${dateIndicator}${value.toISOString()}`
	}

	if (value instanceof Object) {
		const valueAsMap = value as Record<string, unknown>
		for (const key in value) {
			if (!Object.prototype.hasOwnProperty.call(value, key)) {
				continue
			}

			valueAsMap[key] = recursiveDehydrate(valueAsMap[key])
		}
	}

	return value
}

const recursiveRehydrate = (value: unknown) => {
	if (typeof value === 'string' && value.indexOf(dateIndicator) === 0) {
		return new Date(
			value.substring(value.indexOf(dateIndicator) + dateIndicator.length)
		)
	}

	if (value instanceof Object) {
		const valueAsMap = value as Record<string, unknown>
		for (const key in value) {
			if (!Object.prototype.hasOwnProperty.call(value, key)) {
				continue
			}

			valueAsMap[key] = recursiveRehydrate(valueAsMap[key])
		}
	}

	return value
}

export const persistStoreTransform = createTransform(
	(inbound) => {
		const copy = deepcopy(inbound)
		return recursiveDehydrate(copy) as Record<string, unknown>
	},

	(outbound: Record<string, unknown>) => {
		const copy = deepcopy(outbound)
		return recursiveRehydrate(copy)
	}
)
