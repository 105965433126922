import Profile from '../screens/profile/Profile'

export const validateProfile = (profile: Profile): string | null => {
	if (!profile.email) {
		return 'Please enter an email address'
	} else if (profile.phoneNumber && !profile.countryCode) {
		return 'Please enter a dialling code'
	} else if (!profile.operatingUnit) {
		return 'Please enter an operating unit'
	} else if (!profile.primaryWorkLocation) {
		return 'Please enter your primary work location'
	} else if (!profile.name) {
		return 'Please enter a name'
	}

	return null
}
