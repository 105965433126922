import {
	AnswerType,
	CAN_I_CONTROL_THE_HAZARD,
	Documentation,
	QuestionBlock,
} from './schema'

const DOCUMENT_DETAILS: Documentation = {
	prompt: 'Document the details',
}

const NoPreVisitAssessmentCompletedFlow: QuestionBlock = {
	id: 'NO_PRE_VISIT_ASSESSMENT_COMPLETED',
	questions: [
		{
			prompt: 'Can I slip or trip over objects around the work area?',
			questionId: 'trip-over-objects',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt: 'Can I fall from one height to another?',
			questionId: 'personal-fall-risk',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt: 'Can I injure myself by lifting or moving objects incorrectly?',
			questionId: 'lifting-injury',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt: 'Can I touch live electrics or be shocked?',
			questionId: 'electrocuted',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt:
				'Can something fall on me or can I cause something to fall on someone else?',
			questionId: 'object-fall-risk',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt:
				'Are there any other people or activities taking place in the work area that introduced increased risk?',
			questionId: 'increased-risk',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt: 'Do I have to enter a confined space?',
			questionId: 'confined-space',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt:
				'Is there a chance of exposure to chemicals, fumes, dust or radiation?',
			questionId: 'inhale-chemicals',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt: 'Do I have the correct permits for the assigned operations?',
			questionId: 'correct-permits',
			favorableAnswer: AnswerType.YES,
			followUpQuestion: {
				prompt: 'Can I obtain the correct permits?',
				favorableAnswer: AnswerType.YES,
				questionId: 'correct-permits',
			},
		},
		{
			prompt: 'Can I be caught in, on or between anything?',
			questionId: 'caught-in-anything',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt: 'Can I cause a fire?',
			questionId: 'cause-fire',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt:
				'Will I have my issued Personnel Protective Equipment (PPE) on at all times?',
			questionId: 'issued-PPE',
			favorableAnswer: AnswerType.YES,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt:
				'Are my tools & equipment appropriate for the work? (Inspection & tagged)',
			questionId: 'tools-equipment',
			favorableAnswer: AnswerType.YES,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt:
				'Am I aware of the traffic hazards? Either driving or working near forklifts or vehicles?',
			questionId: 'traffic-hazards',
			favorableAnswer: AnswerType.YES,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt: 'Are there any adverse weather conditions?',
			questionId: 'weather-conditions',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt: 'Are there any other hazards that will cause an injury?',
			questionId: 'other-hazards',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt:
				'Am I aware of local HSE procedures which includes local arrangements in case of an emergency?',
			questionId: 'hse-procedures',
			favorableAnswer: AnswerType.YES,
			documentation: DOCUMENT_DETAILS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt: 'Is everyone fit and healthy?',
			questionId: 'fit-healthy',
			favorableAnswer: AnswerType.YES,
		},
		{
			prompt:
				'Am I adequately trained, experienced and confident to undertake this assigned work whilst wearing the required PPE?',
			questionId: 'adequately-trained',
			favorableAnswer: AnswerType.YES,
		},
	],
}

export default NoPreVisitAssessmentCompletedFlow
