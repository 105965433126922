import { useCallback, useEffect, useRef, useState } from 'react'
import { getDate } from '../testing/date'

export const useRepeating = <T>(
	intervalMs: number,
	method: () => Promise<T> | T
): void => {
	const [lastCall, setLastCall] = useState(0)
	const [running, setRunning] = useState(false)
	const mounted = useRef(true) // Used to avoid updating state if we become unmounted for some reason

	const methodProxy = useCallback(async () => {
		if (!mounted.current) {
			return
		}

		setRunning(true)

		await method()

		if (!mounted.current) {
			return
		}

		setLastCall(getDate().getTime())
		setRunning(false)
	}, [method])

	useEffect(() => {
		if (running) {
			return
		}

		const now = getDate().getTime()
		const sinceLastCall = now - lastCall
		const interval = Math.max(0, intervalMs - sinceLastCall)

		const handle = setTimeout(methodProxy, interval)

		return () => clearTimeout(handle)
	}, [intervalMs, lastCall, methodProxy, running])

	useEffect(() => {
		return () => {
			mounted.current = false
		}
	}, [])
}
