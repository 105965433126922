import {
	setFetchingRiskAssessmentPdf,
	setRiskAssessmentPdfError,
	setRiskAssessmentPdf,
} from './actions'
import { call, put, takeEvery } from 'redux-saga/effects'
import { FETCH_RISK_ASSESSMENT_PDF } from './actionTypes'
import { getRiskAssessmentPdf } from '../../../services/assessment/assessmentService'
import RiskAssessmentPdf from './RiskAssessmentPdf'
import { serviceRequestSaga } from '../../services/sagas'
import Action from '..//../Action'
import { logError } from '../../../services/Error/errorService'

function* fetchRiskAssessmentPdf(action: Action<string>) {
	yield put(setFetchingRiskAssessmentPdf(true))
	yield put(setRiskAssessmentPdfError(null))

	try {
		const assessmentId = action.payload
		const assessmentPdf = (yield call(
			serviceRequestSaga as never,
			getRiskAssessmentPdf,
			assessmentId
		) as unknown) as RiskAssessmentPdf
		yield put(setRiskAssessmentPdf(assessmentPdf))
	} catch (e) {
		logError({
			message: e.message,
			name: e.name,
		})
		yield put(setRiskAssessmentPdfError(e.message))
	} finally {
		yield put(setFetchingRiskAssessmentPdf(false))
	}
}

export default [takeEvery(FETCH_RISK_ASSESSMENT_PDF, fetchRiskAssessmentPdf)]
