import { getQuestions, Question, QuestionType } from '../data/IncidentQuestions'
import { Photo } from '../../../components/Photos/UploadPhotos'
import { getIncidentSubLocations } from '../data/IncidentLocation'
import { checkConditionQuestion } from './error'
import { DetailsState } from '../../../redux/incident/details/DetailsState'
import { IncidentTypeOrEmpty } from '../data/IncidentTypes'
import { groupBy } from '../../../array/groupBy'
import { allMatch } from '../../../array/allMatch'

export const setValues = (
	newValue: string | Photo[],
	key: string,
	incidentType: IncidentTypeOrEmpty,
	offline: boolean,
	allValues: DetailsState
): DetailsState => {
	allValues[key] = newValue
	const values = resetConditionAnswers(incidentType, offline, allValues)
	if (key === 'location' && typeof newValue === 'string') {
		const subLocations = getIncidentSubLocations(newValue)
		values.subLocation = subLocations.length === 1 ? subLocations[0].value : ''
	}
	return values
}

const getResetValue = (question: Question): string | Photo[] => {
	if (question.component === QuestionType.Photos) {
		return []
	}

	return ''
}

const resetConditionAnswers = (
	incidentType: IncidentTypeOrEmpty,
	offline: boolean,
	allValues: DetailsState
): DetailsState => {
	const questions = getQuestions(incidentType)

	// Find any keys in state with questions that depend on them.
	// If a question isn't visible it should be reset, but only if the value it provides is not provided by another question.

	for (const key in allValues) {
		const questionsRelyingOnThisKey = questions.filter(
			(x) => x.conditionQuestion?.favourableAnswer === key
		)

		const questionsByKey = groupBy(questionsRelyingOnThisKey, (x) => x.value)

		for (const conditionKey in questionsByKey) {
			const questions = questionsByKey[conditionKey]
			const anyQuestionsPassedCondition = questions.some((x) =>
				checkConditionQuestion(offline, x, allValues)
			)

			if (anyQuestionsPassedCondition) {
				continue
			}

			const resetValues = questions.map(getResetValue)

			if (!allMatch(resetValues)) {
				throw new Error(
					`Two or more questions just tried to reset ${conditionKey} to different values. This doesn't make any sense to do.\n` +
						`Questions: ${questions.map((x) => x.name).join(', ')}\n` +
						`Values: ${resetValues.join(', ')}`
				)
			}

			allValues[conditionKey] = resetValues[0]
		}
	}

	return allValues
}
