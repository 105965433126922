import { call, put, select, takeEvery } from '@redux-saga/core/effects'
import { logError } from '../../../services/Error/errorService'
import { submitIncident } from '../../../services/incident/incidentService'
import { getDate } from '../../../testing/date'
import { requestRecentIncidentReports } from '../../incident/recentIncidents/actions'
import IncidentReportSubmission from '../../incident/submission/IncidentReportSubmission'
import { serviceRequestSaga } from '../../services/sagas'
import {
	selectHasConnectivity,
	selectIsLoggedIn,
	selectIsTokenValid,
} from '../../users/selectors'
import {
	deleteIncidentReportSubmittedOffline,
	offlineIncidentReportSubmissionFailed,
} from './action'
import { TRIGGER_INCIDENT_AUTO_SUBMISSION } from './actionTypes'
import IncidentReportOfflineSubmission from './IncidentReportOffline'
import { selectOfflineSubmittedIncidents } from './selectors'

export function* autoIncidentSubmission(): Generator<unknown, void, never> {
	const hasConnectivity = (yield select(selectHasConnectivity)) as boolean

	if (!hasConnectivity) {
		return
	}

	const isLoggedIn = (yield select(selectIsLoggedIn)) as boolean

	if (!isLoggedIn) {
		return
	}

	const isTokenValid = (yield select(selectIsTokenValid)) as boolean

	if (!isTokenValid) {
		return
	}

	const offlineSubmittedIncidents = (yield select(
		selectOfflineSubmittedIncidents
	)) as IncidentReportOfflineSubmission[]

	const now = getDate()
	const twoMinutes = 2 * 60 * 1000
	const twoMinutesAgo = new Date(now.getTime() - twoMinutes)

	const isSubmittable = (submission: IncidentReportOfflineSubmission) => {
		if (submission.lastSubmissionAttempted === undefined) {
			return true
		}

		return submission.lastSubmissionAttempted < twoMinutesAgo
	}

	const submittables = offlineSubmittedIncidents.filter(isSubmittable)

	for (const incident of submittables) {
		const submission: IncidentReportSubmission = {
			...incident.submissionDetails,
		}

		const id = incident.id

		try {
			yield call(serviceRequestSaga as never, submitIncident, submission)
			yield put(deleteIncidentReportSubmittedOffline(id))
		} catch (err) {
			yield put(offlineIncidentReportSubmissionFailed(id))

			yield call(logError, {
				message: err.message,
				name: err.name,
			})
		}
	}

	if (submittables.length > 0) {
		yield put(requestRecentIncidentReports())
	}
}

export default [
	takeEvery(TRIGGER_INCIDENT_AUTO_SUBMISSION, autoIncidentSubmission),
]
