import RootState from '../../RootState'
import {
	DocumentationUIData,
	RiskAssessmentFlowState,
	QuestionUIData,
	FlowUIData,
	AdditionalInformationUIData,
} from './FlowState'
import Selector from '../../Selector'
import { AnswerType, QuestionBlock } from './constants/schema'
import { UserLocation } from '../../../services/location/locationService'

const wrap = <T>(
	func: (state: RiskAssessmentFlowState) => T
): ((state: RootState) => T) => (state) => {
	const flow = state.assessment.flow
	if (!flow) {
		throw new Error('No flow found')
	}
	return func(flow)
}

export const selectHasFlow: Selector<boolean> = (state) =>
	state.assessment.flow !== null

export const selectQuestionBlockId: Selector<string | null> = (state) =>
	state.assessment.flow !== null && state.assessment.flow.questionBlock
		? state.assessment.flow.questionBlock.id
		: null

export const selectTopLevelQuestions = wrap((state) =>
	Object.values(state.questions).filter((question) => !question.parentId)
)

export const selectQuestionById = (
	questionId: string
): Selector<QuestionUIData | null> =>
	wrap((state) => findById(questionId, state.questions))

export const selectFollowUpQuestion = (
	question: QuestionUIData
): Selector<QuestionUIData | null> => {
	const followUp = question.question.followUpQuestion
	if (!followUp) {
		return () => null
	}
	return selectQuestionById(question.questionId + '.' + followUp.questionId)
}

export const selectDocumentationForQuestion = (
	question: QuestionUIData
): Selector<DocumentationUIData | null> =>
	wrap((state) =>
		findDocumentationByQuestionId(question.questionId, state.documentation)
	)

export const selectAdditionalInformation: Selector<AdditionalInformationUIData> = wrap(
	(state) => state.additionalInformation
)

export const selectIsUIVisible = (data: FlowUIData): Selector<boolean> => (
	state
) => {
	if (data.type === 'additional-information') {
		return true
	}
	const requires = (parentId: string | undefined): boolean => {
		if (!parentId) {
			return true
		}
		const parent = selectQuestionById(parentId)(state)
		if (parent === null) {
			return true
		}
		return areChildrenVisible(parent)
	}

	if (data.type === 'documentation') {
		return requires(data.questionId)
	} else {
		return requires(data.parentId)
	}
}

export const areChildrenVisible = (question: QuestionUIData): boolean => {
	return (
		question.value !== null &&
		question.value !== AnswerType.NOTAPPLICABLE &&
		question.value !== question.question.favorableAnswer
	)
}

export const selectFirstError: Selector<FlowUIData | null> = (state) => {
	const flow = state.assessment.flow
	if (!flow) {
		throw new Error('No flow found')
	}
	return (
		[
			...Object.values(flow.questions),
			...Object.values(flow.documentation),
			flow.additionalInformation,
		]
			.filter((data) => selectIsUIVisible(data)(state))
			.find((data) => data.error !== null) || null
	)
}

export const selectShouldStopWork: Selector<boolean> = (state) => {
	return !!selectStopWorkQuestion(state)
}

export const selectStopWorkQuestion: Selector<QuestionUIData | null> = wrap(
	(state) =>
		Object.values(state.questions).find((question) => {
			const essential =
				question.question.essential === undefined
					? true
					: question.question.essential

			return (
				essential &&
				question.value !== null &&
				question.value !== question.question.favorableAnswer &&
				question.value !== AnswerType.NOTAPPLICABLE &&
				!question.question.followUpQuestion
			)
		}) || null
)

export const selectIsSubmitting: Selector<boolean> = wrap(
	(state) => state.isSubmitting
)
export const selectSubmissionError: Selector<string | null> = wrap(
	(state) => state.submissionError
)

export const findById = (
	id: string,
	questions: Record<string, QuestionUIData>
): QuestionUIData | null => questions[id] || null

export const findDocumentationByQuestionId = (
	questionId: string,
	documentation: Record<string, DocumentationUIData>
): DocumentationUIData | null => documentation[questionId] || null

export const selectShowConfirmationPrompt: Selector<boolean> = wrap(
	(state) => state.showConfirmationPrompt
)

export const selectShowLocationPrompt: Selector<boolean> = wrap(
	(state) => state.showLocationPrompt
)

export const selectCoordinates: Selector<UserLocation> = wrap(
	(state) => state.coordinates
)

export const selectRiskAssessmentFlow: Selector<RiskAssessmentFlowState> = wrap(
	(state) => state
)
