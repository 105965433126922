import { combineReducers, Reducer } from 'redux'
import users from './users/reducer'
import userProfile from './userProfile/reducer'
import assessment from './assessment/reducer'
import incident from './incident/reducer'
import offline from './offline/reducer'
import error from './error/reducer'
import { History } from 'history'

import { connectRouter } from 'connected-react-router'

const createRouteReducer = <S>(history: History<S>): Reducer =>
	combineReducers({
		router: connectRouter(history),
		users,
		userProfile,
		assessment,
		incident,
		offline,
		error,
	})

export default createRouteReducer
