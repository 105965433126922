import { SelectOptions } from '../../../components/Selector'
import { IncidentType } from './IncidentTypes'

export const getPersonInvolved = (incidentType: string): SelectOptions =>
	personInvolvedList
		.filter(
			(t) =>
				(incidentType !== IncidentType.Observation &&
					t !== IncidentPersonInvolved.EmployeeAnonymous) ||
				incidentType === IncidentType.Observation
		)
		.map((personInvolved) => ({
			value: personInvolved,
			label: personInvolved.length ? personInvolved : 'Person Involved',
		}))

export enum IncidentPersonInvolved {
	Employee = 'Employee',
	EmployeeAnonymous = 'Employee (anonymous)',
	Contractors = 'Contractors',
	SubContractors = 'Sub-contractors',
	Visitor = 'Visitor',
	MemberOfPublic = 'Member of public',
}

const personInvolvedList: string[] = [
	'',
	...Object.values(IncidentPersonInvolved),
]
