import Action from '../../Action'
import { SET_INCIDENT_DETAILS, RESET_INCIDENT_DETAILS } from './actionType'
import { DetailsState } from './DetailsState'

export const setIncidentDetails = (
	details: DetailsState
): Action<DetailsState> => ({
	type: SET_INCIDENT_DETAILS,
	payload: details,
})

export const resetIncidentDetails = (): Action => ({
	type: RESET_INCIDENT_DETAILS,
})
