export enum AnswerType {
	YES = 'yes',
	NO = 'no',
	NOTAPPLICABLE = 'notApplicable',
}

export interface Documentation {
	prompt: string
}

export interface Question {
	questionId: string
	prompt: string
	favorableAnswer: AnswerType
	documentation?: Documentation
	essential?: boolean
	followUpQuestion?: Question
}

export interface QuestionBlock {
	id: string
	questions: Question[]
}

export const CAN_I_CONTROL_THE_HAZARD: Question = {
	prompt: 'Can I control the hazard?',
	questionId: 'can-control',
	favorableAnswer: AnswerType.YES,
}
