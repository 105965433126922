import React from 'react'
import { IconComponent, wrapIcon, calculateStrokeWidth } from './IconComponent'

const PersonIcon: IconComponent = wrapIcon((props) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<path
			d="M344,144c-3.92,52.87-44,96-88,96s-84.15-43.12-88-96c-4-55,35-96,88-96S348,90,344,144Z"
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<path
			d="M256,304c-87,0-175.3,48-191.64,138.6C62.39,453.52,68.57,464,80,464H432c11.44,0,17.62-10.48,15.65-21.4C431.3,352,343,304,256,304Z"
			style={{
				strokeMiterlimit: 10,
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
	</svg>
))

export default PersonIcon
