import Action from '../../Action'
import {
	FETCH_RECENT_INCIDENT_REPORTS,
	SET_FETCHING_RECENT_INCIDENT_REPORTS,
	SET_FETCH_INCIDENT_REPORTS_ERROR,
	SET_RECENT_INCIDENT_REPORTS,
} from './actionTypes'
import RecentIncidentReport from './RecentIncidentReport'

export const requestRecentIncidentReports = (): Action => ({
	type: FETCH_RECENT_INCIDENT_REPORTS,
})

export const setFetchingRecentIncidentReports = (
	fetching: boolean
): Action<boolean> => ({
	type: SET_FETCHING_RECENT_INCIDENT_REPORTS,
	payload: fetching,
})

export const setFetchRecentIncidentReportsError = (
	error: string | null
): Action<string | null> => ({
	type: SET_FETCH_INCIDENT_REPORTS_ERROR,
	payload: error,
})

export const setRecentIncidentReports = (
	recentIncidentReports: RecentIncidentReport[]
): Action<RecentIncidentReport[]> => ({
	type: SET_RECENT_INCIDENT_REPORTS,
	payload: recentIncidentReports,
})
