import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
	selectHasConnectivity,
	selectIsLoggedIn,
	selectIsTokenValid,
} from '../redux/users/selectors'
import { validateToken } from '../redux/users/actions'
import { useEffect } from 'react'

const AuthRoute: React.FunctionComponent<RouteProps> = (props: RouteProps) => {
	const dispatch = useDispatch()
	const isLoggedIn = useSelector(selectIsLoggedIn)
	const isTokenValid = useSelector(selectIsTokenValid)

	useEffect(() => {
		if (isLoggedIn && isTokenValid) {
			dispatch(validateToken())
		}
	}, [dispatch, isLoggedIn, isTokenValid])

	if (!isLoggedIn) {
		return <Redirect to="/login" push={false} />
	}
	return <Route {...props}>{props.children}</Route>
}

export default AuthRoute
