import React from 'react'
import { IconComponent, wrapIcon, calculateStrokeWidth } from './IconComponent'

const LockOpenIcon: IconComponent = wrapIcon((props) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<path
			d="M336,112a80,80,0,0,0-160,0v96"
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<rect
			x="96"
			y="208"
			width="320"
			height="272"
			rx="48"
			ry="48"
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
	</svg>
))

export default LockOpenIcon
