import { select, put } from 'redux-saga/effects'
import RootState from '../../../RootState'
import { RiskAssessmentFlowState } from '../FlowState'
import { selectIsUIVisible } from '../selectors'
import {
	validateQuestion,
	validateDocumentation,
	validateAdditionalInformation,
} from './validation'
import {
	setRiskAssessmentQuestionError,
	setRiskAssessmentDocumentationError,
	setRiskAssessmentAdditionalInformationError,
} from '../actions'

export function* validateRiskAssessmentSaga(
	setHasError: (hasError: boolean) => void
): Generator {
	const state = (yield select(
		(state) => (state as RootState).assessment.flow
	) as unknown) as RiskAssessmentFlowState
	const { questions, documentation, additionalInformation } = state

	for (const id in questions) {
		const question = questions[id]

		if (!(yield select(selectIsUIVisible(question)))) {
			continue
		}

		const error = validateQuestion(question)
		yield put(setRiskAssessmentQuestionError(question, error))

		if (error !== null) {
			setHasError(true)
		}
	}
	for (const questionId in documentation) {
		const documentationData = documentation[questionId]

		if (!(yield select(selectIsUIVisible(documentationData)))) {
			continue
		}

		const error = validateDocumentation(documentationData)
		yield put(setRiskAssessmentDocumentationError(documentationData, error))

		if (error !== null) {
			setHasError(true)
		}
	}

	const additionalInformationError = validateAdditionalInformation(
		additionalInformation
	)
	if (additionalInformationError !== null) {
		yield put(
			setRiskAssessmentAdditionalInformationError(additionalInformationError)
		)
		setHasError(true)
	}
}

export default validateRiskAssessmentSaga
