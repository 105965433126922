import { authenticatedFetch } from '../fetch/fetchService'
import IncidentReportSubmission from '../../redux/incident/submission/IncidentReportSubmission'
import getAPIUrl from '../getAPIUrl'
import { logError } from '../Error/errorService'
import RecentIncidentReport, {
	ApiRecentIncidentReport,
} from '../../redux/incident/recentIncidents/RecentIncidentReport'
import { getDate } from '../../testing/date'

export const submitIncident = async (
	incident: IncidentReportSubmission
): Promise<void> => {
	const response = await authenticatedFetch(getAPIUrl('incident'), {
		method: 'POST',
		body: JSON.stringify(incident),
	})

	if (!response.ok) {
		const responseJson = await response.json()
		logError({
			message: responseJson.message,
			name: 'Submit Incident Report Error',
		})
		throw new Error(responseJson.message)
	}
}

export const getRecentIncidentReports = async (): Promise<
	RecentIncidentReport[]
> => {
	const response = await authenticatedFetch(
		getAPIUrl('incident/recentIncidents'),
		{
			method: 'GET',
		}
	)
	const result = await response.json()
	if (!response.ok) {
		logError({
			message: result.message,
			name: 'Get Recent Incidents Error',
		})
		throw new Error(result.message)
	}

	const castResults = result as ApiRecentIncidentReport[]

	return castResults.map(
		(result) =>
			({
				...result,
				submissionDate:
					result.submissionDate !== undefined
						? new Date(result.submissionDate)
						: getDate(),
			} as RecentIncidentReport)
	)
}
