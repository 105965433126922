import React from 'react'
import AlertCircleIcon from '../../icons/AlertCircleIcon'
import BriefcaseIcon from '../../icons/BriefcaseIcon'
import FilledAlertCircleIcon from '../../icons/FilledAlertCircleIcon'
import FilledBriefcaseIcon from '../../icons/FilledBriefcaseIcon'
import FilledHomeIcon from '../../icons/FilledHomeIcon'
import FilledMedicalIcon from '../../icons/FilledMedicalIcon'
import FilledSettingsIcon from '../../icons/FilledSettingsIcon'
import HomeIcon from '../../icons/HomeIcon'
import { IconComponent } from '../../icons/IconComponent'
import MedicalIcon from '../../icons/MedicalIcon'
import SettingsIcon from '../../icons/SettingsIcon'
import RiskAssessment from '../assessment/RiskAssessment'
import Home from '../home/Home'
import IncidentReporting from '../incident/IncidentReporting'
import LoneWorking from '../loneworking/LoneWorking'
import Settings from '../settings/Settings'

export interface MenuData {
	translationKey: string
	route: string
	icon: IconComponent
	filledIcon: IconComponent
	content: React.FunctionComponent
	testRoute?: (route: string) => boolean
}

const MENUS: MenuData[] = [
	{
		translationKey: 'Home',
		route: '/',
		icon: HomeIcon,
		filledIcon: FilledHomeIcon,
		content: Home,
	},
	/* {
		translationKey: 'LoneWork',
		route: '/lone-working',
		icon: BriefcaseIcon,
		filledIcon: FilledBriefcaseIcon,
		content: LoneWorking,
	}, */
	{
		translationKey: 'Incident',
		route: '/incident',
		icon: MedicalIcon,
		filledIcon: FilledMedicalIcon,
		content: IncidentReporting,
	},
	{
		translationKey: 'Assessment',
		route: '/assessment',
		icon: AlertCircleIcon,
		filledIcon: FilledAlertCircleIcon,
		content: RiskAssessment,
	},
	{
		translationKey: 'Settings',
		route: '/settings',
		icon: SettingsIcon,
		filledIcon: FilledSettingsIcon,
		content: Settings,
		testRoute: (route: string): boolean => route.startsWith('/settings'),
	},
]

export default MENUS
