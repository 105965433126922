import React from 'react'
import RecentRiskAssessment from '../../../redux/assessment/recentAssessments/RecentRiskAssessments'
import { useSelector } from 'react-redux'
import { selectIsWorkingOffline } from '../../../redux/users/selectors'
import { AssessmentRow } from './AssessmentRow'
import {
	selectOfflineSubmittedAssessments,
	selectSavedAssessments,
} from '../../../redux/offline/assessment/selectors'
import { SavedRiskAssessmentRow } from './SavedRiskAssessmentRow'
import { StatusType } from '../Status'

interface Props {
	recentAssessments: RecentRiskAssessment[]
}

const RecentAssessments: React.FunctionComponent<Props> = ({
	recentAssessments,
}: Props) => {
	const isOffline = useSelector(selectIsWorkingOffline)
	const offlineSubmittedAssessments = useSelector(
		selectOfflineSubmittedAssessments
	)
	const savedAssessments = useSelector(selectSavedAssessments)

	return (
		<div>
			<div className="text-secondary-10 body-medium border-b border-solid border-primary-2">
				<span className="m-4">
					Recent assessments{' '}
					{isOffline && <span className="text-error">- Currently offline</span>}
				</span>
			</div>
			{savedAssessments &&
				savedAssessments.map((assessment) => (
					<SavedRiskAssessmentRow
						key={assessment.metadataValues.localId}
						savedAssessment={assessment}
					/>
				))}
			{offlineSubmittedAssessments &&
				offlineSubmittedAssessments.map((assessment) => {
					const id = `offline_${assessment.submissionDate.toISOString()}`

					return (
						<AssessmentRow
							key={id}
							assessment={{
								id,
								metadata: assessment.submissionDetails.metadata,
								submittedById: undefined,
								submissionDate: assessment.submissionDate,
								stopWork: assessment.stopWork,
							}}
							status={
								assessment.lastSubmissionAttempted === undefined
									? StatusType.PendingOffline
									: StatusType.PendingError
							}
						/>
					)
				})}
			{!isOffline &&
				recentAssessments.map((assessment) => (
					<AssessmentRow
						key={assessment.id}
						assessment={assessment}
						status={StatusType.Submitted}
					/>
				))}
		</div>
	)
}

export default RecentAssessments
