import { getDate } from '../../../testing/date'
import Action from '../../Action'
import {
	ADD_OFFLINE_RISK_ASSESSMENT,
	DELETE_OFFLINE_RISK_ASSESSMENT,
	DELETE_SAVED_RISK_ASSESSMENT,
	OFFLINE_RISK_ASSESSMENT_SUBMISSION_FAILED,
	RESET_OFFLINE_RISK_ASSESSMENTS,
	UPSERT_SAVED_RISK_ASSESSMENT,
} from './actionTypes'
import RiskAssessmentOfflineSubmission from './RiskAssessmentOffline'
import RiskAssessmentsOfflineState, {
	SavedRiskAssessment,
} from './RiskAssessmentsOfflineState'

const initialState: RiskAssessmentsOfflineState = {
	savedAssessments: [],
	submittedAssessments: [],
}

const riskAssessments = (
	state = initialState,
	action: Action<unknown>
): RiskAssessmentsOfflineState => {
	switch (action.type) {
		case ADD_OFFLINE_RISK_ASSESSMENT: {
			const assessmentOffline = action.payload as RiskAssessmentOfflineSubmission

			return {
				...state,
				submittedAssessments: [
					...state.submittedAssessments,
					assessmentOffline,
				],
			}
		}

		case DELETE_OFFLINE_RISK_ASSESSMENT: {
			const localId = action.payload as string

			return {
				...state,
				submittedAssessments: state.submittedAssessments.filter(
					(x) => x.submissionDetails.metadata.localId !== localId
				),
			}
		}

		case OFFLINE_RISK_ASSESSMENT_SUBMISSION_FAILED: {
			const localId = action.payload as string

			const match = state.submittedAssessments.find(
				(x) => x.submissionDetails.metadata.localId === localId
			)

			if (!match) {
				return state
			}

			const updated: RiskAssessmentOfflineSubmission = {
				...match,
				lastSubmissionAttempted: getDate(),
			}

			return {
				...state,
				submittedAssessments: [
					...state.submittedAssessments.filter((x) => x !== match),
					updated,
				],
			}
		}

		case UPSERT_SAVED_RISK_ASSESSMENT: {
			const assessment = action.payload as SavedRiskAssessment
			const tweakedAssessment: SavedRiskAssessment = {
				...assessment,
				flow: {
					...assessment.flow,
					isSubmitting: false,
					submissionError: null,
				},
			}

			const withReplaced = [
				tweakedAssessment,
				...state.savedAssessments.filter(
					(x) => x.metadataValues.localId !== assessment.metadataValues.localId
				),
			]

			return {
				...state,
				savedAssessments: withReplaced,
			}
		}

		case DELETE_SAVED_RISK_ASSESSMENT: {
			const localId = action.payload as string

			return {
				...state,
				savedAssessments: state.savedAssessments.filter(
					(x) => x.metadataValues.localId !== localId
				),
			}
		}

		case RESET_OFFLINE_RISK_ASSESSMENTS:
			return initialState

		default: {
			return state
		}
	}
}

export default riskAssessments
