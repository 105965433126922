import { logError } from '../../../../services/Error/errorService'
import { DocumentationUIData, QuestionUIData } from '../FlowState'
import {
	areChildrenVisible,
	findById,
	findDocumentationByQuestionId,
} from '../selectors'
import { QuestionAnswer } from './RiskAssessmentSubmission'

const generateAnswer = (
	question: QuestionUIData,
	getQuestionById: (id: string) => QuestionUIData,
	getDocumentationByQuestionId: (id: string) => DocumentationUIData
): QuestionAnswer => {
	const result: QuestionAnswer = {
		answer: question.value,
	}

	if (!areChildrenVisible(question)) {
		return result
	}

	if (question.question.documentation) {
		result.documentation = getDocumentationByQuestionId(
			question.questionId
		).value
	}

	if (question.question.followUpQuestion) {
		const followUp = getQuestionById(
			question.questionId + '.' + question.question.followUpQuestion.questionId
		)
		result.followUp = generateAnswer(
			followUp,
			getQuestionById,
			getDocumentationByQuestionId
		)
	}

	return result
}

const generateAnswers = (
	questions: Record<string, QuestionUIData>,
	documentation: Record<string, DocumentationUIData>
): Record<string, QuestionAnswer> => {
	const getQuestion = (id: string) => {
		const result = findById(id, questions)
		if (!result) {
			logError({
				message: 'No question found',
				name: 'Get Question Error',
			})
			throw new Error('No question found')
		}
		return result
	}
	const getDocumentation = (qId: string) => {
		const result = findDocumentationByQuestionId(qId, documentation)
		if (!result) {
			logError({
				message: 'No documentation found',
				name: 'Get Documentation Error',
			})
			throw new Error('No documentation found')
		}
		return result
	}

	const result: Record<string, QuestionAnswer> = {}
	Object.entries(questions)
		.filter((q) => !q[1].parentId)
		.forEach(([id, data]) => {
			result[id] = generateAnswer(data, getQuestion, getDocumentation)
		})
	return result
}

export default generateAnswers
