import { replace } from 'connected-react-router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Colors from '../../Colors'
import { MenuData } from './Menus'

const StyledPosition = styled.div`
	height: calc(5rem + calc(env(safe-area-inset-bottom) * 0.5));
`

const MenuControl: React.FunctionComponent<{
	menus: Array<MenuData>
}> = ({ menus }) => {
	return (
		<StyledPosition className="bottom-0 left-0 w-screen fixed border-primary-2 border-t z-10 pl-safe pr-safe bg-nav">
			<ul className="flex flex-1 flex-row justify-around pt-3">
				{menus.map((menu) => (
					<MenuItem {...menu} key={menu.route} />
				))}
			</ul>
		</StyledPosition>
	)
}

export const MenuItem: React.FunctionComponent<MenuData> = ({
	icon,
	filledIcon,
	translationKey,
	route,
	testRoute,
}) => {
	const dispatch = useDispatch()
	const location = useLocation()
	const { t } = useTranslation()

	const selected = testRoute
		? testRoute(location.pathname)
		: location.pathname === route

	const Icon = icon
	const FilledIcon = filledIcon

	return (
		<li
			className="flex flex-col items-center cursor-pointer"
			onClick={() => dispatch(replace(route))}
		>
			{selected ? (
				<FilledIcon className="w-6 mb-1" fill={Colors.primary[1]} />
			) : (
				<Icon className="w-6 mb-1" />
			)}

			<span className={`body-sm ${selected ? 'text-primary-1' : ''}`}>
				{t(`menu.${translationKey}`)}
			</span>
		</li>
	)
}

export default MenuControl
