import React, { useState, useEffect } from 'react'
import IconFormField from '../../components/IconFormField'
import PageBanner from '../../components/PageBanner'
import SafeForm from '../../components/SafeForm'
import MailIcon from '../../icons/MailIcon'
import { useDispatch, useSelector } from 'react-redux'
import { goBack, replace } from 'connected-react-router'
import {
	selectIsResetting,
	selectIsReset,
	selectAuthState,
} from '../../redux/users/selectors'
import RootState from '../../redux/RootState'
import { clearPasswordReset, resetPassword } from '../../redux/users/actions'

const ConfirmationMessage: React.FunctionComponent = () => {
	const dispatch = useDispatch()
	const isReset = useSelector(selectIsReset)
	const { error } = useSelector(selectAuthState)

	return isReset ? (
		<>
			<h3 className="px-4 h-56">
				If the email address you entered is in our system, you should receive a
				change password email shortly
			</h3>
			<div className="flex flex-col items-center">
				<button
					className="btn-primary"
					onClick={() => dispatch(replace('/login'))}
				>
					Log in
				</button>
			</div>
		</>
	) : (
		<>
			<h3 className="px-4 h-56 text-error">
				Failed to reset password - {error?.toLocaleLowerCase()}
			</h3>
			<div className="flex flex-col items-center">
				<button
					className="btn-secondary"
					onClick={() => dispatch(clearPasswordReset())}
				>
					Try again
				</button>
			</div>
		</>
	)
}

const ForgotPasswordForm: React.FunctionComponent = () => {
	const dispatch = useDispatch()
	const [email, setEmail] = useState<string>('')
	const isSubmitting = useSelector(selectIsResetting)

	return (
		<>
			<SafeForm
				onSubmit={() => {
					dispatch(resetPassword(email))
				}}
			>
				<div className="h-56">
					<h3 className="px-4">Please enter your registered email address</h3>
					<IconFormField icon={MailIcon}>
						<input
							className="w-full"
							type="email"
							name="email"
							placeholder="Email address"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</IconFormField>
				</div>

				<div className="flex flex-col items-center">
					<button className="btn-primary" disabled={isSubmitting}>
						Submit
					</button>
					<button
						className="btn-secondary mt-8"
						onClick={(e) => {
							e.preventDefault()
							dispatch(goBack())
						}}
					>
						Cancel
					</button>
				</div>
			</SafeForm>
		</>
	)
}

const selectShowForm = (state: RootState): boolean => {
	return !(state.users.isReset || state.users.error)
}

const ForgotPassword: React.FunctionComponent = () => {
	const showForm = useSelector(selectShowForm)
	const dispatch = useDispatch()
	useEffect(() => {
		dispatch(clearPasswordReset())
	}, [dispatch])

	return (
		<div className="w-full">
			<PageBanner title="Forgot password" colorClass="primary-2" />
			<div className="mt-10">
				{showForm ? <ForgotPasswordForm /> : <ConfirmationMessage />}
			</div>
		</div>
	)
}

export default ForgotPassword
