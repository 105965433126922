import React from 'react'
import Dialog from '../../../components/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import {
	submitRiskAssessment,
	showConfirmationPrompt,
} from '../../../redux/assessment/flow/actions'
import {
	selectIsSubmitting,
	selectSubmissionError,
} from '../../../redux/assessment/flow/selectors'

const AssessmentConfirmationPrompt: React.FunctionComponent = () => {
	const isSubmitting = useSelector(selectIsSubmitting)
	const error = useSelector(selectSubmissionError)

	const dispatch = useDispatch()

	return (
		<Dialog title="Submit" bannerColorClass="secondary-2">
			<p className="text-center text-primary-1 mt-4">
				This is an accurate representation of the hazards in the work area. You
				will be unable to edit this once submitted.
			</p>
			<p className="text-center text-primary-1 mt-4">
				The date, time and location will be captured as part of this submission.
			</p>
			<div className="body-sm text-error text-center">{error}</div>
			<div className="flex justify-center mt-4 mb-4">
				<button
					className={`btn-primary-popup mr-3 ${
						isSubmitting ? 'opacity-50' : ''
					}`}
					disabled={isSubmitting}
					onClick={() => {
						dispatch(submitRiskAssessment())
					}}
				>
					Confirm
				</button>
				<button
					className={`btn-secondary-popup ${isSubmitting ? 'opacity-50' : ''}`}
					onClick={() => dispatch(showConfirmationPrompt(false))}
					disabled={isSubmitting}
				>
					Cancel
				</button>
			</div>
		</Dialog>
	)
}

export default AssessmentConfirmationPrompt
