import { HSRep } from '../../redux/assessment/metadata/MetadataState'
import { getLocations } from './getLocations'

export const getHeadOfOperations = (): HSRep => getLocations().headOfOperations

export const getLocationRepresentative = (
	operatingUnit: string,
	locationName: string
): HSRep => {
	const locations = getLocations()

	const unit = locations.operatingUnits[operatingUnit]

	if (unit === undefined) {
		return locations.headOfOperations
	}

	const locationRepresentative = unit.representatives[locationName]

	if (locationRepresentative === undefined) {
		return unit.managingDirector ?? locations.headOfOperations
	}

	return locationRepresentative
}

export const getManagingDirector = (operatingUnit: string): HSRep => {
	const locations = getLocations()

	const unit = locations.operatingUnits[operatingUnit]

	if (unit === undefined) {
		return locations.headOfOperations
	}

	return unit.managingDirector ?? locations.headOfOperations
}
