import RootState from '../../RootState'
import { MetadataValues, MetadataSuggestions } from './MetadataState'
import Selector from '../../Selector'

export const selectHasFilledInValues: Selector<boolean> = (state) => {
	const values = selectMetadataValues(state)
	return (
		values.title.length > 0 &&
		values.projectReference.length > 0 &&
		values.forwardingEmails.length > 0
	)
}

export const selectMetadataValues = (state: RootState): MetadataValues =>
	state.assessment.metadata.values

export const selectMetadataSuggestions = (
	state: RootState
): MetadataSuggestions => state.assessment.metadata.suggestions
