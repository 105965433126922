import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useRepeating } from '../hooks/useRepeating'
import { triggerAutoSubmission } from '../redux/users/actions'

export const AUTO_SUBMISSION_INTERVAL_MS = 30000

export const AutoSubmissionTrigger: React.FunctionComponent = () => {
	const dispatch = useDispatch()

	useRepeating(
		AUTO_SUBMISSION_INTERVAL_MS,
		useCallback(async () => {
			dispatch(triggerAutoSubmission())
		}, [dispatch])
	)

	return null
}
