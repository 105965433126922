import React from 'react'
import styled from 'styled-components'
import AuthRoute from '../../components/AuthRoute'
import { MenuData } from './Menus'

const StyledContent = styled.div`
	height: calc(100vh - 5rem - calc(env(safe-area-inset-bottom) * 0.5));
`

const MenuContent: React.FunctionComponent<{ menus: Array<MenuData> }> = ({
	menus,
	children,
}) => (
	<StyledContent className="w-screen">
		{menus.map((menu) => {
			const Content = menu.content
			return (
				<AuthRoute exact path={menu.route} key={menu.route}>
					<Content />
				</AuthRoute>
			)
		})}
		{children}
	</StyledContent>
)

export default MenuContent
