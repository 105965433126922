import RootState from '../../RootState'
import Selector from '../../Selector'
import RiskAssessmentOfflineSubmission from './RiskAssessmentOffline'
import { SavedRiskAssessment } from './RiskAssessmentsOfflineState'

export const selectOfflineSubmittedAssessments: Selector<
	RiskAssessmentOfflineSubmission[]
> = (state: RootState) => state.offline.riskAssessments.submittedAssessments

export const selectSavedAssessments: Selector<SavedRiskAssessment[]> = (
	state: RootState
) => state.offline.riskAssessments.savedAssessments
