export interface AuthConfig {
	apiUrl: string
	audience: string
	clientId: string
	connection: string
	enableTestMode: boolean
	resetPasswordUrl: string
	signupUrl: string
	testOperatingUnit: {
		managingDirectorEmail: string
		representativeEmail: string
	}
	tokenUrl: string
	versionNumber: string
}

export const getEnvironmentVariables = (): AuthConfig => {
	const env = process.env

	if (!env.REACT_APP_AUTH_AUDIENCE) {
		throw Error('No authentication audience provided via environment variables')
	}
	if (!env.REACT_APP_AUTH_CLIENT_ID) {
		throw Error(
			'No authentication client id provided via environment variables'
		)
	}
	if (!env.REACT_APP_AUTH_TOKEN_URL) {
		throw Error('No authentication URL provided via environment variables')
	}
	if (!env.REACT_APP_AUTH_SIGN_UP_URL) {
		throw Error('No signup URL provided via environment variables')
	}
	if (!env.REACT_APP_AUTH_DB_CONNECTION) {
		throw Error('No db connection provided via environment variables')
	}
	if (!env.REACT_APP_AUTH_RESET_PASSWORD_URL) {
		throw Error('No reset password URL provided via environment variables')
	}
	if (!env.REACT_APP_API_URL) {
		throw Error('No API URL provided via environment variables')
	}
	if (!env.REACT_APP_VERSION_NUMBER) {
		throw Error('No version number provided via environment variables')
	}

	const enableTestMode =
		(env.REACT_APP_ENABLE_TEST_MODE ?? '').toLowerCase().trim() === 'true'

	if (enableTestMode) {
		if (!env.REACT_APP_TEST_OU_MANAGING_DIRECTOR_EMAIL) {
			throw Error(
				'Testing mode is enabled, but no managing director email has been specified for the test operating unit.'
			)
		}

		if (!env.REACT_APP_TEST_OU_REPRESENTATIVE_EMAIL) {
			throw Error(
				'Testing mode is enabled, but no representative email has been specified for the test operating unit.'
			)
		}
	}

	return {
		apiUrl: env.REACT_APP_API_URL,
		audience: env.REACT_APP_AUTH_AUDIENCE,
		clientId: env.REACT_APP_AUTH_CLIENT_ID,
		connection: env.REACT_APP_AUTH_DB_CONNECTION,
		enableTestMode,
		resetPasswordUrl: env.REACT_APP_AUTH_RESET_PASSWORD_URL,
		signupUrl: env.REACT_APP_AUTH_SIGN_UP_URL,
		testOperatingUnit: {
			managingDirectorEmail:
				env.REACT_APP_TEST_OU_MANAGING_DIRECTOR_EMAIL ?? '',
			representativeEmail: env.REACT_APP_TEST_OU_REPRESENTATIVE_EMAIL ?? '',
		},
		tokenUrl: env.REACT_APP_AUTH_TOKEN_URL,
		versionNumber: env.REACT_APP_VERSION_NUMBER,
	}
}
