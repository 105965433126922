import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import QuestionLoader from './QuestionLoader'
import { Photo } from '../../../components/Photos/UploadPhotos'
import { getQuestions } from '../data/IncidentQuestions'
import { getErrorState, validateErrorState } from './error'
import { setValues } from './value'
import SubmissionPrompt from './SubmissionPrompt'
import { setIncidentStage } from '../../../redux/incident/actions'
import { INCIDENT_STAGE_CONFIG } from '../../../redux/incident/IncidentStage'
import { selectConfig } from '../../../redux/incident/config/selectors'
import { selectDetails } from '../../../redux/incident/details/selectors'
import { setIncidentDetails } from '../../../redux/incident/details/actions'
import CallPrompt from './CallPrompt'
import { showIncidentReportSubmissionPrompt } from '../../../redux/incident/submission/actions'
import { selectSubmission } from '../../../redux/incident/submission/selectors'
import { selectIsWorkingOffline } from '../../../redux/users/selectors'

const Details: React.FunctionComponent = () => {
	const dispatch = useDispatch()
	const offline = useSelector(selectIsWorkingOffline)
	const config = useSelector(selectConfig)
	const details = useSelector(selectDetails)
	const ref = React.createRef<HTMLDivElement>()
	const incidentType = config.incidentType

	const [allValues, setAllValues] = useState(details)
	const [allErrors, setAllErrors] = useState(getErrorState(incidentType))
	const [validationError, setValidationError] = useState(false)
	const { showCallPrompt, showSubmissionPrompt } = useSelector(selectSubmission)

	const autoScroll = useCallback(() => {
		if (ref.current) {
			ref.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [ref])

	useEffect(() => {
		if (validationError && showSubmissionPrompt) {
			autoScroll()
			dispatch(showIncidentReportSubmissionPrompt(false))
		}
	}, [autoScroll, dispatch, showSubmissionPrompt, validationError])

	const setState = useCallback(
		(
			newValue: string | Photo[],
			key: string,
			newError: string,
			errorKey: string
		) => {
			const updatedValues = setValues(
				newValue,
				key,
				incidentType,
				offline,
				allValues
			)
			setAllValues(updatedValues)
			setAllErrors({
				...allErrors,
				[errorKey]: newError,
			})
			dispatch(setIncidentDetails(updatedValues))
		},
		[allErrors, allValues, dispatch, incidentType, offline]
	)

	const validateData = useCallback(() => {
		const validateError = validateErrorState(incidentType, offline, allValues)
		setAllErrors(validateError)

		const error =
			Object.values(validateError).filter((x) => x.length > 0).length > 0

		setValidationError(error)
		dispatch(showIncidentReportSubmissionPrompt(true))
	}, [allValues, dispatch, incidentType, offline])

	const setSubmissionPrompt = useCallback(
		(confirmed: boolean) => {
			dispatch(showIncidentReportSubmissionPrompt(confirmed))
		},
		[dispatch]
	)

	return (
		<div className="flex-1 overflow-auto">
			{validationError && (
				<div ref={ref} className="p-5 pb-0">
					<div className="border-error border-3 pl-2">
						<div className="body-sm">Please check the form</div>
						<div className="body-sm text-error underline">
							Please fill in the required fields
						</div>
					</div>
				</div>
			)}
			{!validationError && showSubmissionPrompt && (
				<SubmissionPrompt
					setSubmission={(confirmed) => setSubmissionPrompt(confirmed)}
				/>
			)}
			{showCallPrompt && <CallPrompt />}
			{getQuestions(incidentType).map((question, key) => (
				<QuestionLoader
					key={`${question.value}-${key}`}
					offline={offline}
					question={question}
					values={allValues}
					errors={allErrors}
					incidentType={incidentType}
					setStates={setState}
				/>
			))}
			<div className="flex flex-col items-center mt-16 mb-16">
				<button
					aria-label="Submit"
					className="btn-primary mt-4"
					onClick={() => validateData()}
				>
					Submit
				</button>
				<button
					aria-label="Cancel"
					className="btn-secondary mt-8"
					onClick={(e) => {
						e.preventDefault()
						dispatch(setIncidentStage(INCIDENT_STAGE_CONFIG))
					}}
				>
					Cancel
				</button>
			</div>
		</div>
	)
}

export default Details
