import { getEnvironmentVariables } from '../../../services/authentication/getEnvironmentVariables'
import {
	TEST_OPERATING_UNIT_LOCATION,
	TEST_OPERATING_UNIT_SUB_LOCATION,
} from '../../profile/getLocations'
import { Locations } from './IncidentLocation'

export const getLocationList = (): Locations => {
	const { enableTestMode } = getEnvironmentVariables()

	const locations = {
		...locationList,
		location: {
			...locationList.location,
		},
	}

	if (enableTestMode) {
		locations.location[TEST_OPERATING_UNIT_LOCATION] = {
			subLocation: ['', TEST_OPERATING_UNIT_SUB_LOCATION],
		}
	}

	return locations
}

const locationList: Locations = {
	location: {
		'': { subLocation: [] },
		'BMT Office (UK)': {
			subLocation: [
				'',
				'Aberdeen, UK',
				'Barrow In Furness, UK',
				'Bath, UK (Berkeley House)',
				'Bath, UK (Maritime House)',
				'Fareham, UK',
				'Keynsham, UK',
				'Leeds, UK',
				'London, UK',
				'Plymouth, UK',
				'Southampton, UK',
				'Teddington, UK',
				'Weymouth, UK',
			],
		},
		'Client Office (UK)': {
			subLocation: ['', 'Embedded Employee', 'Visiting Employee'],
		},
		'BMT Office (Europe)': {
			subLocation: [
				'',
				'Antwerp, Belgium',
				'Amsterdam, The Netherlands',
				'Arnhem, The Netherlands',
				'Den Helder, The Netherlands',
				'Groningen, The Netherlands',
				'Rotterdam, The Netherlands',
			],
		},
		'Client Office (Europe)': {
			subLocation: ['', 'Embedded Employee', 'Visiting Employee'],
		},
		'BMT Office (Asia)': { subLocation: ['Singapore'] },
		'Client Office (Asia)': {
			subLocation: ['', 'Embedded Employee', 'Visiting Employee'],
		},
		'BMT Office (Australia)': {
			subLocation: [
				'',
				'Adelaide, Australia',
				'Bangalow, Australia',
				'Brisbane, Australia',
				'Canberra, Australia',
				'Melbourne, Australia',
				'Newcastle, Australia',
				'Perth, Australia',
				'Sydney, Australia',
			],
		},
		'Client Office (Australia)': {
			subLocation: ['', 'Embedded Employee', 'Visiting Employee'],
		},
		'BMT Office (Americas)': {
			subLocation: [
				'',
				'Houston, USA',
				'Calgary, Canada',
				'Dartmouth, Canada (Halifax)',
				'Ottawa, Canada',
				'Victoria, Canada',
			],
		},
		'Client Office (Americas)': {
			subLocation: ['', 'Embedded Employee', 'Visiting Employee'],
		},
		'BMT Workshop': {
			subLocation: [
				'',
				'Brisbane (NSW)',
				'Houston (TX)',
				'Newcastle (NSW)',
				'Ottawa (ONT)',
			],
		},
		'Home Workspace': { subLocation: ['Employee Home'] },
		Onshore: {
			subLocation: [
				'',
				'Dockyard/Shipyard',
				'Field Survey Land',
				'Mine',
				'Port',
				'Site Visit',
				'Other',
			],
		},
		Offshore: {
			subLocation: ['', 'Dive Site', 'Platform', 'Vessel', 'Other'],
		},
		Travelling: {
			subLocation: [
				'',
				'Air Travel',
				'Bicycle/Scooter',
				'Motor Vehicle',
				'Rail',
				'Waterborne Transport',
			],
		},
	},
}
