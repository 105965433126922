import { useState } from 'react'

export const useValueWithError = <T>(
	defaultValue: T
): [T, (newValue: T) => void, string, (newError: string) => void] => {
	const [value, setValue] = useState(defaultValue)
	const [error, setError] = useState('')

	return [value, setValue, error, setError]
}

export const useValueWithValidator = <T>(
	defaultValue: T,
	validator: (value: T) => string | null
): [
	T,
	(newValue: T) => void,
	string,
	(newError: string) => void,
	() => boolean
] => {
	const [value, setValue, error, setError] = useValueWithError(defaultValue)
	const internalValidator = (): boolean => {
		const errorMessage = validator(value)
		setError(errorMessage || '')
		return errorMessage === null
	}

	return [value, setValue, error, setError, internalValidator]
}
