import {
	QuestionUIData,
	DocumentationUIData,
	AdditionalInformationUIData,
} from '../FlowState'

export const MAX_ANSWER_LENGTH = 2500
export const MIN_ANSWER_LENGTH = 30

export const validateQuestion = (question: QuestionUIData): string | null => {
	if (question.value === null) {
		return 'This field cannot be empty'
	}
	return null
}

export const validateDocumentation = (
	documentation: DocumentationUIData
): string | null => {
	const length = documentation.value.trim().length
	if (length === 0) {
		return 'This field cannot be empty'
	}
	if (length < MIN_ANSWER_LENGTH) {
		return `Please enter more information (${length}/${MIN_ANSWER_LENGTH} characters)`
	}
	if (length > MAX_ANSWER_LENGTH) {
		return `Please enter at most ${MAX_ANSWER_LENGTH} characters (you entered ${length})`
	}
	return null
}

export const validateAdditionalInformation = (
	information: AdditionalInformationUIData
): string | null => {
	if (information.value.length > MAX_ANSWER_LENGTH) {
		return `Please enter at most ${MAX_ANSWER_LENGTH} characters (you entered ${information.value.length})`
	}
	return null
}
