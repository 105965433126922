import { getDate } from '../../../testing/date'
import Action from '../../Action'
import {
	ADD_OFFLINE_INCIDENT_REPORT,
	DELETE_OFFLINE_INCIDENT_REPORT,
	DELETE_SAVED_INCIDENT_REPORT,
	OFFLINE_INCIDENT_REPORT_SUBMISSION_FAILED,
	RESET_OFFLINE_INCIDENT_REPORTS,
	UPSERT_SAVED_INCIDENT_REPORT,
} from './actionTypes'
import IncidentReportOfflineSubmission from './IncidentReportOffline'
import IncidentReportsOfflineState, {
	SavedIncidentReport,
} from './IncidentReportsOfflineState'

const initialState: IncidentReportsOfflineState = {
	savedIncidents: [],
	submittedIncidents: [],
}

export const incidentReports = (
	state = initialState,
	action: Action<unknown>
): IncidentReportsOfflineState => {
	switch (action.type) {
		case ADD_OFFLINE_INCIDENT_REPORT: {
			const incidentOffline = action.payload as IncidentReportOfflineSubmission

			const incidents = [...state.submittedIncidents, incidentOffline]

			return {
				...state,
				submittedIncidents: incidents,
			}
		}

		case DELETE_OFFLINE_INCIDENT_REPORT: {
			const id = action.payload as string

			return {
				...state,
				submittedIncidents: state.submittedIncidents.filter((x) => x.id !== id),
			}
		}

		case OFFLINE_INCIDENT_REPORT_SUBMISSION_FAILED: {
			const id = action.payload as string

			const match = state.submittedIncidents.find((x) => x.id === id)

			if (!match) {
				return state
			}

			const updated: IncidentReportOfflineSubmission = {
				...match,
				lastSubmissionAttempted: getDate(),
			}

			return {
				...state,
				submittedIncidents: [
					...state.submittedIncidents.filter((x) => x !== match),
					updated,
				],
			}
		}

		case UPSERT_SAVED_INCIDENT_REPORT: {
			const incident = action.payload as SavedIncidentReport
			const withReplaced = [
				incident,
				...state.savedIncidents.filter((s) => s.id !== incident.id),
			]

			return {
				...state,
				savedIncidents: withReplaced,
			}
		}
		case DELETE_SAVED_INCIDENT_REPORT: {
			const id = action.payload as string

			return {
				...state,
				savedIncidents: state.savedIncidents.filter((s) => s.id !== id),
			}
		}
		case RESET_OFFLINE_INCIDENT_REPORTS:
			return initialState
		default: {
			return state
		}
	}
}

export default incidentReports
