import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useRepeating } from '../hooks/useRepeating'
import { setHasConnectivity } from '../redux/users/actions'
import getAPIUrl from '../services/getAPIUrl'

const POLL_INTERVAL_MS = 20000

export const OnlinePoller: React.FunctionComponent = () => {
	const dispatch = useDispatch()

	useRepeating(
		POLL_INTERVAL_MS,
		useCallback(async () => {
			let success = true

			try {
				await fetch(getAPIUrl('ping'))
			} catch (e) {
				success = false
			}

			dispatch(setHasConnectivity(success))
		}, [dispatch])
	)

	return null
}
