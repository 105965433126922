import React, { useCallback } from 'react'
import { Question, QuestionType } from '../data/IncidentQuestions'
import FormField from '../../../components/IconFormField'
import Selector from '../../../components/Selector'
import OptionsButtonQuestion, {
	QuestionSize,
} from '../../../components/OptionsButtonQuestion'
import TextualQuestion from '../../../components/TextualQuestion'
import UploadPhotos, { Photo } from '../../../components/Photos/UploadPhotos'
import {
	getSelectionOptions,
	getNatureOfIncidentText,
} from '../data/IncidentQuestions'
import {
	getValue,
	getPhotos,
	checkConditionQuestion,
	IncidentErrorState,
} from './error'
import { DetailsState } from '../../../redux/incident/details/DetailsState'
import { IncidentType } from '../data/IncidentTypes'
import { UserNameSelect } from '../../../components/UserNameSelect'

const MAX_ANSWER_LENGTH = 10000

const QuestionLoader: React.FunctionComponent<{
	offline: boolean
	question: Question
	values: DetailsState
	errors: IncidentErrorState
	incidentType: string
	setStates: (
		newValue: string | Photo[],
		key: string,
		newError: string,
		errorKey: string
	) => void
}> = ({ offline, question, values, errors, incidentType, setStates }) => {
	const ref = React.createRef<HTMLDivElement>()

	const changeHandler = useCallback(
		(
			newValue: string | Photo[],
			key: string,
			newError: string,
			errorKey: string
		) => {
			setStates(newValue, key, newError, errorKey)
			if (ref.current) {
				ref.current.scrollIntoView({ behavior: 'smooth' })
			}
		},
		[ref, setStates]
	)

	const loadQuestion = useCallback(() => {
		const shouldShowQuestion = checkConditionQuestion(offline, question, values)

		if (!shouldShowQuestion) {
			return
		}

		const value = getValue(values, question.value)

		switch (question.component) {
			case QuestionType.Selector:
				return (
					<FormField icon={question.icon}>
						<div className={question.css}>
							<Selector
								name={question.name ?? ''}
								placeholder={question.placeholder ?? ''}
								options={getSelectionOptions(question, values, incidentType)}
								value={value}
								setValue={(newValue) =>
									changeHandler(newValue, question.value, '', question.error)
								}
								error={errors[question.error]}
							/>
						</div>
					</FormField>
				)
			case QuestionType.Input:
				return (
					<FormField icon={question.icon}>
						<div className={question.css}>
							<div className="text-error body-xs-thin">
								{errors[question.error]}
							</div>
							<input
								name={question.name ?? ''}
								className="w-full"
								type="text"
								autoComplete="off"
								placeholder={question.placeholder ?? ''}
								value={value}
								onChange={(e) =>
									changeHandler(
										e.target.value,
										question.value,
										'',
										question.error
									)
								}
							/>
						</div>
					</FormField>
				)
			case QuestionType.Textual:
				return (
					<TextualQuestion
						styleClass={question.css}
						prompt={
							question.prompt
								? question.prompt
								: getNatureOfIncidentText(incidentType as IncidentType)
						}
						value={value}
						setValue={(newValue) => {
							let error = ''
							if (newValue.trim().length > MAX_ANSWER_LENGTH) {
								error = `restricted to ${MAX_ANSWER_LENGTH} characters`
								newValue = newValue.substring(0, 9999)
							}
							changeHandler(newValue, question.value, error, question.error)
						}}
						error={errors[question.error]}
						size={QuestionSize.REGULAR}
						maxLength={MAX_ANSWER_LENGTH}
					/>
				)
			case QuestionType.Options:
				return (
					<OptionsButtonQuestion
						styleClass={question.css}
						questionName={question.value}
						prompt={question.prompt ?? ''}
						value={value}
						setValue={(newValue) =>
							changeHandler(newValue, question.value, '', question.error)
						}
						error={errors[question.error]}
						size={QuestionSize.REGULAR}
						options={question.availableOptions ?? []}
					/>
				)
			case QuestionType.Photos:
				return (
					<UploadPhotos
						styleClass={question.css}
						photos={getPhotos(values, question.value)}
						error={errors[question.error]}
						setValue={(newValue, newError) =>
							changeHandler(newValue, question.value, newError, question.error)
						}
						maxPhotos={5}
					/>
				)
			case QuestionType.UserSelect:
				return (
					<FormField icon={question.icon}>
						<div className={question.css}>
							<div className="text-error body-xs-thin">
								{errors[question.error]}
							</div>
							<UserNameSelect
								defaultValue={value}
								onSelectionChanged={(value?: string) =>
									changeHandler(value ?? '', question.value, '', question.error)
								}
							/>
						</div>
					</FormField>
				)
		}
	}, [offline, changeHandler, errors, incidentType, question, values])

	return (
		<div className="ml-3" ref={ref}>
			{loadQuestion()}
		</div>
	)
}

export default QuestionLoader
