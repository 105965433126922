import {
	getCountries,
	getCountryCallingCode,
	CountryCode,
	CountryCallingCode,
	AsYouType,
} from 'libphonenumber-js'
import { SelectOptions } from '../../components/Selector'
import { getCommonCountryCodes } from './getLocations'
import { getCountryName } from './getCountryName'

export interface DialingCodeData {
	countryCode: CountryCode
	dialingCode: CountryCallingCode
	countryName: string
}

export const getDialingCodes = (): DialingCodeData[] => {
	const topCountries = getCommonCountryCodes().reverse()

	return getCountries()
		.map((country) => ({
			countryCode: country,
			dialingCode: getCountryCallingCode(country),
			countryName: getCountryName(country) || 'Unknown',
		}))
		.sort((d1, d2) => {
			const index1 = topCountries.indexOf(d1.countryCode)
			const index2 = topCountries.indexOf(d2.countryCode)
			if (index1 >= 0) return index2 - index1
			if (index2 >= 0) return index2 - index1

			const code1 = Number.parseInt(d1.dialingCode as string)
			const code2 = Number.parseInt(d2.dialingCode as string)
			if (code1 !== code2) {
				return code1 - code2
			}
			return d1.countryName.localeCompare(d2.countryName)
		})
}

export const formatDialingCode = (countryCode: CountryCode | ''): string => {
	if (countryCode === '') {
		return ''
	}
	return `(+${getCountryCallingCode(countryCode)}) ${getCountryName(
		countryCode
	)}`
}

export const formatPhoneNumber: (profile: {
	countryCode: CountryCode | ''
	phoneNumber: string
}) => string = ({ countryCode, phoneNumber }) => {
	if (countryCode === '') {
		return phoneNumber
	}
	return new AsYouType(countryCode).input(phoneNumber)
}

export const getDiaingCodeOptions = (): SelectOptions =>
	getDialingCodes().map((d) => ({
		value: d.countryCode,
		label: formatDialingCode(d.countryCode),
	}))
