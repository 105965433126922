import { MetadataValues, MetadataSuggestions } from './MetadataState'
import {
	SET_ASSESSMENT_METADATA_VALUES,
	SET_ASSESSMENT_METADATA_SUGGESTIONS,
	REQUEST_ASSESSMENT_METADATA_SUGGESTIONS,
	RESET_METADATA_VALUES,
} from './actionTypes'
import Action from '../../Action'

export const requestMetadataSuggestions = (): Action => ({
	type: REQUEST_ASSESSMENT_METADATA_SUGGESTIONS,
})

export const setMetadataValues = (
	values: MetadataValues
): Action<MetadataValues> => ({
	type: SET_ASSESSMENT_METADATA_VALUES,
	payload: values,
})

export const setMetadataSuggestions = (
	suggestions: MetadataSuggestions
): Action<MetadataSuggestions> => ({
	type: SET_ASSESSMENT_METADATA_SUGGESTIONS,
	payload: suggestions,
})

export const resetMetadataValues = (): Action => ({
	type: RESET_METADATA_VALUES,
})
