import { setFetchingErrors, setFetchErrorsError, setErrors } from './actions'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { FETCH_ERRORS } from './actionTypes'
import { serviceRequestSaga } from '../services/sagas'
import { getErrors, logError } from '../../services/Error/errorService'
import { LoggedError } from '../../screens/logErrors/LogErrors'

export function* fetchErrors(): Generator<unknown, void, unknown> {
	yield put(setFetchingErrors(true))
	yield put(setFetchErrorsError(null))
	try {
		const errors = (yield call(
			serviceRequestSaga,
			getErrors
		) as unknown) as LoggedError[]

		yield put(setErrors(errors))
	} catch (e) {
		yield put(setFetchErrorsError(e.message))
	} finally {
		yield put(setFetchingErrors(false))
	}
}

export default [takeEvery(FETCH_ERRORS, fetchErrors)]
