import Action from '../Action'
import {
	CHECK_AND_RESET_OFFLINE_ASSESSMENTS_AND_INCIDENTS,
	RESET_EMAIL_ADDRESS,
	SET_EMAIL_ADDRESS,
} from './actionTypes'

export const setEmailAddress = (email: string): Action<string> => ({
	type: SET_EMAIL_ADDRESS,
	payload: email,
})

export const resetEmailAddress = (): Action => ({
	type: RESET_EMAIL_ADDRESS,
})

export const checkAndResetOfflineAssessmentAndIncidents = (
	email: string
): Action<string> => ({
	type: CHECK_AND_RESET_OFFLINE_ASSESSMENTS_AND_INCIDENTS,
	payload: email,
})
