import Action from '../../Action'
import RecentRiskAssessmentsState from './recentRiskAssessmentsState'
import {
	SET_FETCHING_RECENT_RISK_ASSESSMENTS,
	SET_RECENT_RISK_ASSESSMENTS,
	SET_FETCH_RISK_ASSESSMENTS_ERROR,
} from './actionTypes'
import RecentRiskAssessment from './RecentRiskAssessments'
const initialState: RecentRiskAssessmentsState = {
	riskAssessments: [],
	fetchError: null,
	isFetching: false,
	error: null,
	isSubmitting: false,
}

const recentRiskAssessments = (
	state = initialState,
	action: Action<unknown>
): RecentRiskAssessmentsState => {
	switch (action.type) {
		case SET_RECENT_RISK_ASSESSMENTS: {
			const riskAssessments = action.payload as RecentRiskAssessment[]

			return {
				...state,
				riskAssessments,
			}
		}
		case SET_FETCHING_RECENT_RISK_ASSESSMENTS: {
			return {
				...state,
				isFetching: action.payload as boolean,
			}
		}
		case SET_FETCH_RISK_ASSESSMENTS_ERROR: {
			return {
				...state,
				fetchError: action.payload as string | null,
			}
		}
		default: {
			return state
		}
	}
}

export default recentRiskAssessments
