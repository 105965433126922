import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import FileDownloader from '../../../components/FileDownloader'
import { NumericDate } from '../../../components/NumericDate'
import RecentRiskAssessment from '../../../redux/assessment/recentAssessments/RecentRiskAssessments'
import { EmailedStatus } from '../../../redux/emailedStatus'
import { selectIsWorkingOffline } from '../../../redux/users/selectors'
import { FailedEmailHistoryState } from '../../failedemail/FailedEmailScreen'
import { Status, StatusType } from '../Status'

const mousePointerCss = (openHandler?: () => void): string =>
	openHandler ? 'cursor-pointer' : ''

type Props = {
	assessment: RecentRiskAssessment
	status: StatusType
	onOpen?: () => void
}

export const AssessmentRow: React.FunctionComponent<Props> = ({
	assessment: { id, metadata, submissionDate },
	onOpen,
	status,
}: Props) => {
	const { forwardingEmails, failedEmails, hsRep, title } = metadata
	const emailAddresses = forwardingEmails.concat(hsRep.email)

	return (
		<div className="flex h-auto border-solid border-b border-secondary-6 pr-4 max-h-20">
			<div className="flex flex-none">
				<FileDownloader assessmentId={id} status={status} />
			</div>
			<div
				className={`flex flex-1 pt-2 pl-2 overflow-hidden h-full ${mousePointerCss(
					onOpen
				)}`}
				onClick={onOpen}
			>
				<div className="flex-1 pt-2 pr-2 line-clamp-2 break-all">{title}</div>
				<div
					className={`flex flex-col flex-none pt-2 ${mousePointerCss(onOpen)}`}
					onClick={onOpen}
				>
					<div className="body-xs-thin text-gray-5">
						{submissionDate !== undefined && (
							<NumericDate date={submissionDate} />
						)}
						{submissionDate === undefined && 'N/A'}
					</div>
					{metadata.status === EmailedStatus.FAIL && (
						<Link
							to={{
								pathname: '/failed-email',
								state: {
									title: metadata.title,
									emailAddresses: emailAddresses,
									failedEmails: failedEmails,
								} as FailedEmailHistoryState,
							}}
						>
							<div className="text-error body-base">
								Failed{' '}
								{metadata.failedEmails.length > 0
									? failedEmails.length
									: emailAddresses.length}{' '}
								Addresses
							</div>
						</Link>
					)}

					<div className="text-right -mt-1">
						{metadata.status !== EmailedStatus.FAIL && (
							<Status status={status} />
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
