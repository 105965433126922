import { call, CallEffect } from 'redux-saga/effects'
import { logError } from '../../services/Error/errorService'
import { isAuthenticationError } from '../../services/fetch/AuthenticationError'
import { logOutSaga } from '../users/sagas'

export function* serviceRequestSaga<Fn extends (...args: unknown[]) => unknown>(
	service: Fn,
	...args: Parameters<Fn>
): Generator<CallEffect> {
	try {
		const result = yield call(service, ...args)
		return result
	} catch (e) {
		if (!isAuthenticationError(e)) {
			logError({
				message: e.message,
				name: e.name,
			})
			throw e
		}

		yield call(logOutSaga)
		throw e
	}
}
