import React from 'react'
import { formatDate } from '../format/date'

interface Props {
	date: Date
}

export const NumericDate: React.FunctionComponent<Props> = ({
	date,
}: Props) => {
	return <>{formatDate(date)}</>
}
