import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormField from '../../../components/IconFormField'
import DateIcon from '../../../icons/DateIcon'
import BusinessIcon from '../../../icons/BusinessIcon'
import IncidentClassIcon from '../../../icons/IncidentClassIcon'
import IncidentCauseIcon from '../../../icons/IncidentCauseIcon'
import SafeForm from '../../../components/SafeForm'
import Selector from '../../../components/Selector'
import { v4 as uuidv4 } from 'uuid'
import { getDates, getIncidentDates } from '../data/IncidentDates'
import {
	getIncidentTypes,
	displayIncidentCause,
	IncidentType,
} from '../data/IncidentTypes'
import { getIncidentCauses } from '../data/IncidentCauses'
import {
	validateIncidentType,
	validateIncidentCause,
	validateOperatingUnit,
	validateOfficeLocation,
} from './validation'
import AlertPrompt from './AlertPrompt'
import Profile from '../../profile/Profile'
import { selectConfig } from '../../../redux/incident/config/selectors'
import { selectIncidentId } from '../../../redux/incident/selectors'
import {
	setIncidentStage,
	setIncidentId,
} from '../../../redux/incident/actions'
import { setIncidentConfig } from '../../../redux/incident/config/actions'
import { resetIncidentDetails } from '../../../redux/incident/details/actions'
import { INCIDENT_STAGE_DETAILS } from '../../../redux/incident/IncidentStage'
import {
	getHeadOfOperations,
	getLocationRepresentative,
} from '../../profile/lookupContact'
import {
	getOperatingUnitOptions,
	getWorkLocationOptions,
} from '../../profile/getOperatingUnits'
import LocationIcon from '../../../icons/LocationIcon'
import { selectOfflineEmail } from '../../../redux/offline/selectors'

const Configuration: React.FunctionComponent<{ profile: Profile | null }> = ({
	profile,
}) => {
	const dispatch = useDispatch()
	const offlineEmail = useSelector(selectOfflineEmail)
	const config = useSelector(selectConfig)
	const id = useSelector(selectIncidentId)
	const [allValues, setAllValues] = useState({
		incidentDate: getIncidentDates(config.incidentDate),
		operatingUnit: profile?.operatingUnit ?? config.operatingUnit,
		officeLocation: profile?.primaryWorkLocation ?? config.primaryWorkLocation,
		incidentType: config.incidentType,
		incidentCause: config.incidentCause,
		typeError: '',
		causeError: '',
		operatingUnitError: '',
		officeLocationError: '',
		showAlert: false,
	})

	const validateData = (): boolean => {
		const validateError = {
			typeError: validateIncidentType(allValues.incidentType),
			causeError: displayIncidentCause(allValues.incidentType)
				? validateIncidentCause(allValues.incidentCause)
				: '',
			operatingUnitError: validateOperatingUnit(allValues.operatingUnit),
			officeLocationError: validateOfficeLocation(allValues.officeLocation),
		}
		setAllValues({
			...allValues,
			typeError: validateError.typeError,
			causeError: validateError.causeError,
			operatingUnitError: validateError.operatingUnitError,
			officeLocationError: validateError.officeLocationError,
		})
		return Object.values(validateError).filter((x) => x.length > 0).length === 0
	}

	const onSubmissionHandler = () => {
		if (validateData()) {
			dispatch(setIncidentStage(INCIDENT_STAGE_DETAILS))
			if (id.length === 0) {
				dispatch(setIncidentId(uuidv4()))
			}

			let emailList: string[] = profile?.email
				? [profile.email]
				: [offlineEmail]
			if (
				(allValues.operatingUnit.length > 0 &&
					allValues.officeLocation.length > 0) ||
				!profile
			) {
				emailList.push(
					getLocationRepresentative(
						allValues.operatingUnit,
						allValues.officeLocation
					).email
				)
			} else {
				getLocationRepresentative(
					profile.operatingUnit,
					profile.primaryWorkLocation
				).email
			}

			if (allValues.incidentType !== IncidentType.Observation) {
				emailList.push(getHeadOfOperations().email)
			}

			// remove duplicate email address, if any
			emailList = [...new Set(emailList.map((x) => x.toLowerCase()))]

			dispatch(
				setIncidentConfig({
					incidentDate: allValues.incidentDate,
					operatingUnit: allValues.operatingUnit,
					primaryWorkLocation: allValues.officeLocation,
					incidentType: allValues.incidentType,
					incidentCause: allValues.incidentCause,
					forwardingEmails: emailList,
				})
			)
			if (config.incidentType !== allValues.incidentType) {
				dispatch(resetIncidentDetails())
			}
		}
	}

	return (
		<SafeForm onSubmit={() => onSubmissionHandler()}>
			{allValues.showAlert ? (
				<AlertPrompt
					setAlert={(status: boolean) =>
						setAllValues({
							...allValues,
							incidentType: status ? allValues.incidentType : '',
							showAlert: false,
						})
					}
				/>
			) : (
				false
			)}
			<div className="flex flex-col w-full min-h-64 pl-3">
				<FormField icon={DateIcon}>
					<div className="pl-5 mt-0">
						<Selector
							name="incidentDate"
							placeholder="Incident date"
							options={getDates()}
							value={allValues.incidentDate}
							setValue={(newDate) =>
								setAllValues({
									...allValues,
									incidentDate: newDate,
								})
							}
						/>
					</div>
				</FormField>
				{profile?.operatingUnit !== undefined && (
					<>
						<FormField icon={BusinessIcon}>
							<p className="text-secondary-6 pl-5">{allValues.operatingUnit}</p>
						</FormField>
						<FormField icon={LocationIcon}>
							<p className="text-secondary-6 pl-5">
								{allValues.officeLocation}
							</p>
						</FormField>
					</>
				)}
				{profile?.operatingUnit === undefined && (
					<>
						<FormField icon={BusinessIcon}>
							<div className="pl-5 mt-0">
								<Selector
									name="operatingUnit"
									placeholder="Operating Unit"
									options={getOperatingUnitOptions()}
									value={allValues.operatingUnit}
									setValue={(operatingUnit) => {
										if (operatingUnit !== allValues.operatingUnit) {
											setAllValues({
												...allValues,
												operatingUnit,
												operatingUnitError: '',
												officeLocation: '',
											})
										} else {
											setAllValues({
												...allValues,
												operatingUnit,
												operatingUnitError: '',
											})
										}
									}}
									error={allValues.operatingUnitError}
								/>
							</div>
						</FormField>
						{allValues.operatingUnit.length > 0 && (
							<FormField icon={LocationIcon}>
								<div className="pl-5 mt-0">
									<Selector
										name="officeLocation"
										placeholder="Office Location"
										options={getWorkLocationOptions(allValues.operatingUnit)}
										value={allValues.officeLocation}
										setValue={(officeLocation) =>
											setAllValues({
												...allValues,
												officeLocation,
												officeLocationError: '',
											})
										}
										error={allValues.officeLocationError}
									/>
								</div>
							</FormField>
						)}
					</>
				)}
				<FormField icon={IncidentClassIcon}>
					<div className="pl-5 mt-0">
						<Selector
							name="incidentClass"
							placeholder="Incident class"
							options={getIncidentTypes()}
							value={allValues.incidentType}
							setValue={(newType) => {
								setAllValues({
									...allValues,
									incidentType: newType as IncidentType,
									typeError: '',
									incidentCause: '',
									causeError: '',
									showAlert: newType === IncidentType.MajorInjury,
								})
							}}
							error={allValues.typeError}
						/>
					</div>
				</FormField>
				{displayIncidentCause(allValues.incidentType) ? (
					<FormField icon={IncidentCauseIcon}>
						<div className="pl-5">
							<Selector
								name="incidentCause"
								placeholder="Incident cause"
								options={getIncidentCauses(allValues.incidentType)}
								value={allValues.incidentCause}
								setValue={(newCause) =>
									setAllValues({
										...allValues,
										incidentCause: newCause,
										causeError: '',
									})
								}
								error={allValues.causeError}
							/>
						</div>
					</FormField>
				) : (
					false
				)}
			</div>
			<div className="flex flex-col items-center mt-20">
				<button className="btn-primary mb-10">Next</button>
			</div>
		</SafeForm>
	)
}

export default Configuration
