import { takeEvery, put, select, call, take, all } from 'redux-saga/effects'
import { submitAssessment } from '../../../services/assessment/assessmentService'
import { logError } from '../../../services/Error/errorService'
import { getDate } from '../../../testing/date'
import RiskAssessmentSubmission from '../../assessment/flow/submission/RiskAssessmentSubmission'
import { requestRecentRiskAssessments } from '../../assessment/recentAssessments/actions'
import { serviceRequestSaga } from '../../services/sagas'
import {
	selectHasConnectivity,
	selectIsLoggedIn,
	selectIsTokenValid,
} from '../../users/selectors'
import {
	deleteRiskAssessmentSubmittedOffline,
	offlineRiskAssessmentSubmissionFailed,
} from './actions'
import { TRIGGER_ASSESSMENT_AUTO_SUBMISSION } from './actionTypes'
import RiskAssessmentOfflineSubmission from './RiskAssessmentOffline'
import { selectOfflineSubmittedAssessments } from './selectors'

export function* autoAssessmentSubmission(): Generator<unknown, void, never> {
	const hasConnectivity = (yield select(selectHasConnectivity)) as boolean

	if (!hasConnectivity) {
		return
	}

	const isLoggedIn = (yield select(selectIsLoggedIn)) as boolean

	if (!isLoggedIn) {
		return
	}

	const isTokenValid = (yield select(selectIsTokenValid)) as boolean

	if (!isTokenValid) {
		return
	}

	const offlineSubmittedAssessments = (yield select(
		selectOfflineSubmittedAssessments
	)) as RiskAssessmentOfflineSubmission[]

	const now = getDate()
	const twoMinutes = 2 * 60 * 1000
	const twoMinutesAgo = new Date(now.getTime() - twoMinutes)

	const isSubmittable = (submission: RiskAssessmentOfflineSubmission) => {
		if (submission.lastSubmissionAttempted === undefined) {
			return true
		}

		return submission.lastSubmissionAttempted < twoMinutesAgo
	}

	const submittables = offlineSubmittedAssessments.filter(isSubmittable)

	for (const assessment of submittables) {
		const submission: RiskAssessmentSubmission = {
			...assessment.submissionDetails,
		}

		const id = assessment.submissionDetails.metadata.localId

		try {
			yield call(serviceRequestSaga as never, submitAssessment, submission)
			yield put(deleteRiskAssessmentSubmittedOffline(id))
		} catch (err) {
			yield put(offlineRiskAssessmentSubmissionFailed(id))

			yield call(logError, {
				message: err.message,
				name: err.name,
			})
		}
	}

	if (submittables.length > 0) {
		yield put(requestRecentRiskAssessments())
	}
}

export default [
	takeEvery(TRIGGER_ASSESSMENT_AUTO_SUBMISSION, autoAssessmentSubmission),
]
