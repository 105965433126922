import { authenticatedFetch } from '../fetch/fetchService'
import getAPIUrl from '../getAPIUrl'

export type UserLookupResult = {
	id: string
	displayName: string
	mail: string
}

export const lookupUsers = async (
	displayName: string
): Promise<UserLookupResult[]> => {
	const response = await authenticatedFetch(
		`${getAPIUrl('lookupUsers')}?name=${displayName}`,
		{
			method: 'GET',
		}
	)

	const result = await response.json()

	if (!response.ok) {
		throw new Error(result.message)
	}

	return result as UserLookupResult[]
}
