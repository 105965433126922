import {
	Documentation,
	QuestionBlock,
	AnswerType,
	CAN_I_CONTROL_THE_HAZARD,
} from './schema'

const DOCUMENT_DEVIATIONS: Documentation = {
	prompt: 'Document the deviations',
}

const PreVisitAssessmentCompletedFlow: QuestionBlock = {
	id: 'PRE_VISIT_ASSESSMENT_COMPLETED',
	questions: [
		{
			prompt:
				'Have the visit requirements changed from the previsit risk assessment?',
			questionId: 'reqirements-changed',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DEVIATIONS,
			essential: false,
		},
		{
			prompt: 'Are there any additional hazards present?',
			questionId: 'additional-hazards',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DEVIATIONS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt:
				'Are there any other activities taking place in the work area that introduced increased risk?',
			questionId: 'risky-activities',
			favorableAnswer: AnswerType.NO,
			documentation: DOCUMENT_DEVIATIONS,
			followUpQuestion: CAN_I_CONTROL_THE_HAZARD,
		},
		{
			prompt:
				'Is all of my equipment and Personnel Protective Equipment (PPE) appropriate for the work?',
			questionId: 'ppe-fit-for-purpose',
			favorableAnswer: AnswerType.YES,
			followUpQuestion: {
				prompt: 'Can I obtain the correct PPE?',
				questionId: 'obtain-ppe',
				favorableAnswer: AnswerType.YES,
			},
		},
		{
			prompt:
				'Am I aware of local HSE procedures which includes local arrangements in case of an emergency?',
			questionId: 'aware-of-hse-procedures',
			favorableAnswer: AnswerType.YES,
			documentation: {
				prompt: 'Request local information',
			},
		},
		{
			prompt: 'Is everyone fit and healthy?',
			questionId: 'everyone-fit-and-healthy',
			favorableAnswer: AnswerType.YES,
		},
		{
			prompt:
				'Am I adequately trained, experienced and confident to undertake this assigned operation whilst wearing the required PPE?',
			questionId: 'adequately-trained',
			favorableAnswer: AnswerType.YES,
		},
	],
}

export default PreVisitAssessmentCompletedFlow
