import React, { useState, useEffect } from 'react'
import PageBanner, { PageBannerProps } from '../../components/PageBanner'
import withAsyncAction from '../../components/withAsyncAction'
import { RequestErrors } from '../../redux/error/actions'
import { withLoading } from '../../components/FullscreenLoading'
import { withError } from '../../components/FullscreenError'
import { getGreeting } from '../home/date'
import { formatDate } from '../../format/date'

export interface LoggedError {
	id: string
	errorMessage: string
	userId?: string
	dateCreated: string
	isUi: boolean
}

const LogErrors: React.FunctionComponent<{
	data: LoggedError[]
}> = ({ data: errors }) => {
	return (
		<div>
			<PageBanner title="Error Log" colorClass="error" />
			<div>
				<table className="table w-full border border-primary">
					<thead className="border border-primary">
						<tr>
							<th className="d-inline p-2 border border-primary" scope="col">
								Id
							</th>
							<th className="d-inline p-2 border border-primary" scope="col">
								UI/API?
							</th>
							<th className="d-inline p-2 border border-primary" scope="col">
								Error Message
							</th>
							<th className="d-inline p-2 border border-primary" scope="col">
								User Id
							</th>
							<th className="d-inline p-2 border border-primary" scope="col">
								Date Created
							</th>
						</tr>
					</thead>

					<tbody>
						{errors.map((error) => (
							<tr key={error.id} className="border border-primary">
								<td className="border border-primary">{error.id}</td>
								<td className="border border-primary">
									{error.isUi ? 'UI' : 'API'}
								</td>
								<td className="border border-primary">{error.errorMessage}</td>
								<td className="border border-primary">{error.userId}</td>
								<td className="border border-primary">{error.dateCreated}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

const bannerProps: PageBannerProps = {
	title: getGreeting(new Date()),
	subtitle: formatDate(new Date()),
	colorClass: 'secondary-2',
	subtitleColorClass: 'primary-2',
}

export default withAsyncAction<LoggedError[]>({
	fetchAction: () => RequestErrors(),
	loadingComponent: withLoading(bannerProps),
	errorComponent: withError({
		bannerProps,
		formatError: (error) =>
			`Failed to load error Page - ${error.toLowerCase()}`,
	}),
	dataComponent: LogErrors,
	selectState: (state) => ({
		errorMessage: state.userProfile.fetchError,
		inProgress: state.userProfile.isFetching,
		data: state.error.errors,
	}),
})
