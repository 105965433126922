import { LoggedError } from '../../screens/logErrors/LogErrors'
import { authenticatedFetch } from '../fetch/fetchService'
import getAPIUrl from '../getAPIUrl'

export const logError = async (error: Error): Promise<void> => {
	const response = await authenticatedFetch(getAPIUrl('logError'), {
		method: 'POST',
		body: JSON.stringify(error),
	})

	if (!response.ok) {
		throw new Error((await response.json()).message)
	}
}

export const getErrors = async (): Promise<LoggedError[]> => {
	const response = await authenticatedFetch(getAPIUrl('errors'), {
		method: 'GET',
	})

	if (!response.ok) {
		throw new Error((await response.json()).message)
	}

	const result = await response.json()
	return result as LoggedError[]
}
