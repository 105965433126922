import {
	SAVE_DETAILS,
	SAVE_DETAILS_ERROR,
	FETCH_USER_PROFILE,
	SET_USER_PROFILE,
	SET_SUBMITTING,
	SET_FETCHING_PROFILE,
	SET_FETCH_ERROR,
	SET_USER_PROFILE_EMAIL,
} from './actionTypes'
import Action from '../Action'
import SaveDetailsErrorPayload from './SaveDetailsErrorPayload'
import Profile from '../../screens/profile/Profile'
import SubmittingPayload from './SubmittingPayload'

export const saveDetails = (profile: Profile): Action<Profile> => ({
	type: SAVE_DETAILS,
	payload: profile,
})

export const saveDetailsError = (
	message: string | null
): Action<SaveDetailsErrorPayload> => ({
	type: SAVE_DETAILS_ERROR,
	payload: { message },
})

export const fetchUserProfile = (): Action => ({
	type: FETCH_USER_PROFILE,
})

export const setFetchingUserProfile = (fetching: boolean): Action<boolean> => ({
	type: SET_FETCHING_PROFILE,
	payload: fetching,
})

export const setFetchUserProfileError = (
	error: string | null
): Action<string | null> => ({
	type: SET_FETCH_ERROR,
	payload: error,
})

export const setUserProfile = (
	profile: Profile | null
): Action<Profile | null> => ({
	type: SET_USER_PROFILE,
	payload: profile,
})

export const setUserProfileEmail = (email: string): Action<string> => ({
	type: SET_USER_PROFILE_EMAIL,
	payload: email,
})

export const setSubmtiting = (
	submitting: boolean
): Action<SubmittingPayload> => ({
	type: SET_SUBMITTING,
	payload: {
		submitting,
	},
})
