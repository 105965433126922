import React from 'react'
import { IconComponent, wrapIcon, calculateStrokeWidth } from './IconComponent'

const BusinessIcon: IconComponent = wrapIcon((props) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<line
			x1="176"
			y1="416"
			x2="176"
			y2="480"
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<path
			d="M80,32H272a32,32,0,0,1,32,32V476a4,4,0,0,1-4,4H48a0,0,0,0,1,0,0V64A32,32,0,0,1,80,32Z"
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<path
			d="M320,192H432a32,32,0,0,1,32,32V480a0,0,0,0,1,0,0H304a0,0,0,0,1,0,0V208A16,16,0,0,1,320,192Z"
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<path
			fill={props.stroke}
			d="M98.08,431.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,98.08,431.87Z"
		/>
		<path
			fill={props.stroke}
			d="M98.08,351.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,98.08,351.87Z"
		/>
		<path
			fill={props.stroke}
			d="M98.08,271.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,98.08,271.87Z"
		/>
		<path
			fill={props.stroke}
			d="M98.08,191.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,98.08,191.87Z"
		/>
		<path
			fill={props.stroke}
			d="M98.08,111.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,98.08,111.87Z"
		/>
		<path
			fill={props.stroke}
			d="M178.08,351.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,178.08,351.87Z"
		/>
		<path
			fill={props.stroke}
			d="M178.08,271.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,178.08,271.87Z"
		/>
		<path
			fill={props.stroke}
			d="M178.08,191.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,178.08,191.87Z"
		/>
		<path
			fill={props.stroke}
			d="M178.08,111.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,178.08,111.87Z"
		/>
		<path
			fill={props.stroke}
			d="M258.08,431.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,258.08,431.87Z"
		/>
		<path
			fill={props.stroke}
			d="M258.08,351.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,258.08,351.87Z"
		/>
		<path
			fill={props.stroke}
			d="M258.08,271.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,258.08,271.87Z"
		/>
		<ellipse
			cx="256"
			cy="176"
			rx="15.95"
			ry="16.03"
			transform="translate(-49.47 232.56) rotate(-45)"
		/>
		<path
			fill={props.stroke}
			d="M258.08,111.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,258.08,111.87Z"
		/>
		<path
			fill={props.stroke}
			d="M400,400a16,16,0,1,0,16,16,16,16,0,0,0-16-16Z"
		/>
		<path
			fill={props.stroke}
			d="M400,320a16,16,0,1,0,16,16,16,16,0,0,0-16-16Z"
		/>
		<path
			fill={props.stroke}
			d="M400,240a16,16,0,1,0,16,16,16,16,0,0,0-16-16Z"
		/>
		<path
			fill={props.stroke}
			d="M336,400a16,16,0,1,0,16,16,16,16,0,0,0-16-16Z"
		/>
		<path
			fill={props.stroke}
			d="M336,320a16,16,0,1,0,16,16,16,16,0,0,0-16-16Z"
		/>
		<path
			fill={props.stroke}
			d="M336,240a16,16,0,1,0,16,16,16,16,0,0,0-16-16Z"
		/>
	</svg>
))

export default BusinessIcon
