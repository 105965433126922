import { LoggedError } from '../../screens/logErrors/LogErrors'
import Action from '../Action'
import { SET_FETCHING_ERRORS, SET_ERRORS, SET_FETCH_ERROR } from './actionTypes'
import ErrorState from './ErrorState'
const initialState: ErrorState = {
	errors: [],
	fetchError: null,
	isFetching: false,
}

const errors = (state = initialState, action: Action<unknown>): ErrorState => {
	switch (action.type) {
		case SET_ERRORS: {
			const errors = action.payload as LoggedError[]

			return {
				...state,
				errors,
			}
		}
		case SET_FETCHING_ERRORS: {
			return {
				...state,
				isFetching: action.payload as boolean,
			}
		}
		case SET_FETCH_ERROR: {
			return {
				...state,
				fetchError: action.payload as string | null,
			}
		}
		default: {
			return state
		}
	}
}

export default errors
