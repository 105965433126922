export type UserLocation = {
	latitude: number
	longitude: number
	result: UserLocationResult
	error: string
}

export enum UserLocationResult {
	None = 'none',
	Success = 'success',
	PermissionDenied = 'permission-denied',
	Error = 'error',
	NotSupported = 'not-supported',
}

const translateErrorCode = (errorCode: number): UserLocationResult => {
	switch (errorCode) {
		case 1:
			return UserLocationResult.PermissionDenied
		case 2:
		case 3:
		default:
			return UserLocationResult.Error
	}
}

export const getLocation = async (): Promise<UserLocation> => {
	if (navigator.geolocation === undefined) {
		return {
			latitude: 0,
			longitude: 0,
			result: UserLocationResult.NotSupported,
			error: '',
		}
	}

	const result = await new Promise<UserLocation>((resolve) => {
		navigator.geolocation.getCurrentPosition(
			({ coords }) => {
				resolve({
					latitude: coords.latitude,
					longitude: coords.longitude,
					result: UserLocationResult.Success,
					error: '',
				})
			},
			(error) => {
				resolve({
					latitude: 0,
					longitude: 0,
					result: translateErrorCode(error.code),
					error: error.message,
				})
			},
			{ timeout: 20000 }
		)
	})

	return result
}
