import Action from '../../Action'
import RiskAssessmentState from './pdfRiskAssessmentState'
import {
	SET_FETCHING_RISK_ASSESSMENT_PDF,
	SET_RISK_ASSESSMENT_PDF,
	SET_FETCH_PDF_ERROR,
} from './actionTypes'
import RiskAssessmentPdf from './RiskAssessmentPdf'

const initialState: RiskAssessmentState = {
	assessmentPdf: { filename: '', pdfContent: '' },
	fetchError: '',
	isFetching: false,
}

const riskAssessmentPdf = (
	state = initialState,
	action: Action<unknown>
): RiskAssessmentState => {
	switch (action.type) {
		case SET_RISK_ASSESSMENT_PDF: {
			const assessmentPdf = action.payload as RiskAssessmentPdf

			return {
				...state,
				assessmentPdf,
			}
		}
		case SET_FETCHING_RISK_ASSESSMENT_PDF: {
			return {
				...state,
				isFetching: action.payload as boolean,
			}
		}
		case SET_FETCH_PDF_ERROR: {
			return {
				...state,
				fetchError: action.payload as string | undefined,
				assessmentPdf: initialState.assessmentPdf,
			}
		}
		default: {
			return state
		}
	}
}

export default riskAssessmentPdf
