import React, { useState } from 'react'
import Dialog from '../../../components/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { selectSubmission } from '../../../redux/incident/submission/selectors'
import { submitIncidentReport } from '../../../redux/incident/submission/actions'

const SubmissionPrompt: React.FunctionComponent<{
	setSubmission: (confirm: boolean) => void
}> = ({ setSubmission }) => {
	const submission = useSelector(selectSubmission)
	const [disabled, setDisabled] = useState(false)
	const dispatch = useDispatch()

	return (
		<Dialog title="Submit" bannerColorClass="secondary-2">
			<p className="text-center text-primary-1 mt-4">
				This is an accurate representation of the incident being reported. You
				will be unable to edit this once submitted.
			</p>
			<div className="body-sm text-error text-center">{submission.error}</div>
			<div className="flex justify-center mt-4 mb-4">
				<button
					className="btn-primary-popup mr-3 disabled:opacity-50"
					disabled={disabled}
					onClick={() => {
						setDisabled(true)
						dispatch(submitIncidentReport())
					}}
				>
					Confirm
				</button>
				<button
					className="btn-secondary-popup"
					onClick={() => setSubmission(false)}
				>
					Cancel
				</button>
			</div>
		</Dialog>
	)
}

export default SubmissionPrompt
