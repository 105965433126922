/*
	Format a date in a consistent format for ALL users: DD_ MMM YYYY. e.g., 1st Jan 1969
	This was requested by Michelle to avoid e.g. US dates being passed to the UK and causing confusion.
	The downside is that some locale-specific formatting is skipped.
*/
const dayOptions: Intl.DateTimeFormatOptions = {
	day: 'numeric',
	timeZone: 'UTC',
}

const monthYearOptions: Intl.DateTimeFormatOptions = {
	month: 'short',
	year: 'numeric',
	timeZone: 'UTC',
}

// This is not en-GB because Jest usually only has en-US. US/UK locales have slightly different month names which breaks tests.
const locale = 'en-US'

const getDateOrdinal = (date: Date): string => {
	const day = date.getDate()

	if (day > 10 && day < 20) {
		return 'th'
	}

	const digit = day % 10

	switch (digit) {
		case 1:
			return 'st'
		case 2:
			return 'nd'
		case 3:
			return 'rd'
		default:
			return 'th'
	}
}

export const formatDate = (date: Date): string => {
	const dayString = date.toLocaleString(locale, dayOptions)
	const monthYearString = date.toLocaleString(locale, monthYearOptions)

	return `${dayString}${getDateOrdinal(date)} ${monthYearString}`
}
