import RootState from '../../RootState'
import Selector from '../../Selector'
import IncidentReportOfflineSubmission from './IncidentReportOffline'
import { SavedIncidentReport } from './IncidentReportsOfflineState'

export const selectOfflineSubmittedIncidents: Selector<
	IncidentReportOfflineSubmission[]
> = (state: RootState) => state.offline.incidentReports.submittedIncidents

export const selectSavedIncidents: Selector<SavedIncidentReport[]> = (
	state: RootState
) => state.offline.incidentReports.savedIncidents
