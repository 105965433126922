import React from 'react'
import { QuestionSize } from './YesNoQuestion'
import { MAX_ANSWER_LENGTH } from '../../../redux/assessment/flow/submission/validation'

const TextualQuestion: React.FunctionComponent<{
	prompt: string
	value: string
	setValue: (newValue: string) => void
	size: QuestionSize
	error: string | null
	maxLength?: number
}> = ({
	prompt,
	value,
	setValue,
	size,
	error,
	maxLength = MAX_ANSWER_LENGTH,
}) => {
	const textClass = size === QuestionSize.SMALL ? 'body-sm' : ''

	return (
		<>
			<div className={`text-error ${textClass}`}>{error}</div>
			<h4 className={textClass}>{prompt}</h4>
			<textarea
				className={`w-full border ${
					error !== null ? 'border-error' : 'border-secondary-10'
				} body-sm p-1 text-secondary-10`}
				value={value}
				rows={4}
				onChange={(e) => setValue(e.target.value)}
				maxLength={maxLength}
			/>
		</>
	)
}

export default TextualQuestion
