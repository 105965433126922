import { HSRep } from '../../redux/assessment/metadata/MetadataState'
import { getEnvironmentVariables } from '../../services/authentication/getEnvironmentVariables'
import { getRawLocations, RawLocations } from './getRawLocations'

export interface Locations {
	operatingUnits: Record<string, OperatingUnitRepresentatives>
	headOfOperations: HSRep
}

export type OperatingUnitRepresentatives = {
	managingDirector: HSRep
	representatives: Record<string, HSRep>
}

export const TEST_OPERATING_UNIT_NAME = 'BMT Test OU'
export const TEST_OPERATING_UNIT_MANAGING_DIRECTOR_NAME = 'BMT Test OU MD'
export const TEST_OPERATING_UNIT_REPRESENTATIVE_NAME = 'BMT Test OU Rep'
export const TEST_OPERATING_UNIT_LOCATION = 'BMT Test Team'
export const TEST_OPERATING_UNIT_SUB_LOCATION = 'BMT Testers, Bath, UK'

export const getLocations = (): Locations => {
	const {
		headOfOperations,
		operatingUnits: condensedOperatingUnits,
		contacts,
	} = getRawLocations() as RawLocations

	const {
		enableTestMode,
		testOperatingUnit: {
			managingDirectorEmail: testManagingDirectorEmail,
			representativeEmail: testRepresentativeEmail,
		},
	} = getEnvironmentVariables()

	const operatingUnits: Record<string, OperatingUnitRepresentatives> = {}
	const allContacts = Object.keys(contacts).map((name) => {
		const { email, phoneNumber, responsibleFor } = contacts[name]

		return {
			name,
			email,
			phoneNumber,
			responsibleFor: Object.keys(responsibleFor).map((operatingUnitName) => ({
				operatingUnitName,
				locations: responsibleFor[operatingUnitName],
			})),
		}
	})

	for (const name in condensedOperatingUnits) {
		const { managingDirector } = condensedOperatingUnits[name]

		const unit: OperatingUnitRepresentatives = {
			managingDirector,
			representatives: {},
		}

		operatingUnits[name] = unit
	}

	for (const { name, email, phoneNumber, responsibleFor } of allContacts) {
		for (const unitContactIsResponsibleFor of responsibleFor) {
			const unit = operatingUnits[unitContactIsResponsibleFor.operatingUnitName]

			for (const location of unitContactIsResponsibleFor.locations) {
				if (unit.representatives[location] !== undefined) {
					throw new Error(
						`Operating unit ${unitContactIsResponsibleFor.operatingUnitName} has two representatives defined for location ${location}!`
					)
				}

				unit.representatives[location] = {
					name,
					email,
					phoneNumber,
				}
			}
		}
	}

	if (enableTestMode) {
		const testerOperatingUnit: OperatingUnitRepresentatives = {
			managingDirector: {
				name: TEST_OPERATING_UNIT_MANAGING_DIRECTOR_NAME,
				email: testManagingDirectorEmail,
				phoneNumber: '0123456789',
			},
			representatives: {
				[TEST_OPERATING_UNIT_LOCATION]: {
					name: TEST_OPERATING_UNIT_REPRESENTATIVE_NAME,
					email: testRepresentativeEmail,
					phoneNumber: '98765 43210',
				},
			},
		}

		operatingUnits[TEST_OPERATING_UNIT_NAME] = testerOperatingUnit
	}

	return {
		headOfOperations,
		operatingUnits,
	}
}

export const getCommonCountryCodes = (): string[] => [
	'GB',
	'US',
	'CA',
	'AU',
	'NL',
	'BE',
	'IN',
	'ID',
]
