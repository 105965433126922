import React from 'react'
import { IconComponent, wrapIcon, calculateStrokeWidth } from './IconComponent'

const IncidentClassIcon: IconComponent = wrapIcon(({ ...props }) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<rect
			x="-24.43"
			y="167.88"
			width="560.87"
			height="176.25"
			rx="88.12"
			ry="88.12"
			transform="rotate(-45 256 256.002)"
			fill={props.fill}
			stroke={props.stroke}
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<rect
			x="169.41"
			y="156.59"
			width="176"
			height="196"
			rx="32"
			ry="32"
			transform="rotate(45 257.409 254.582)"
			fill={props.fill}
			stroke={props.stroke}
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<circle
			cx="256"
			cy="208"
			r="16"
			fill={props.stroke}
			stroke={props.stroke}
		/>
		<circle
			cx="304"
			cy="256"
			r="16"
			fill={props.stroke}
			stroke={props.stroke}
		/>
		<circle
			cx="208"
			cy="256"
			r="16"
			fill={props.stroke}
			stroke={props.stroke}
		/>
		<circle
			cx="256"
			cy="304"
			r="16"
			fill={props.stroke}
			stroke={props.stroke}
		/>
	</svg>
))

export default IncidentClassIcon
