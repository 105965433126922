import { HSRep } from '../../redux/assessment/metadata/MetadataState'
import locations from './locations.json'

type OperatingUnit = {
	managingDirector: HSRep
	officeDirectors?: Record<string, HSRep>
}

type ContactResponsibilities = Omit<HSRep, 'name'> & {
	responsibleFor: Record<string, string[]>
}

export type RawLocations = {
	headOfOperations: HSRep
	operatingUnits: Record<string, OperatingUnit>
	contacts: Record<string, ContactResponsibilities>
}

export const getRawLocations = (): RawLocations => locations
