import React from 'react'
import { Redirect, RedirectProps } from 'react-router-dom'

const withRedirectRoute = <T extends unknown>(
	redirectProps: RedirectProps,
	testShouldRedirect: () => boolean,
	component: React.FunctionComponent<T>
): React.FunctionComponent<T> => {
	const Component = component
	const RedirectRoute: React.FunctionComponent<T> = (props) => {
		if (testShouldRedirect()) {
			return <Component {...props} />
		}
		return <Redirect {...redirectProps} />
	}
	return RedirectRoute
}

export default withRedirectRoute
