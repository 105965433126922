import React from 'react'
import BMTLogoDark from './BMTLogoDark'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectIsWorkingOffline } from '../redux/users/selectors'

const StyledPartialIndent = styled.div`
	padding-left: calc(env(safe-area-inset-left) * 0.5);
`

export interface PageBannerProps {
	colorClass: string
	title: string
	subtitle?: string
	subtitleClass?: string
	subtitleColorClass?: string
}

const PageBanner: React.FunctionComponent<PageBannerProps> = ({
	colorClass = 'secondary-4',
	title,
	subtitle,
	subtitleColorClass = 'primary-2',
}) => {
	const isOffline = useSelector(selectIsWorkingOffline)

	return (
		<div>
			<div className="w-screen">
				<div className="w-full h-safe-t bg-primary-1" />
				<div className="w-full h-20 bg-primary-1 flex">
					<div className="block pl-4 pt-2 flex-1">
						<StyledPartialIndent>
							<BMTLogoDark className="w-20" />
						</StyledPartialIndent>
					</div>
					<div className="flex-initial">
						<h2 className="text-right body-lg text-background mr-4 pr-safe flex-initial mt-6">
							{title}
						</h2>
						<h3
							className={`text-right text body-xs-thin text-${subtitleColorClass} mr-4 pr-safe flex-initial`}
						>
							{subtitle}
							{isOffline && (
								<span className="text-center text-lg ml-4">
									Working offline
								</span>
							)}
						</h3>
					</div>
					)
				</div>
				<div className={`w-full h-4 bg-${colorClass}`} />
			</div>
		</div>
	)
}

export default PageBanner
