import { replace } from 'connected-react-router'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { EmailedStatus } from '../../../redux/emailedStatus'
import {
	setIncidentId,
	setIncidentStage,
} from '../../../redux/incident/actions'
import { setIncidentConfig } from '../../../redux/incident/config/actions'
import { setIncidentDetails } from '../../../redux/incident/details/actions'
import { SavedIncidentReport } from '../../../redux/offline/incident/IncidentReportsOfflineState'
import { StatusType } from '../Status'
import IncidentRow from './IncidentRow'

interface Props {
	savedIncident: SavedIncidentReport
}

export const SavedIncidentReportRow: React.FunctionComponent<Props> = ({
	savedIncident: { stage, id, config, details },
}: Props) => {
	const dispatch = useDispatch()

	const onOpen = useCallback(() => {
		dispatch(setIncidentStage(stage))
		dispatch(setIncidentId(id))
		dispatch(setIncidentConfig(config))
		dispatch(setIncidentDetails(details))
		dispatch(replace('/incident'))
	}, [dispatch, stage, id, config, details])

	return (
		<IncidentRow
			incident={{
				id: id as string,
				config,
				details,
				emailedStatus: EmailedStatus.NONE,
				failedEmails: [] as string[],
			}}
			status={StatusType.Saved}
			onOpen={onOpen}
		/>
	)
}
