import React from 'react'
import PageBanner from '../../components/PageBanner'
import { Link, useHistory } from 'react-router-dom'
import FailedEmailList from './FailedEmailList'

export type FailedEmailHistoryState = {
	title: string
	emailAddresses: string[]
	failedEmails: string[]
}

const FailedEmailScreen: React.FunctionComponent = () => {
	const history = useHistory<FailedEmailHistoryState>()
	const { failedEmails, emailAddresses, title } = history.location.state
	const addresses = failedEmails.length > 0 ? failedEmails : emailAddresses

	return (
		<div>
			<PageBanner
				title="Failed Email"
				colorClass="secondary-7"
				subtitleColorClass="primary-2"
			/>
			<div className="h-full overflow-auto items-center">
				<div className="text-secondary-10 body-medium border-b border-solid border-primary-2">
					<span className="m-4">{title}</span>
				</div>
			</div>
			{addresses.map((address) => (
				<FailedEmailList address={address} key={address} />
			))}
			<div className="flex flex-col items-center mt-4">
				<Link to="/">
					<button className="btn-secondary ">Back</button>
				</Link>
			</div>
		</div>
	)
}

export default FailedEmailScreen
