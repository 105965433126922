export const validateLocation = (location: number): string => {
	if (location === 0) {
		return 'Please select an incident location'
	}
	return ''
}

export const validateSubLocation = (subLocation: number): string => {
	if (subLocation === 0) {
		return 'Please select an incident sub location'
	}
	return ''
}

export const validatePersonInvolved = (personInvolved: number): string => {
	if (personInvolved === 0) {
		return 'Please select a person involved'
	}
	return ''
}

export const validateNameOfPerson = (personName: number): string => {
	if (personName === 0) {
		return 'Name of person cannot be empty'
	}
	return ''
}

export const validateInjuryType = (injuryType: number): string => {
	if (injuryType === 0) {
		return 'Please select a type of injury'
	}
	return ''
}

export const validateNatureOfIncident = (natureOfIncident: number): string => {
	if (natureOfIncident < 30) {
		return 'This field should have 30 characters'
	}
	return ''
}

export const validateNatureOfIllness = (natureOfIllness: number): string => {
	if (natureOfIllness === 0) {
		return 'Please select nature of illness'
	}
	return ''
}

export const validateAnswer = (answer: number): string => {
	if (answer === 0) {
		return 'This field cannot be empty'
	}
	return ''
}

export const validatePhotos = (photos: number): string => {
	if (photos === 0) {
		return 'Please upload photos'
	}
	return ''
}
