export const getGreeting = (today: Date): string => {
	const hour = today.getHours()

	if (hour < 12) {
		return 'Good Morning'
	} else if (hour < 18) {
		return 'Good Afternoon'
	} else {
		return 'Good Evening'
	}
}
