export const SET_RISK_ASSESSMENT_QUESTIONS = 'SET_RISK_ASSESSMENT_QUESTIONS'
export const SET_RISK_ASSESSMENT_QUESTION_VALUE =
	'SET_RISK_ASSESSMENT_QUESTION_VALUE'
export const SET_RISK_ASSESSMENT_DOCUMENTATION =
	'SET_RISK_ASSESSMENT_DOCUMENTATION'

export const SET_RISK_ASSESSMENT_QUESTION_ERROR =
	'SET_RISK_ASSESSMENT_QUESTION_ERROR'
export const SET_RISK_ASSESSMENT_DOCUMENTATION_ERROR =
	'SET_RISK_ASSESSMENT_DOCUMENTATION_ERROR'

export const SET_RISK_ASSESSMENT_ADDITIONAL_INFORMATION_VALUE =
	'SET_RISK_ASSESSMENT_ADDITIONAL_INFORMATION_VALUE'

export const SET_RISK_ASSESSMENT_ADDITIONAL_INFORMATION_ERROR =
	'SET_RISK_ASSESSMENT_ADDITIONAL_INFORMATION_ERROR'

export const SUBMIT_RISK_ASSESSMENT = 'SUBMIT_RISK_ASSESSMENT'
export const SET_RISK_ASSESSMENT_SUBMISSION_ERROR =
	'SET_RISK_ASSESSMENT_SUBMISSION_ERROR'
export const SET_RISK_ASSESSMENT_SUBMITTING = 'SET_RISK_ASSESSMENT_SUBMITTING'

export const VALIDATE_RISK_ASSESSMENT_FORM = 'VALIDATE_RISK_ASSESSMENT_FORM'
export const SHOW_CONFIRMATION_PROMPT = 'SHOW_CONFIRMATION_PROMPT'
export const SHOW_LOCATION_PROMPT = 'SHOW_LOCATION_PROMPT'

export const SET_COORDINATES = 'SET_COORDINATES'
export const SET_RISK_ASSESSMENT_FLOW = 'SET_RISK_ASSESSMENT_FLOW'

export const CLOSE_LOCATION_PROMPT = 'CLOSE_LOCATION_PROMPT'

export const RESET_RISK_ASSESSMENT_FLOW = 'RESET_RISK_ASSESSMENT_FLOW'
