import React, { useState } from 'react'
import Dialog from '../../../components/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import {
	submitRiskAssessment,
	setRiskAssessmentQuestionValue,
} from '../../../redux/assessment/flow/actions'
import {
	selectIsSubmitting,
	selectSubmissionError,
	selectStopWorkQuestion,
} from '../../../redux/assessment/flow/selectors'
import { QuestionUIData } from '../../../redux/assessment/flow/FlowState'

const StopWorkPrompt: React.FunctionComponent = () => {
	const [stopWorkPrompt, setStopWorkPrompt] = useState(false)

	return stopWorkPrompt ? (
		<StopWork />
	) : (
		<StopWorkWarning setStopWorkPrompt={setStopWorkPrompt} />
	)
}

const StopWork: React.FunctionComponent = () => {
	const dispatch = useDispatch()

	const isSubmitting = useSelector(selectIsSubmitting)
	const error = useSelector(selectSubmissionError)

	return (
		<Dialog title="Stop work" bannerColorClass="secondary-2">
			<p className="text-center text-primary-1 mt-4">
				Please stop work immediately and contact your project manager and health
				and safety representative
			</p>
			<div className="body-sm text-error text-center px-3 mt-1">{error}</div>
			<div className="flex justify-center mt-4">
				<button
					className={`btn-primary-popup ${isSubmitting ? 'opacity-50' : ''}`}
					disabled={isSubmitting}
					onClick={() => {
						dispatch(submitRiskAssessment())
					}}
				>
					Accept
				</button>
			</div>
		</Dialog>
	)
}

const StopWorkWarning: React.FunctionComponent<{
	setStopWorkPrompt: (showStopWorkPrompt: boolean) => void
}> = ({ setStopWorkPrompt }) => {
	const dispatch = useDispatch()

	const stopWorkQuestion = useSelector(selectStopWorkQuestion) as QuestionUIData

	return (
		<Dialog title="Warning" bannerColorClass="secondary-2">
			<p className="text-center text-primary-1 mt-8 mx-4">
				You are declaring that you cannot control this hazard.
			</p>
			<div className="flex justify-center mt-8">
				<button
					className="btn-primary-popup mr-4"
					onClick={() => {
						setStopWorkPrompt(true)
					}}
				>
					Confirm
				</button>
				<button
					className="btn-secondary"
					onClick={() => {
						dispatch(setRiskAssessmentQuestionValue(stopWorkQuestion, null))
					}}
				>
					Cancel
				</button>
			</div>
		</Dialog>
	)
}

export default StopWorkPrompt
