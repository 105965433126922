import Action from '../Action'
import { SET_ASSESSMENT_STAGE, RESET_ASSESSMENT_STAGE } from './actionTypes'
import { AssessmentStage } from './AssessmentStage'

export const setAssessmentStage = (
	stage: AssessmentStage
): Action<AssessmentStage> => ({
	type: SET_ASSESSMENT_STAGE,
	payload: stage,
})

export const resetAssessmentStage = (): Action => ({
	type: RESET_ASSESSMENT_STAGE,
})
