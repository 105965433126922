export const groupBy = <T>(
	array: T[],
	keySelector: (item: T) => string
): Record<string, T[]> => {
	const groups: Record<string, T[]> = {}

	for (const entry of array) {
		const key = keySelector(entry)

		const existingGroup: T[] | undefined = groups[key]

		if (existingGroup === undefined) {
			groups[key] = [entry]
		} else {
			existingGroup.push(entry)
		}
	}

	return groups
}
