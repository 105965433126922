import React from 'react'
import Select from 'react-select'

const Selector: React.FunctionComponent<{
	placeholder: string
	value: string
	name: string
	setValue: (newValue: string) => void
	error?: string
	options: SelectOptions
}> = ({ placeholder, name, value, setValue, error, options }) => {
	return (
		<div data-testid={`select-${name}`}>
			<div className="text-error body-xs-thin">{error ? error : ''}</div>
			<Select
				className="w-full"
				options={options}
				placeholder={placeholder}
				name={name}
				styles={{
					control: (provided) => ({
						...provided,
						border: 'none',
						minHeight: '0',
					}),
					valueContainer: (provided) => ({
						...provided,
						padding: '0',
					}),
					placeholder: (provided) => ({
						...provided,
						color: 'black',
					}),
					dropdownIndicator: (provided) => ({
						...provided,
						padding: '0 0 0 8px',
					}),
					input: (provided) => ({
						...provided,
						margin: '0',
						padding: '0',
						paddingLeft: '2px',
					}),
					indicatorSeparator: (provided) => ({
						...provided,
						marginTop: '4px',
						marginBottom: '4px',
					}),
					singleValue: (provided) => ({
						...provided,
						marginLeft: '0',
					}),
				}}
				value={options.find((o) => o.value === value) ?? null}
				onChange={(value) => {
					setValue((value as { label: string; value: string }).value)
				}}
			/>
		</div>
	)
}

export default Selector

export type SelectOptions = {
	value: string
	label: string
}[]
