import RootState from '..//../RootState'
import RiskAssessmentState from './pdfRiskAssessmentState'

export const selectPdfState = (state: RootState): RiskAssessmentState => {
	const {
		assessmentPdf,
		fetchError,
		isFetching,
	} = state.assessment.pdfAssessment

	return {
		assessmentPdf,
		fetchError,
		isFetching,
	}
}
