import { combineReducers } from 'redux'
import riskAssessments from './assessment/reducer'
import incidentReports from './incident/reducer'
import Action from '../Action'
import { RESET_EMAIL_ADDRESS, SET_EMAIL_ADDRESS } from './actionTypes'

const initialEmail = ''

export const email = (
	state = initialEmail,
	action: Action<unknown>
): string => {
	switch (action.type) {
		case SET_EMAIL_ADDRESS: {
			return action.payload as string
		}
		case RESET_EMAIL_ADDRESS: {
			return initialEmail
		}
		default: {
			return state
		}
	}
}

const offline = combineReducers({
	email,
	riskAssessments,
	incidentReports,
})

export default offline
