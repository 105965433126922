import { SavedIncidentReport } from '../offline/incident/IncidentReportsOfflineState'
import RootState from '../RootState'
import Selector from '../Selector'
import { IncidentStage } from './IncidentStage'

export const selectStage: Selector<IncidentStage> = (state: RootState) =>
	state.incident.stage

export const selectIncidentId: Selector<string> = (state: RootState) =>
	state.incident.id

export const selectSaveableIncidentReport: Selector<SavedIncidentReport> = (
	state: RootState
) => {
	const {
		incident: { stage, id, config, details },
	} = state

	return {
		stage,
		id,
		config,
		details,
	} as SavedIncidentReport
}
