import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withError } from '../../components/FullscreenError'
import { withLoading } from '../../components/FullscreenLoading'
import { PageBannerProps } from '../../components/PageBanner'
import withAsyncAction from '../../components/withAsyncAction'
import { fetchUserProfile, saveDetails } from '../../redux/userProfile/actions'
import {
	selectError,
	selectIsSubmitting,
} from '../../redux/userProfile/selectors'
import EditProfileTemplate from './EditProfileTemplate'
import Profile from './Profile'
import { replace } from 'connected-react-router'

const EditProfile: React.FunctionComponent<{
	data: Profile | null
}> = ({ data: profile }) => {
	const dispatch = useDispatch()
	const error = useSelector(selectError)
	const isSubmitting = useSelector(selectIsSubmitting)

	return (
		profile && (
			<EditProfileTemplate
				profile={profile}
				handleSubmit={(newProfile) => dispatch(saveDetails(newProfile))}
				handleCancel={() => dispatch(replace('/settings'))}
				error={error}
				promptText="Edit profile"
				saveButtonText="Save"
				loading={isSubmitting}
			/>
		)
	)
}

const bannerProps: PageBannerProps = {
	title: 'Edit profile',
	colorClass: 'primary-2',
}

export default withAsyncAction<Profile | null>({
	fetchAction: () => fetchUserProfile(),
	loadingComponent: withLoading(bannerProps),
	errorComponent: withError({
		bannerProps,
		formatError: (error) =>
			`Failed to load your profile - ${error.toLowerCase()}`,
	}),
	dataComponent: EditProfile,
	selectState: (state) => ({
		errorMessage: state.userProfile.fetchError,
		inProgress: state.userProfile.isFetching,
		data: state.userProfile.profile,
	}),
})
