import React from 'react'
import { IconComponent, wrapIcon } from './IconComponent'

const FileIcon: IconComponent = wrapIcon((props) => (
	<svg
		{...props}
		xmlns="http://www.w3.org/2000/svg"
		width="50"
		height="65"
		strokeWidth="0"
		strokeLinecap="round"
		viewBox="0 0 24 24"
	>
		<g>
			<g>
				<path stroke={props.fill} d="M0,0H24V24H0Z" />
			</g>
			<g>
				<path
					fill={props.stroke}
					d="M14,2H6A2,2,0,0,0,4.01,4L4,20a2,2,0,0,0,1.99,2H18a2.006,2.006,0,0,0,2-2V8Zm4,18H6V4h7V9h5Zm-7.05-4.83Z"
				/>
			</g>
		</g>
		<path
			fill={props.stroke}
			d="M12.547,7.734h0c-.138,0,.033.013,0,0,.022-.013,0-.74,0,0h.344v0C12.891,6.832,12.558,7.76,12.547,7.734Zm.421-.215h0Zm-5.859,0Zm4.466,2.955V8.014a.768.768,0,0,0-.7-.82H9.487v4.1H10.88A.768.768,0,0,0,11.576,10.475Zm-1.392-2.46h.7v2.46h-.7Zm2.785,1.64h.7v-.82h-.7v-.82h.7v-.82H12.272v4.1h.7Zm-5.569,0h.7a.768.768,0,0,0,.7-.82v-.82a.768.768,0,0,0-.7-.82H6.7v4.1h.7Zm0-1.64h.7v.82H7.4Z"
			transform="translate(1.817 7)"
		/>
	</svg>
))

export default FileIcon
