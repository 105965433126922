import React, { useEffect } from 'react'
import EditProfileTemplate from './EditProfileTemplate'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsSubmitting } from '../../redux/userProfile/selectors'
import {
	signUp,
	clearErrorMessage,
	resetRegisterPage,
} from '../../redux/users/actions'
import {
	selectAuthState,
	selectSignUpSuccess,
} from '../../redux/users/selectors'
import { replace } from 'connected-react-router'

const SIGN_UP_SUCCESS_MESSAGE =
	'User created successfully. Please verify your email address before logging in.'
const Register: React.FunctionComponent = () => {
	const { error } = useSelector(selectAuthState)
	const signUpSuccess = useSelector(selectSignUpSuccess)
	const isSubmitting = useSelector(selectIsSubmitting)
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(resetRegisterPage())
	}, [dispatch])

	return (
		<div className="p-safe h-full">
			<EditProfileTemplate
				profile={{
					name: '',
					email: '',
					countryCode: '',
					operatingUnit: '',
					phoneNumber: '',
					primaryWorkLocation: '',
				}}
				handleSubmit={(newProfile, passwordObj) => {
					if (!passwordObj) {
						throw new Error('No password entered.')
					}

					dispatch(
						signUp(newProfile, passwordObj.password, passwordObj.confirm)
					)
				}}
				handleCancel={() => {
					dispatch(replace('/login'))
					dispatch(clearErrorMessage())
				}}
				saveButtonText="Submit"
				promptText="Register"
				error={error}
				message={signUpSuccess ? SIGN_UP_SUCCESS_MESSAGE : undefined}
				includePasswords
				loading={isSubmitting}
			/>
		</div>
	)
}

export default Register
