import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRiskAssessmentQuestionValue } from '../../../redux/assessment/flow/actions'
import { AnswerType } from '../../../redux/assessment/flow/constants/schema'
import {
	DocumentationUIData,
	QuestionUIData,
} from '../../../redux/assessment/flow/FlowState'
import {
	selectDocumentationForQuestion,
	selectFollowUpQuestion,
} from '../../../redux/assessment/flow/selectors'
import DocumentDetails from './DocumentDetails'
import YesNoQuestion, { QuestionSize } from './YesNoQuestion'

const RiskAssessmentQuestion: React.FunctionComponent<{
	question: QuestionUIData
}> = ({ question }) => {
	const dispatch = useDispatch()

	const incorrectAnswer =
		question.value !== null &&
		question.value !== AnswerType.NOTAPPLICABLE &&
		question.value !== question.question.favorableAnswer

	const followUpQuestion = useSelector(selectFollowUpQuestion(question))
	const documentation = useSelector(selectDocumentationForQuestion(question))

	const showFollowUpQuestion = followUpQuestion !== null && incorrectAnswer
	const showDocumentation = documentation !== null && incorrectAnswer

	const ref = React.createRef<HTMLDivElement>()

	const handleChangeValue = (value: AnswerType) => {
		dispatch(setRiskAssessmentQuestionValue(question, value))
		if (
			ref.current &&
			(value === question.question.favorableAnswer ||
				value === AnswerType.NOTAPPLICABLE)
		) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
			})
		}
	}

	return (
		<div ref={ref}>
			<YesNoQuestion
				questionId={question.questionId}
				prompt={question.question.prompt}
				value={question.value}
				notApplicableOption={question.questionId === 'correct-permits'}
				setValue={handleChangeValue}
				size={question.parentId ? QuestionSize.SMALL : QuestionSize.REGULAR}
				error={question.error}
			>
				<div className="ml-6">
					{showFollowUpQuestion && (
						<RiskAssessmentQuestion
							question={followUpQuestion as QuestionUIData}
						/>
					)}
					{showDocumentation && (
						<DocumentDetails
							documentation={documentation as DocumentationUIData}
						/>
					)}
				</div>
			</YesNoQuestion>
		</div>
	)
}

export default RiskAssessmentQuestion
