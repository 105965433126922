import React from 'react'
import { setRiskAssessmentPdfError } from '../redux/assessment/pdfAssessment/actions'
import FileIcon from '../icons/FileIcon'
import { useDispatch } from 'react-redux'
import { getRiskAssessmentPdf } from '../services/assessment/assessmentService'
import RiskAssessmentPdf from '../redux/assessment/pdfAssessment/RiskAssessmentPdf'
import Colors from '../Colors'
import { logError } from '../services/Error/errorService'
import { StatusType } from '../screens/home/Status'

interface Props {
	assessmentId: string
	status: StatusType
}

const FileDownloader: React.FunctionComponent<Props> = ({
	assessmentId,
	status,
}: Props) => {
	const dispatch = useDispatch()
	const onClickHandler = async () => {
		try {
			const assessmentPdf: RiskAssessmentPdf = await getRiskAssessmentPdf(
				assessmentId
			)
			const blob = new Blob(
				[Buffer.from(JSON.parse(assessmentPdf.pdfContent).blob, 'base64')],
				{ type: 'application/pdf' }
			)
			const url = URL.createObjectURL(blob)
			window.location.href = url
			setTimeout(() => URL.revokeObjectURL(url), 1000)
		} catch (e) {
			logError({
				message: e.message,
				name: e.name,
			})
			dispatch(setRiskAssessmentPdfError(e.message))
		}
	}

	return (
		<button
			type="button"
			className={`disabled:opacity-50 focus:outline-none ${
				status !== StatusType.Submitted ? 'cursor-default' : ''
			}`}
			onClick={() => onClickHandler()}
			disabled={status !== StatusType.Submitted}
		>
			<FileIcon
				className="m-1"
				fill={Colors.background}
				stroke={Colors.gray[6]}
			/>
		</button>
	)
}

export default FileDownloader
