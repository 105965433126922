import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { selectSaveableRiskAssessment } from '../../redux/assessment/selectors'
import { upsertSavedRiskAssessment } from '../../redux/offline/assessment/actions'
import { resetMetadataValues } from '../../redux/assessment/metadata/actions'
import { resetRiskAssessmentFlow } from '../../redux/assessment/flow/actions'
import { resetAssessmentStage } from '../../redux/assessment/actions'

const AutoSaveRiskAssessment: React.FunctionComponent = () => {
	const dispatch = useDispatch()
	const previousRoute = useRef('')
	const savedAssessment = useSelector(selectSaveableRiskAssessment)
	const currentRoute = useLocation().pathname

	useEffect(() => {
		const clearListener = () => {
			if (
				previousRoute.current === '/assessment' &&
				currentRoute !== '/assessment'
			) {
				if (
					savedAssessment.metadataValues.title &&
					savedAssessment.metadataValues.title.length > 0
				) {
					dispatch(upsertSavedRiskAssessment(savedAssessment))
					dispatch(resetMetadataValues())
					dispatch(resetRiskAssessmentFlow())
					dispatch(resetAssessmentStage())
				}
			}

			previousRoute.current = currentRoute
		}

		return () => {
			clearListener()
		}
	}, [dispatch, currentRoute, savedAssessment])
	return null
}

export default AutoSaveRiskAssessment
