import { SelectOptions } from '../../../components/Selector'

export const getIncidentInjuryTypes = (): SelectOptions =>
	incidentInjuryTypeList.map((injuryType) => ({
		value: injuryType,
		label: injuryType.length ? injuryType : 'Type of injury',
	}))

export enum IncidentInjuryType {
	Amputation = 'Amputation',
	AnimalOrInsectBites = 'Animal / Insect Bites',
	Bruises = 'Bruises',
	SkinIrritation = 'Skin Irritation',
	BurnsOrScalds = 'Burns/Scalds',
	CutsOrAbrasions = 'Cuts / Abrasions',
	Dislocations = 'Dislocations',
	ElectricalInjuries = 'Electrical Injuries',
	Fatality = 'Fatality',
	FracturesInFingersOrThumbsOrToes = 'Fractures (fingers, thumbs and toes)',
	FracturesInOtherThanFingersOrThumbsOrToes = 'Fractures (other than fingers, thumbs and toes)',
	LossOfConsciousness = 'Loss of Consciousness',
	LossOfHearingOrReductionInHearing = 'Loss of Hearing / Reduction in Hearing',
	LossOfSightOrReductionInSight = 'Loss of Sight / Reduction in Sight',
	Psychological = 'Psychological',
	SprainsOrStrainsOrOtherMuscleInjuries = 'Sprains, Strains & other Muscle injuries',
}

const incidentInjuryTypeList: string[] = [
	'',
	...Object.values(IncidentInjuryType),
]
