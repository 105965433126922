import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRiskAssessmentAdditionalInformationValue } from '../../../redux/assessment/flow/actions'
import { selectAdditionalInformation } from '../../../redux/assessment/flow/selectors'
import TextualQuestion from './TextualQuestion'
import { QuestionSize } from './YesNoQuestion'

const AdditionalInformation: React.FunctionComponent = () => {
	const dispatch = useDispatch()
	const information = useSelector(selectAdditionalInformation)

	return (
		<TextualQuestion
			prompt="Additional risk assessment information"
			value={information.value}
			setValue={(newValue) =>
				dispatch(setRiskAssessmentAdditionalInformationValue(newValue))
			}
			error={information.error}
			size={QuestionSize.REGULAR}
		/>
	)
}

export default AdditionalInformation
