import AuthenticationResult from './AuthenticationResult'
import { getEnvironmentVariables } from './getEnvironmentVariables'
import { formSerialise } from '../../serialisation/formSerialise'
import {
	deleteStoredTokens,
	parseAuthTokenResult,
	setStoredTokens,
} from './storedTokens'
import getAPIUrl from '../getAPIUrl'

export type AuthTokenResult = {
	access_token: string
	refresh_token: string
	id_token: string
	token_type: string
	expires_in: number
}

export const authenticate = async (
	username: string,
	password: string
): Promise<AuthenticationResult> => {
	try {
		const { audience, clientId, tokenUrl } = getEnvironmentVariables()

		const details = {
			grant_type: 'password',
			audience,
			client_id: clientId,
			username,
			password,
			scope: 'offline_access',
		}

		const response = await fetch(tokenUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			},
			body: formSerialise(details),
		})

		if (!response.ok) {
			const { error_description } = await response.json()
			return {
				success: false,
				message: error_description,
			}
		}

		const token = (await response.json()) as AuthTokenResult
		setStoredTokens(parseAuthTokenResult(token))

		// Notify the API that we logged in successfully and to perform any setup as may be necessary
		const notifyLoginResponse = await fetch(getAPIUrl('notifyLogin'), {
			method: 'POST',
			headers: { Authorization: `Bearer ${token.access_token}` },
		})

		if (!notifyLoginResponse.ok) {
			deleteStoredTokens()

			try {
				const responseMessage = await notifyLoginResponse.json()

				if (responseMessage.message !== undefined) {
					return {
						success: false,
						message: responseMessage.message,
					}
				}
			} catch (e) {
				return {
					success: false,
					message: 'Login failed.',
				}
			}
		}

		return {
			success: true,
		}
	} catch (e) {
		return {
			success: false,
			message: e.message,
		}
	}
}
