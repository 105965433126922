import Action from '../../Action'
import {
	SET_INCIDENT_REPORT_SUBMISSION_ERROR,
	SET_INCIDENT_REPORT_SUBMITTING,
	SHOW_INCIDENT_REPORT_CALL_PROMPT,
	SHOW_INCIDENT_REPORT_SUBMISSION_PROMPT,
} from './actionType'
import { SubmissionState } from './SubmissionState'

const initialState: SubmissionState = {
	error: null,
	isSubmitting: false,
	showCallPrompt: false,
	showSubmissionPrompt: false,
}

const submission = (
	state = initialState,
	action: Action<unknown>
): SubmissionState => {
	switch (action.type) {
		case SET_INCIDENT_REPORT_SUBMISSION_ERROR: {
			return {
				...state,
				error: action.payload as string | null,
			}
		}
		case SET_INCIDENT_REPORT_SUBMITTING: {
			return {
				...state,
				isSubmitting: action.payload as boolean,
			}
		}
		case SHOW_INCIDENT_REPORT_CALL_PROMPT: {
			return {
				...state,
				showCallPrompt: action.payload as boolean,
			}
		}
		case SHOW_INCIDENT_REPORT_SUBMISSION_PROMPT: {
			return {
				...state,
				showSubmissionPrompt: action.payload as boolean,
			}
		}
		default: {
			return state
		}
	}
}

export default submission
