import React from 'react'
import { AnswerType } from '../../../redux/assessment/flow/constants/schema'

export enum QuestionSize {
	REGULAR,
	SMALL,
}

const YesNoQuestion: React.FunctionComponent<{
	questionId: string
	prompt: string
	value: AnswerType | null
	setValue: (value: AnswerType) => void
	size: QuestionSize
	notApplicableOption: boolean
	error: string | null
}> = ({
	questionId,
	prompt,
	value,
	setValue,
	size,
	notApplicableOption,
	error,
	children,
}) => {
	const margin = size === QuestionSize.SMALL ? 2 : 6
	const textClass = size === QuestionSize.SMALL ? 'body-sm' : ''

	const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		switch (e.target.value) {
			case AnswerType.NO: {
				setValue(AnswerType.NO)
				break
			}
			case AnswerType.YES: {
				setValue(AnswerType.YES)
				break
			}
			case AnswerType.NOTAPPLICABLE: {
				setValue(AnswerType.NOTAPPLICABLE)
				break
			}
		}
	}

	return (
		<div
			className={`mb-${margin} ${error ? 'border-error border-l-3' : ''} pl-1`}
		>
			{error && <div className={`text-error ${textClass}`}>{error}</div>}
			<h3 className={textClass}>{prompt}</h3>
			<div className={`block mt-${margin}`}>
				<label className={textClass}>
					<input
						className="mx-6 h-4 w-4"
						type="radio"
						name={questionId}
						value={AnswerType.YES}
						checked={value === AnswerType.YES}
						onChange={onChange}
					/>
					Yes
				</label>
			</div>
			<div className={`block my-${margin}`}>
				<label className={textClass}>
					<input
						className="mx-6 h-4 w-4"
						type="radio"
						name={questionId}
						value={AnswerType.NO}
						checked={value === AnswerType.NO}
						onChange={onChange}
					/>
					No
				</label>
			</div>
			<div
				className={`block my-${margin}`}
				style={{ display: notApplicableOption ? 'inline' : 'none' }}
			>
				<label className={textClass}>
					<input
						className="mx-6 h-4 w-4"
						type="radio"
						name={questionId}
						value={AnswerType.NOTAPPLICABLE}
						checked={value === AnswerType.NOTAPPLICABLE}
						onChange={onChange}
					/>
					Not Applicable
				</label>
			</div>
			{children}
		</div>
	)
}

export default YesNoQuestion
