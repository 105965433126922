import React from 'react'
import { IconComponent, wrapIcon, calculateStrokeWidth } from './IconComponent'

const ChevronForwardIcon: IconComponent = wrapIcon((props) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<polyline
			points="184 112 328 256 184 400"
			style={{
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeWidth: calculateStrokeWidth(props.thickness, 48),
			}}
		/>
	</svg>
))

export default ChevronForwardIcon
