import Action from '../Action'
import {
	SET_INCIDENT_STAGE,
	RESET_INCIDENT_STAGE,
	SET_INCIDENT_ID,
	RESET_INCIDENT_ID,
} from './actionTypes'
import { IncidentStage } from './IncidentStage'

export const setIncidentStage = (
	stage: IncidentStage
): Action<IncidentStage> => ({
	type: SET_INCIDENT_STAGE,
	payload: stage,
})

export const resetIncidentStage = (): Action => ({
	type: RESET_INCIDENT_STAGE,
})

export const setIncidentId = (id: string): Action<string> => ({
	type: SET_INCIDENT_ID,
	payload: id,
})

export const resetIncidentId = (): Action => ({
	type: RESET_INCIDENT_ID,
})
