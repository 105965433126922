import React, { useState } from 'react'
import IconFormField from '../../components/IconFormField'
import LockClosedIcon from '../../icons/LockClosedIcon'
import { IconComponent } from '../../icons/IconComponent'
import LockOpenIcon from '../../icons/LockOpenIcon'
import PageBanner, { PageBannerProps } from '../../components/PageBanner'
import { useDispatch, useSelector } from 'react-redux'
import { changePassword } from '../../redux/users/actions'
import { selectAuthState } from '../../redux/users/selectors'
import { goBack } from 'connected-react-router'
import SafeForm from '../../components/SafeForm'

const Input: React.FunctionComponent<{
	icon: IconComponent
	placeholder: string
	name: string
	value: string
	type: string
	setValue: (newValue: string) => void
}> = ({ icon, placeholder, name, value, type, setValue }) => (
	<IconFormField icon={icon}>
		<input
			className="w-full mt-2"
			type={type}
			placeholder={placeholder}
			name={name}
			value={value}
			onChange={(e) => setValue(e.target.value)}
			required
		/>
	</IconFormField>
)

const bannerProps: PageBannerProps = {
	title: 'Change password',
	colorClass: 'secondary-4',
}

const ChangePassword: React.FunctionComponent<{
	handleSubmit: (
		currentPassword: string,
		newPassword: string,
		confirmNewPassword: string
	) => void
	error: string | null
	loading?: boolean
}> = ({ handleSubmit, error, loading = false }) => {
	const [currentPassword, setCurrentPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [confirmNewPassword, setConfirmNewPassword] = useState('')
	const dispatch = useDispatch()

	return (
		<div className="w-full overflow-auto">
			<PageBanner {...bannerProps} />
			<SafeForm
				onSubmit={() => {
					handleSubmit(currentPassword, newPassword, confirmNewPassword)
				}}
			>
				<Input
					icon={LockOpenIcon}
					type="password"
					placeholder="Current password"
					name="password"
					value={currentPassword}
					setValue={setCurrentPassword}
				/>
				<Input
					icon={LockClosedIcon}
					type="password"
					placeholder="New password"
					name="new-password"
					value={newPassword}
					setValue={setNewPassword}
				/>
				<Input
					icon={LockClosedIcon}
					type="password"
					placeholder="Re-type new password"
					name="confirm-password"
					value={confirmNewPassword}
					setValue={setConfirmNewPassword}
				/>

				<div className="flex flex-col items-center mb-6">
					<div className="body-sm text-error text-center mt-3 px-5">
						{error}
					</div>
					<button className="btn-primary mt-4" disabled={loading}>
						Save
					</button>
					<button
						className="btn-secondary mt-8"
						disabled={loading}
						onClick={(e) => {
							e.preventDefault()
							dispatch(goBack())
						}}
					>
						Cancel
					</button>
				</div>
			</SafeForm>
		</div>
	)
}

const ChangeUserPassword: React.FunctionComponent = () => {
	const dispatch = useDispatch()
	const { error } = useSelector(selectAuthState)

	return (
		<ChangePassword
			handleSubmit={(currentPassword, newPassword, confirmNewPassword) =>
				dispatch(
					changePassword(currentPassword, newPassword, confirmNewPassword)
				)
			}
			error={error}
		/>
	)
}

export default ChangeUserPassword
