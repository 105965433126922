import { getStoredTokens } from '.'
import { getDate } from '../../testing/date'
import { SynchronisedPromise } from '../sync/synchronisedPromise'
import { AuthTokenResult } from './authenticate'
import { getEnvironmentVariables } from './getEnvironmentVariables'
import {
	deleteStoredTokens,
	parseAuthTokenResult,
	setStoredTokens,
} from './storedTokens'

const sync = new SynchronisedPromise<string | null>()

type ExpiryEventHandler = () => void

let expirySubscribers: ExpiryEventHandler[] = []
export const tokenExpiry = {
	subscribe: (method: ExpiryEventHandler): void => {
		expirySubscribers.push(method)
	},

	unsubscribe: (method: ExpiryEventHandler): void => {
		expirySubscribers = expirySubscribers.filter((x) => x !== method)
	},
}

const notifyExpirySubscribers = (): void => {
	for (const handler of expirySubscribers) {
		handler()
	}
}

export const getAccessToken = async (): Promise<string | null> => {
	if (sync.running) {
		// Don't refresh the token during an existing refresh. Instead wait for that refresh to finish and re-use the result.
		return await sync.spawn()
	}

	const tokens = getStoredTokens()

	if (tokens === null) {
		return null
	}

	if (tokens.expiryDate.getTime() < getDate().getTime()) {
		sync.start()

		const result = await tryRefreshToken(tokens.refreshToken)

		if (result === null) {
			deleteStoredTokens()
			sync.resolve(null)

			notifyExpirySubscribers()
			return null
		}

		setStoredTokens(parseAuthTokenResult(result))

		sync.resolve(result.access_token)
		return result.access_token
	}

	return tokens.accessToken
}

const tryRefreshToken = async (
	refreshToken: string
): Promise<AuthTokenResult | null> => {
	const { clientId, tokenUrl } = getEnvironmentVariables()
	let response: Response

	try {
		response = await fetch(tokenUrl, {
			method: 'post',
			body: JSON.stringify({
				grant_type: 'refresh_token',
				client_id: clientId,
				refresh_token: refreshToken,
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		})
	} catch (err) {
		return null
	}

	if (response.status < 200 || response.status >= 300) {
		return null
	}

	return (await response.json()) as AuthTokenResult
}
