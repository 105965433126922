import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SafeForm from '../../../components/SafeForm'
import withRedirectRoute from '../../../components/withRedirectRoute'
import { AnswerType } from '../../../redux/assessment/flow/constants/schema'
import { selectHasFilledInValues } from '../../../redux/assessment/metadata/selectors'
import RiskAssessmentBanner from '../RiskAssessmentBanner'
import YesNoQuestion, { QuestionSize } from './YesNoQuestion'
import PreVisitAssessmentCompletedFlow from '../../../redux/assessment/flow/constants/PreVisitAssessmentCompletedFlow'
import NoPreVisitAssessmentCompletedFlow from '../../../redux/assessment/flow/constants/NoPreVisitAssessmentCompletedFlow'
import { setRiskAssessmentQuestions } from '../../../redux/assessment/flow/actions'
import { setAssessmentStage } from '../../../redux/assessment/actions'
import {
	ASSESSMENT_STAGE_CONFIG,
	ASSESSMENT_STAGE_FLOW,
} from '../../../redux/assessment/AssessmentStage'
import { selectQuestionBlockId } from '../../../redux/assessment/flow/selectors'

const preVisitCompleteQuestion = (id: string | null): AnswerType | null => {
	if (id === null) {
		return null
	}

	return id === PreVisitAssessmentCompletedFlow.id
		? AnswerType.YES
		: AnswerType.NO
}

const VisitCompleted: React.FunctionComponent = () => {
	const dispatch = useDispatch()
	const id = useSelector(selectQuestionBlockId)

	const [error, setError] = useState<string | null>(null)
	const [value, setValue] = useState<AnswerType | null>(
		preVisitCompleteQuestion(id)
	)

	const handleSubmit = () => {
		if (value === null) {
			setError('Please select an answer.')
		} else {
			const questions =
				value === AnswerType.YES
					? PreVisitAssessmentCompletedFlow
					: NoPreVisitAssessmentCompletedFlow
			if (id === null || id !== questions.id) {
				dispatch(setRiskAssessmentQuestions(questions))
			}
			dispatch(setAssessmentStage(ASSESSMENT_STAGE_FLOW))
		}
	}

	return (
		<div className="flex flex-col h-full">
			<RiskAssessmentBanner />
			<SafeForm
				className="p-6 h-full flex flex-col items-center overflow-auto"
				onSubmit={handleSubmit}
			>
				<YesNoQuestion
					questionId="preVisitAssessmentCompleted"
					prompt="Has a pre visit assessment been created and authorised?"
					value={value}
					setValue={(value) => {
						setValue(value)
						setError(null)
					}}
					error={error}
					notApplicableOption={false}
					size={QuestionSize.REGULAR}
				/>
				<div className="flex flex-col items-center">
					<button className="btn-primary mt-4">Next</button>
					<button
						className="btn-secondary mt-8"
						onClick={(e) => {
							e.preventDefault()
							dispatch(setAssessmentStage(ASSESSMENT_STAGE_CONFIG))
						}}
					>
						Cancel
					</button>
				</div>
			</SafeForm>
		</div>
	)
}

export default withRedirectRoute(
	{
		to: '/assessment',
		push: false,
	},
	() => useSelector(selectHasFilledInValues),
	VisitCompleted
)
