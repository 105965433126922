import { verify } from 'jsonwebtoken'
import { logError } from '../Error/errorService'
import { getKey } from './keyManagement'

export const isTokenValid = async (token: string): Promise<boolean> => {
	try {
		const signingKey = await getKey()

		return await new Promise((resolve, reject) => {
			if (!token) {
				reject('No authentication token')
			}
			verify(token, signingKey, (err) => {
				if (err) {
					resolve(false)
				}
				resolve(true)
			})
		})
	} catch (e) {
		return false
	}
}
