import Action from '../Action'
import {
	SET_FETCHING_ERRORS,
	SET_ERRORS,
	FETCH_ERRORS,
	SET_FETCH_ERROR,
} from './actionTypes'
import { LoggedError } from '../../screens/logErrors/LogErrors'

export const RequestErrors = (): Action => ({
	type: FETCH_ERRORS,
})

export const setFetchingErrors = (fetching: boolean): Action<boolean> => ({
	type: SET_FETCHING_ERRORS,
	payload: fetching,
})

export const setFetchErrorsError = (
	error: string | null
): Action<string | null> => ({
	type: SET_FETCH_ERROR,
	payload: error,
})

export const setErrors = (errors: LoggedError[]): Action<LoggedError[]> => ({
	type: SET_ERRORS,
	payload: errors,
})
