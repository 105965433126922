import {
	setFetchingRecentRiskAssessments,
	setFetchRecentRiskAssessmentsError,
	setRecentRiskAssessments,
} from './actions'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { FETCH_RECENT_RISK_ASSESSMENTS } from './actionTypes'
import { getRecentRiskAssessments } from '../../../services/assessment/assessmentService'
import RecentRiskAssessment from './RecentRiskAssessments'
import { serviceRequestSaga } from '../../services/sagas'
import { logError } from '../../../services/Error/errorService'
import { selectIsWorkingOffline } from '../../users/selectors'

export function* fetchRecentRiskAssessments(): Generator<
	unknown,
	void,
	unknown
> {
	yield put(setFetchingRecentRiskAssessments(true))
	yield put(setFetchRecentRiskAssessmentsError(null))

	const isOffline = (yield select(selectIsWorkingOffline) as unknown) as boolean
	try {
		if (isOffline) {
			yield put(setRecentRiskAssessments([]))
		} else {
			const recentRiskAssessments = (yield call(
				serviceRequestSaga,
				getRecentRiskAssessments
			) as unknown) as RecentRiskAssessment[]

			yield put(setRecentRiskAssessments(recentRiskAssessments))
		}
	} catch (e) {
		logError({
			message: e.message,
			name: e.name,
		})
		yield put(setFetchRecentRiskAssessmentsError(e.message))
	} finally {
		yield put(setFetchingRecentRiskAssessments(false))
	}
}

export default [
	takeEvery(FETCH_RECENT_RISK_ASSESSMENTS, fetchRecentRiskAssessments),
]
