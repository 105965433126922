import RootState from '../RootState'
import AuthenticationState from './AuthenticationState'

export const selectAuthState = (state: RootState): AuthenticationState => {
	const { isLoading, error } = state.users

	return {
		isLoading,
		error,
	}
}

export const selectIsLoggedIn = (state: RootState): boolean =>
	state.users.isLoggedIn

export const selectIsResetting = (state: RootState): boolean =>
	state.users.isResetting

export const selectIsReset = (state: RootState): boolean => state.users.isReset

export const selectIsWorkingOffline = (state: RootState): boolean =>
	state.users.isLoggedIn &&
	(!state.users.isTokenValid || !state.users.hasConnectivity)

export const selectIsTokenValid = (state: RootState): boolean =>
	state.users.isTokenValid

export const selectHasConnectivity = (state: RootState): boolean =>
	state.users.hasConnectivity

export const selectSignUpSuccess = (state: RootState): boolean =>
	state.users.signUpSuccess
