import React from 'react'

export enum QuestionSize {
	REGULAR,
	SMALL,
}

const OptionsButtonQuestion: React.FunctionComponent<{
	styleClass?: string
	questionName: string
	prompt: string
	value: string
	setValue: (value: string) => void
	size: QuestionSize
	options: string[]
	error: string
}> = ({
	styleClass,
	questionName,
	prompt,
	value,
	setValue,
	size,
	options,
	error,
	children,
}) => {
	const textClass = size === QuestionSize.SMALL ? 'body-sm' : ''

	const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setValue(e.target.value)
	}

	const getOptionText = (option: string) =>
		option.includes('(') ? option.substr(0, option.indexOf('(')).trim() : option

	return (
		<div className={styleClass ? styleClass : ''}>
			{error && <div className="text-error body-xs-thin">{error}</div>}
			<h3 className={textClass}>{prompt}</h3>
			{options.map((option: string, key) => (
				<div key={`${questionName}-${key}`} className={`block my-2`}>
					<label className={textClass}>
						<input
							className="mx-5 h-3 w-3"
							type="radio"
							name={questionName}
							value={getOptionText(option)}
							checked={value === getOptionText(option)}
							onChange={onChange}
						/>
						{option}
					</label>
				</div>
			))}
			{children}
		</div>
	)
}

export default OptionsButtonQuestion
