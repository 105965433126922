import Action from '../../Action'
import {
	SET_FETCHING_RECENT_RISK_ASSESSMENTS,
	SET_RECENT_RISK_ASSESSMENTS,
	FETCH_RECENT_RISK_ASSESSMENTS,
	SET_FETCH_RISK_ASSESSMENTS_ERROR,
} from './actionTypes'
import RecentRiskAssessment from './RecentRiskAssessments'

export const requestRecentRiskAssessments = (): Action => ({
	type: FETCH_RECENT_RISK_ASSESSMENTS,
})

export const setFetchingRecentRiskAssessments = (
	fetching: boolean
): Action<boolean> => ({
	type: SET_FETCHING_RECENT_RISK_ASSESSMENTS,
	payload: fetching,
})

export const setFetchRecentRiskAssessmentsError = (
	error: string | null
): Action<string | null> => ({
	type: SET_FETCH_RISK_ASSESSMENTS_ERROR,
	payload: error,
})

export const setRecentRiskAssessments = (
	recentRiskAssessments: RecentRiskAssessment[]
): Action<RecentRiskAssessment[]> => ({
	type: SET_RECENT_RISK_ASSESSMENTS,
	payload: recentRiskAssessments,
})
