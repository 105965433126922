import React, { useState } from 'react'
import styled from 'styled-components'
import { workingOffline } from '../redux/users/actions'
import { useDispatch } from 'react-redux'
import emailValidator from 'email-validator'
import { BMT_DOMAINS } from '../screens/assessment/validation'
import { checkAndResetOfflineAssessmentAndIncidents } from '../redux/offline/action'

const StyledOfflineButton = styled.button`
	color: white;
	background-color: #005581;
	font-size: small;
	box-shadow: 8px 8px 5px rgba(0, 0, 0, 0.5);
	line-height: 0.5%;
	font-size: 10px;
	letter-spacing: 0.5px;
`

const WorkOffline: React.FunctionComponent<{ email: string }> = ({ email }) => {
	const dispatch = useDispatch()
	const [emailError, setEmailError] = useState('')

	const validateEmail = (): boolean => {
		const trimmedEmail = email.trim().toLowerCase()

		if (trimmedEmail.length === 0) {
			setEmailError('Please enter an email address')
			return false
		}

		if (!emailValidator.validate(trimmedEmail)) {
			setEmailError('Please enter a valid email address')
			return false
		}

		if (!BMT_DOMAINS.some((domain) => trimmedEmail.endsWith(domain))) {
			setEmailError('Please enter a BMT email address')
			return false
		}
		return true
	}
	return (
		<>
			{emailError && (
				<div className="fixed bottom-0 mb-18 pb-6 text-error">{emailError}</div>
			)}
			<StyledOfflineButton
				type="button"
				className="fixed bottom-0 mb-16 rounded-md h-5 p-3"
				onClick={(e) => {
					e.preventDefault()
					if (validateEmail()) {
						dispatch(checkAndResetOfflineAssessmentAndIncidents(email))
						dispatch(workingOffline(true))
					}
				}}
			>
				Work offline
			</StyledOfflineButton>
		</>
	)
}

export default WorkOffline
