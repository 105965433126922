import { UserLocation } from '../../../services/location/locationService'
import Action from '../../Action'
import { SavedRiskAssessment } from '../../offline/assessment/RiskAssessmentsOfflineState'
import {
	SET_RISK_ASSESSMENT_SUBMISSION_ERROR,
	SET_RISK_ASSESSMENT_QUESTIONS,
	SET_RISK_ASSESSMENT_QUESTION_ERROR,
	SET_RISK_ASSESSMENT_QUESTION_VALUE,
	SET_RISK_ASSESSMENT_DOCUMENTATION,
	SET_RISK_ASSESSMENT_DOCUMENTATION_ERROR,
	SUBMIT_RISK_ASSESSMENT,
	SET_RISK_ASSESSMENT_ADDITIONAL_INFORMATION_VALUE,
	SET_RISK_ASSESSMENT_ADDITIONAL_INFORMATION_ERROR,
	SET_RISK_ASSESSMENT_SUBMITTING,
	VALIDATE_RISK_ASSESSMENT_FORM,
	SHOW_CONFIRMATION_PROMPT,
	SET_COORDINATES,
	SHOW_LOCATION_PROMPT,
	SET_RISK_ASSESSMENT_FLOW,
	CLOSE_LOCATION_PROMPT,
	RESET_RISK_ASSESSMENT_FLOW,
} from './actionTypes'
import { AnswerType, QuestionBlock } from './constants/schema'
import {
	DocumentationUIData,
	QuestionUIData,
	RiskAssessmentFlowState,
} from './FlowState'
import SetQuestionErrorPayload from './SetQuestionErrorPayload'
import SetQuestionValuePayload from './SetQuestionValuePayload'

export const setRiskAssessmentQuestions = (
	questionBlock: QuestionBlock
): Action<QuestionBlock> => ({
	type: SET_RISK_ASSESSMENT_QUESTIONS,
	payload: questionBlock,
})

export const setRiskAssessmentQuestionValue = (
	question: QuestionUIData,
	value: AnswerType | null
): Action<SetQuestionValuePayload<AnswerType | null>> => ({
	type: SET_RISK_ASSESSMENT_QUESTION_VALUE,
	payload: {
		questionId: question.questionId,
		value,
	},
})

export const setRiskAssessmentDocumentationValue = (
	documentation: DocumentationUIData,
	value: string
): Action<SetQuestionValuePayload<string>> => ({
	type: SET_RISK_ASSESSMENT_DOCUMENTATION,
	payload: {
		questionId: documentation.questionId,
		value,
	},
})

export const setRiskAssessmentQuestionError = (
	question: QuestionUIData,
	error: string | null
): Action<SetQuestionErrorPayload> => ({
	type: SET_RISK_ASSESSMENT_QUESTION_ERROR,
	payload: {
		questionId: question.questionId,
		error,
	},
})

export const setRiskAssessmentDocumentationError = (
	documentation: DocumentationUIData,
	error: string | null
): Action<SetQuestionErrorPayload> => ({
	type: SET_RISK_ASSESSMENT_DOCUMENTATION_ERROR,
	payload: {
		questionId: documentation.questionId,
		error,
	},
})

export const setRiskAssessmentAdditionalInformationValue = (
	value: string
): Action<string> => ({
	type: SET_RISK_ASSESSMENT_ADDITIONAL_INFORMATION_VALUE,
	payload: value,
})

export const setRiskAssessmentAdditionalInformationError = (
	error: string | null
): Action<string | null> => ({
	type: SET_RISK_ASSESSMENT_ADDITIONAL_INFORMATION_ERROR,
	payload: error,
})

export const submitRiskAssessment = (): Action => ({
	type: SUBMIT_RISK_ASSESSMENT,
})

export const setRiskAssessmentSubmissionError = (
	error: string | null
): Action<string | null> => ({
	type: SET_RISK_ASSESSMENT_SUBMISSION_ERROR,
	payload: error,
})

export const setRiskAssessmentSubmitting = (
	submitting: boolean
): Action<boolean> => ({
	type: SET_RISK_ASSESSMENT_SUBMITTING,
	payload: submitting,
})

export const validateRiskAssessmentForm = (): Action => ({
	type: VALIDATE_RISK_ASSESSMENT_FORM,
})

export const showConfirmationPrompt = (
	showPrompt: boolean
): Action<boolean> => ({
	type: SHOW_CONFIRMATION_PROMPT,
	payload: showPrompt,
})

export const showLocationPrompt = (showPrompt: boolean): Action<boolean> => ({
	type: SHOW_LOCATION_PROMPT,
	payload: showPrompt,
})

export const setCoordinates = (coordinates: UserLocation): Action => ({
	type: SET_COORDINATES,
	payload: coordinates,
})

export const closeLocationPrompt = (): Action<SavedRiskAssessment> => ({
	type: CLOSE_LOCATION_PROMPT,
})

export const setRiskAssessmentFlow = (
	flow: RiskAssessmentFlowState
): Action<RiskAssessmentFlowState> => ({
	type: SET_RISK_ASSESSMENT_FLOW,
	payload: flow,
})

export const resetRiskAssessmentFlow = (): Action => ({
	type: RESET_RISK_ASSESSMENT_FLOW,
})
