import React from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux'
import App from './App'
import './index.css'
import i18n from './internationalisation/i18n'
import { store, persistor, history } from './redux/store'
import * as serviceWorker from './serviceWorker'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/lib/integration/react'

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConnectedRouter history={history}>
					<I18nextProvider i18n={i18n}>
						<App />
					</I18nextProvider>
				</ConnectedRouter>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if (process.env.REACT_APP_service_worker) {
	serviceWorker.register()
} else {
	serviceWorker.unregister()
}
