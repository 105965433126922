import React from 'react'
import MinusIcon from '../../icons/MinusIcon'
import Resizer from 'react-image-file-resizer'
import { logError } from '../../services/Error/errorService'
import AddPhotoRow from './AddPhotoRow'

export interface Photo {
	name: string
	uri: string
}

const resizeFile = (file: File): Promise<string> =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			300,
			300,
			'JPEG',
			100,
			0,
			(uri) => {
				resolve(uri as string)
			},
			'base64'
		)
	})

const UploadPhotos: React.FunctionComponent<{
	styleClass?: string
	photos: Photo[]
	error: string
	setValue: (newValue: Photo[], newError: string) => void
	maxPhotos?: number
}> = ({ styleClass, photos, error, setValue, maxPhotos = 5 }) => {
	const ref = React.createRef<HTMLInputElement>()

	const photoUploader = (uploadFile: React.RefObject<HTMLInputElement>) => {
		uploadFile.current?.click()
	}

	const onChangeHandler = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		try {
			const target = event.target as HTMLInputElement
			const selectedPhotos: FileList = target.files as FileList
			const uploadedPhotos: Photo[] = photos
			if (selectedPhotos.length > 0) {
				const photoCount = selectedPhotos.length + uploadedPhotos.length
				if (photoCount <= maxPhotos) {
					for (let index = 0; index < selectedPhotos.length; index++) {
						const image: string = await resizeFile(selectedPhotos[index])
							.then((base64String: string) => {
								return base64String
							})
							.catch((err) => {
								throw new Error(err)
							})
						const photo: Photo = {
							name: `${selectedPhotos[index].name.split('.')[0]}.jpeg`,
							uri: image,
						}
						uploadedPhotos.push(photo)
					}
					setValue(uploadedPhotos, '')
				} else {
					setValue(photos, 'Upload limited to 5 photos')
				}
			} else {
				setValue(photos, 'Unable to upload photos')
			}
		} catch (e) {
			logError({
				message: e.message,
				name: e.name,
			})
		}
	}

	return (
		<div className={styleClass ? styleClass : ''}>
			<div className={`text-error body-xs-thin`}>{error}</div>
			<input
				ref={ref}
				type="file"
				name="file"
				accept="image/*"
				multiple
				className="hidden"
				onChange={(event) => onChangeHandler(event)}
			/>
			<div className="w-full">
				{photos.length < maxPhotos ? (
					<AddPhotoRow onClick={() => photoUploader(ref)} />
				) : (
					false
				)}
				{photos
					.slice(0)
					.reverse()
					.map((photo, index) => (
						<div
							key={`${photo.name}-${index}`}
							className="flex items-center mr-4 mb-4 border-secondary-6 border-b p-1"
						>
							<img className="mr-2 w-12 h-10" src={photo.uri} />
							<div className="flex-1">{photo.name}</div>
							<div className="w-6">
								<MinusIcon
									className="w-6 cursor-pointer"
									onClick={() =>
										setValue(
											photos.filter((data) => data !== photo),
											''
										)
									}
								/>
							</div>
						</div>
					))}
			</div>
		</div>
	)
}

export default UploadPhotos
