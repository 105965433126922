import React from 'react'

const SafeForm: React.FunctionComponent<React.FormHTMLAttributes<
	HTMLFormElement
>> = ({ children, ...props }) => (
	<form
		{...props}
		autoComplete="off"
		onSubmit={(e) => {
			e.preventDefault()
			if (props.onSubmit) {
				props.onSubmit(e)
			}
		}}
	>
		{children}
	</form>
)

export default SafeForm
