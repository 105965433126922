import { REQUEST_ASSESSMENT_METADATA_SUGGESTIONS } from './actionTypes'
import { takeEvery, put, call } from 'redux-saga/effects'
import {
	getMetadataSuggestions,
	MetadataSuggestionsResult,
} from '../../../services/assessment/assessmentService'
import { setMetadataSuggestions } from './actions'
import { serviceRequestSaga } from '../../services/sagas'
import { select } from 'redux-saga/effects'
import * as selectors from '../../users/selectors'
import { logError } from '../../../services/Error/errorService'

function* requestSuggestionsSaga() {
	try {
		const isOffline = (yield select(
			selectors.selectIsWorkingOffline
		) as unknown) as boolean
		let suggestions: MetadataSuggestionsResult = {
			projectReferences: [],
			forwardingEmails: [],
		}
		if (!isOffline) {
			suggestions = yield call(serviceRequestSaga, getMetadataSuggestions)
		}
		yield put(
			setMetadataSuggestions({
				...suggestions,
				titles: [],
			})
		)
	} catch (e) {
		logError({
			message: e.message,
			name: e.name,
		})
	}
}

export default [
	takeEvery(REQUEST_ASSESSMENT_METADATA_SUGGESTIONS, requestSuggestionsSaga),
]
