import { takeEvery, put, select, call, all } from 'redux-saga/effects'
import { RESET_INCIDENT_REPORT, SUBMIT_INCIDENT_REPORT } from './actionType'
import { resetIncidentDetails } from '../details/actions'
import { resetIncidentConfig } from '../config/actions'
import {
	setIncidentReportSubmissionError,
	setIncidentReportSubmitting,
	showIncidentReportCallPrompt,
	showIncidentReportSubmissionPrompt,
} from './actions'
import { resetIncidentId, resetIncidentStage } from '../actions'
import IncidentReportSubmission from './IncidentReportSubmission'
import { selectConfig } from '../config/selectors'
import { selectDetails } from '../details/selectors'
import ConfigState from '../config/ConfigState'
import { DetailsState } from '../details/DetailsState'
import { logError } from '../../../services/Error/errorService'
import { serviceRequestSaga } from '../../services/sagas'
import { submitIncident } from '../../../services/incident/incidentService'
import {
	addIncidentReportSubmittedOffline,
	deleteSavedIncidentReport,
} from '../../offline/incident/action'
import { selectIncidentId } from '../selectors'
import { selectIsWorkingOffline } from '../../users/selectors'
import IncidentReportOfflineSubmission from '../../offline/incident/IncidentReportOffline'
import { getDate } from '../../../testing/date'

export function* submitIncidentReportSaga(): Generator<unknown, void, unknown> {
	yield put(setIncidentReportSubmitting(true))
	yield put(setIncidentReportSubmissionError(null))

	const id = (yield select(selectIncidentId)) as string
	const config = (yield select(selectConfig)) as ConfigState
	const details = (yield select(selectDetails)) as DetailsState
	const submission: IncidentReportSubmission = {
		config,
		details,
	}
	const isOffline = (yield select(selectIsWorkingOffline) as unknown) as boolean
	try {
		if (isOffline) {
			const offlineSubmission: IncidentReportOfflineSubmission = {
				id,
				submissionDetails: submission,
				submissionDate: getDate(),
			}

			yield put(addIncidentReportSubmittedOffline(offlineSubmission))
		} else {
			yield call(serviceRequestSaga as never, submitIncident, submission)
		}
		yield put(showIncidentReportCallPrompt(true))
		yield put(showIncidentReportSubmissionPrompt(false))
	} catch (e) {
		if (!isOffline) {
			logError({
				message: e.message,
				name: e.name,
			})
		}
		const errorDescription = isOffline
			? 'Failed to save offline incident report - '
			: 'Failed to submit incident report - '
		yield put(setIncidentReportSubmissionError(errorDescription + e.message))
	} finally {
		yield put(setIncidentReportSubmitting(false))
	}
}

export function* resetIncidentReportSaga(): Generator<unknown, void, unknown> {
	const id = (yield select(selectIncidentId)) as string

	yield put(deleteSavedIncidentReport(id))
	yield all([
		put(resetIncidentStage()),
		put(resetIncidentId()),
		put(resetIncidentConfig()),
		put(resetIncidentDetails()),
	])
}

export default [
	takeEvery(SUBMIT_INCIDENT_REPORT, submitIncidentReportSaga),
	takeEvery(RESET_INCIDENT_REPORT, resetIncidentReportSaga),
]
