import React from 'react'

const Modal: React.FunctionComponent<{ close?: () => void }> = ({
	close,
	children,
}) => (
	<div
		className="fixed h-screen w-screen bg-primary-1 bg-opacity-50 z-30"
		onClick={close}
		role="dialog"
	>
		<div className="flex flex-col items-center justify-center h-full mb-2">
			{children}
		</div>
	</div>
)

export default Modal
