const getAPIUrl = (extra?: string): string => {
	const url = process.env.REACT_APP_API_URL
	if (!url) {
		throw new Error('There is no REACT_APP_API_URL variable defined.')
	}
	if (!extra) {
		return url
	}
	if (url.endsWith('/')) {
		return `${url}${extra}`
	} else {
		return `${url}/${extra}`
	}
}

export default getAPIUrl
