import React from 'react'
import { useSelector } from 'react-redux'
import { EmailedStatus } from '../../../redux/emailedStatus'
import RecentIncidentReport from '../../../redux/incident/recentIncidents/RecentIncidentReport'
import {
	selectOfflineSubmittedIncidents,
	selectSavedIncidents,
} from '../../../redux/offline/incident/selectors'
import { selectIsWorkingOffline } from '../../../redux/users/selectors'
import { StatusType } from '../Status'
import IncidentRow from './IncidentRow'
import { SavedIncidentReportRow } from './SavedIncidentReportRow'

interface Props {
	recentIncidents: RecentIncidentReport[]
}

const RecentIncidents: React.FunctionComponent<Props> = ({
	recentIncidents,
}) => {
	const isOffline = useSelector(selectIsWorkingOffline)
	const offlineSubmittedIncidents = useSelector(selectOfflineSubmittedIncidents)
	const savedIncidents = useSelector(selectSavedIncidents)

	return (
		<div key="recent-Incidents">
			<div className="text-secondary-10 body-medium border-b border-t border-solid border-primary-2">
				<span className="m-4">
					Recent incidents{' '}
					{isOffline && <span className="text-error">- Currently offline</span>}
				</span>
			</div>
			{savedIncidents &&
				savedIncidents.map((incident) => (
					<SavedIncidentReportRow key={incident.id} savedIncident={incident} />
				))}
			{offlineSubmittedIncidents &&
				offlineSubmittedIncidents.map((incident) => {
					const id = `offline_${incident.submissionDate.toISOString()}`

					return (
						<IncidentRow
							key={id}
							incident={{
								id,
								config: incident.submissionDetails.config,
								submittedById: undefined,
								submissionDate: incident.submissionDate,
								details: incident.submissionDetails.details,
								emailedStatus: EmailedStatus.NONE,
								failedEmails: [],
							}}
							status={
								incident.lastSubmissionAttempted === undefined
									? StatusType.PendingOffline
									: StatusType.PendingError
							}
						/>
					)
				})}
			{!isOffline &&
				recentIncidents.map((incidents: RecentIncidentReport) => (
					<IncidentRow
						key={`recent-Incident-${incidents.id}`}
						incident={incidents}
						status={StatusType.Submitted}
					/>
				))}
		</div>
	)
}

export default RecentIncidents
