import React from 'react'
import { QuestionSize } from './OptionsButtonQuestion'

const TextualQuestion: React.FunctionComponent<{
	styleClass?: string
	prompt: string
	value: string
	setValue: (newValue: string) => void
	size: QuestionSize
	error: string
	maxLength: number
}> = ({ styleClass, prompt, value, setValue, size, error, maxLength }) => {
	const textClass = size === QuestionSize.SMALL ? 'body-sm' : ''

	return (
		<div className={styleClass ? styleClass : ''}>
			<div className={`text-error body-xs-thin`}>{error}</div>
			<h4 className={textClass}>{prompt}</h4>
			<textarea
				className={`w-full border ${
					error === '' ? 'border-secondary-10' : 'border-error'
				} body-sm p-1 text-secondary-10`}
				value={value}
				rows={4}
				onChange={(e) => setValue(e.target.value)}
				maxLength={maxLength}
			/>
		</div>
	)
}

export default TextualQuestion
