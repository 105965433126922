import { FETCH_RECENT_RISK_ASSESSMENTS_AND_RECENT_INCIDENTS } from './actionTypes'
import { all, call, takeEvery } from 'redux-saga/effects'
import { fetchRecentIncidentReports } from './incident/recentIncidents/sagas'
import { fetchRecentRiskAssessments } from './assessment/recentAssessments/sagas'

export function* fetchRecentRiskAssessmentsAndRecentIncidents(): Generator<
	unknown,
	void,
	unknown
> {
	yield all([
		call(fetchRecentRiskAssessments),
		call(fetchRecentIncidentReports),
	])
}

export default [
	takeEvery(
		FETCH_RECENT_RISK_ASSESSMENTS_AND_RECENT_INCIDENTS,
		fetchRecentRiskAssessmentsAndRecentIncidents
	),
]
