import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import {
	setMetadataValues,
	requestMetadataSuggestions,
} from '../../redux/assessment/metadata/actions'
import {
	selectMetadataSuggestions,
	selectMetadataValues,
} from '../../redux/assessment/metadata/selectors'
import { fetchUserProfile } from '../../redux/userProfile/actions'
import withAsyncAction from '../../components/withAsyncAction'
import { withLoading } from '../../components/FullscreenLoading'
import { withError } from '../../components/FullscreenError'
import { PageBannerProps } from '../../components/PageBanner'
import Profile from '../profile/Profile'
import { setAssessmentStage } from '../../redux/assessment/actions'
import { ASSESSMENT_STAGE_INITIAL } from '../../redux/assessment/AssessmentStage'
import RiskAssessmentConfigTemplate from './RiskAssessmentConfigTemplate'
import { resetRiskAssessmentFlow } from '../../redux/assessment/flow/actions'

const RiskAssessmentConfig: React.FunctionComponent<{
	data: Profile | null
}> = ({ data: profile }) => {
	const dispatch = useDispatch()
	const suggestions = useSelector(selectMetadataSuggestions)
	const values = useSelector(selectMetadataValues)
	useEffect(() => {
		dispatch(requestMetadataSuggestions())
	}, [dispatch])

	return (
		profile && (
			<RiskAssessmentConfigTemplate
				profile={profile}
				referenceSuggestions={suggestions.projectReferences}
				titleSuggestions={suggestions.titles}
				emailSuggestions={suggestions.forwardingEmails}
				metadataValues={values}
				handleSubmit={(
					projectReference,
					title,
					forwardingEmails,
					hsRep,
					status,
					failedEmails
				) => {
					const metadataValues = {
						localId: values.localId.length > 0 ? values.localId : uuidv4(),
						projectReference,
						title,
						forwardingEmails,
						hsRep,
						status,
						failedEmails,
					}
					dispatch(setMetadataValues(metadataValues))
					dispatch(setAssessmentStage(ASSESSMENT_STAGE_INITIAL))
					if (values.localId.length === 0) {
						dispatch(resetRiskAssessmentFlow())
					}
				}}
			/>
		)
	)
}

const bannerProps: PageBannerProps = {
	title: 'Risk assessment',
	colorClass: 'secondary-3',
}

export default withAsyncAction<Profile | null>({
	fetchAction: () => fetchUserProfile(),
	loadingComponent: withLoading(bannerProps),
	errorComponent: withError({
		bannerProps,
		formatError: (error) =>
			`Failed to load your profile - ${error.toLowerCase()}`,
	}),
	dataComponent: RiskAssessmentConfig,
	selectState: (state) => ({
		errorMessage: state.userProfile.fetchError,
		inProgress: state.userProfile.isFetching,
		data: state.userProfile.profile,
	}),
})
