import KeyedValue from './KeyedValue'
import { useState } from 'react'

export interface ListActions<T> {
	addEntry: (entry: T) => void
	removeEntry: (entry: T) => void
	updateEntry: (entry: T) => void
}

const useListState = <T extends KeyedValue>(
	initialEntries: T[] | (() => T[]),
	addEntryToList: (newEntry: T, entries: T[]) => T[]
): [T[], React.Dispatch<React.SetStateAction<T[]>>, ListActions<T>] => {
	const [entries, setEntries] = useState(initialEntries)

	return [
		entries,
		setEntries,
		{
			addEntry: (entry) => setEntries(addEntryToList(entry, entries)),
			updateEntry: (entry) =>
				setEntries(
					entries.map((curr) => (curr.key === entry.key ? entry : curr))
				),
			removeEntry: (entry) =>
				setEntries(entries.filter((curr) => curr.key !== entry.key)),
		},
	]
}

export default useListState
