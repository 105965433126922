import React from 'react'
import { IconComponent } from '../icons/IconComponent'
import styled from 'styled-components'
import Colors from '../Colors'

const StyledContainer = styled.div`
	& input {
		&::placeholder {
			color: ${Colors.secondary['10']};
		}
	}
`

const IconFormField: React.FunctionComponent<{
	icon?: IconComponent
}> = ({ icon, children }) => {
	const Icon = icon
	const ref = React.createRef<HTMLDivElement>()

	return (
		<div className="w-full pl-4 pr-4 mt-4">
			<StyledContainer className="flex flex-row items-end w-full border-secondary-6 border-b p-1">
				{Icon ? (
					<Icon
						className="ml-2 mb-1 w-6"
						onClick={() => {
							if (ref.current) {
								const element = ref.current.querySelector(
									'input'
								) as HTMLInputElement
								if (element) {
									element.focus()
								}
							}
						}}
					/>
				) : (
					false
				)}
				<div className="w-full" ref={ref}>
					{children}
				</div>
			</StyledContainer>
		</div>
	)
}

export default IconFormField
