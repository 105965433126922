import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SafeForm from '../../../components/SafeForm'
import {
	selectTopLevelQuestions,
	selectHasFlow,
	selectSubmissionError,
	selectShouldStopWork,
	selectShowConfirmationPrompt,
	selectCoordinates,
} from '../../../redux/assessment/flow/selectors'
import RiskAssessmentBanner from '../RiskAssessmentBanner'
import RiskAssessmentQuestion from './RiskAssessmentQuestion'
import StopWorkPrompt from './StopWorkPrompt'
import withRedirectRoute from '../../../components/withRedirectRoute'
import AdditionalInformation from './AdditionalInformation'
import { selectIsSubmitting } from '../../../redux/assessment/flow/selectors'
import AssessmentConfirmationPrompt from './AssessmentConfirmationPrompt'
import { validateRiskAssessmentForm } from '../../../redux/assessment/flow/actions'
import { setAssessmentStage } from '../../../redux/assessment/actions'
import { ASSESSMENT_STAGE_INITIAL } from '../../../redux/assessment/AssessmentStage'
import { LocationPrompt } from './LocationPrompt'
import { LocationPoller } from './LocationPoller'
import { LocationWarning } from './LocationWarning'
import { UserLocationResult } from '../../../services/location/locationService'

const RiskAssessmentFlow: React.FunctionComponent = () => {
	const questions = useSelector(selectTopLevelQuestions)
	const shouldStopWork = useSelector(selectShouldStopWork)
	const error = useSelector(selectSubmissionError)
	const isSubmitting = useSelector(selectIsSubmitting)
	const showConfirmationPrompt = useSelector(selectShowConfirmationPrompt)
	const location = useSelector(selectCoordinates)

	const [wasSubmitted, setWasSubmitted] = useState(false)

	const dispatch = useDispatch()
	const ref = React.createRef<HTMLDivElement>()

	const autoScroll = useCallback(() => {
		if (ref.current) {
			ref.current.scrollIntoView({ behavior: 'smooth' })
		}
	}, [ref])

	useEffect(() => {
		if (error && wasSubmitted) {
			autoScroll()
			setWasSubmitted(false)
		}
	}, [error, wasSubmitted, autoScroll])

	const onSubmit = useCallback(() => {
		if (!isSubmitting) {
			dispatch(validateRiskAssessmentForm())
			setWasSubmitted(true)
		}
	}, [dispatch, isSubmitting])

	return (
		<div ref={ref} className="flex flex-col h-full">
			<RiskAssessmentBanner />
			{shouldStopWork && <StopWorkPrompt />}
			{showConfirmationPrompt && <AssessmentConfirmationPrompt />}

			<LocationPoller intervalMs={5000} />
			<LocationPrompt />

			<SafeForm className="p-6 flex-1 overflow-auto" onSubmit={onSubmit}>
				{error && (
					<>
						<div className=" border-error border-3 mb-6 pl-1">
							<div className="body-sm">Please check the form</div>
							<div className="body-sm text-error underline">{error}</div>
						</div>
					</>
				)}

				{questions.map((question) => (
					<RiskAssessmentQuestion
						question={question}
						key={question.questionId}
					/>
				))}
				<AdditionalInformation />

				<LocationWarning />

				<div className="flex flex-col items-center mb-6">
					<button
						className={`btn-primary mt-4 ${isSubmitting ? 'opacity-50' : ''}`}
						disabled={
							isSubmitting || location.result === UserLocationResult.None
						}
					>
						Submit
					</button>
					<button
						className="btn-secondary mt-8"
						onClick={(e) => {
							e.preventDefault()
							dispatch(setAssessmentStage(ASSESSMENT_STAGE_INITIAL))
						}}
					>
						Cancel
					</button>
				</div>
			</SafeForm>
		</div>
	)
}

export default withRedirectRoute(
	{
		to: '/assessment',
		push: false,
	},
	() => useSelector(selectHasFlow),
	RiskAssessmentFlow
)
