import { replace } from 'connected-react-router'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '../../../components/Dialog'
import { selectConfig } from '../../../redux/incident/config/selectors'
import {
	showIncidentReportCallPrompt,
	resetIncidentReport,
} from '../../../redux/incident/submission/actions'
import { selectUserProfile } from '../../../redux/userProfile/selectors'
import {
	getHeadOfOperations,
	getManagingDirector,
	getLocationRepresentative,
} from '../../profile/lookupContact'
import { IncidentType } from '../data/IncidentTypes'

const CallPrompt: React.FunctionComponent = () => {
	const dispatch = useDispatch()
	const [disabled, setDisabled] = useState(false)
	const profile = useSelector(selectUserProfile)
	const { incidentType, operatingUnit, primaryWorkLocation } = useSelector(
		selectConfig
	)

	const shouldUseManagingDirector = incidentType === IncidentType.MajorInjury

	const { name, phoneNumber } = useMemo(() => {
		if (
			profile === null ||
			(operatingUnit.length > 0 && primaryWorkLocation.length > 0)
		) {
			return getLocationRepresentative(operatingUnit, primaryWorkLocation)
		}

		if (shouldUseManagingDirector) {
			return getManagingDirector(profile.operatingUnit)
		}

		return getLocationRepresentative(
			profile.operatingUnit,
			profile.primaryWorkLocation
		)
	}, [profile, shouldUseManagingDirector, operatingUnit, primaryWorkLocation])

	const onClose = useCallback(() => {
		dispatch(replace('/'))
		dispatch(showIncidentReportCallPrompt(false))
		dispatch(resetIncidentReport())
	}, [dispatch])

	return (
		<Dialog title="Call" bannerColorClass="secondary-2">
			<div className="m-4 mt-0">
				{!shouldUseManagingDirector && (
					<p className="text-primary-1 mt-4">
						You may wish to call your HSE representative to inform them of this
						incident.
					</p>
				)}
				{shouldUseManagingDirector && (
					<p className="text-primary-1 mt-4">
						Please call the managing director to inform them of this major
						incident.
					</p>
				)}
				<p className="text-center text-primary-1 mt-4">
					Name: {name} <br />
					Telephone: {phoneNumber}
				</p>
			</div>
			<div className="flex justify-center mt-4 mb-4">
				<button
					aria-label="Call"
					className="btn-primary-popup mr-3 disabled:opacity-50"
					disabled={disabled}
					onClick={() => {
						setDisabled(true)
						onClose()

						window.open(`tel:${phoneNumber}`, '_blank')
					}}
				>
					Call
				</button>
				{!shouldUseManagingDirector && (
					<button
						aria-label="Cancel"
						className="btn-secondary-popup"
						onClick={onClose}
					>
						Cancel
					</button>
				)}
			</div>
		</Dialog>
	)
}

export default CallPrompt
