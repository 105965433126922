import Action from '../Action'
import { put, select, takeEvery } from '@redux-saga/core/effects'
import { CHECK_AND_RESET_OFFLINE_ASSESSMENTS_AND_INCIDENTS } from './actionTypes'
import { selectOfflineEmail } from './selectors'
import { setEmailAddress } from './action'
import { resetOfflineRiskAssessments } from './assessment/actions'
import { resetOfflineIncidentReports } from './incident/action'

export function* checkAndResetOfflineMode(
	action: Action<string>
): Generator<unknown, void, unknown> {
	const email = action.payload as string
	const offlineEmail = (yield select(selectOfflineEmail) as unknown) as string

	if (email !== offlineEmail) {
		yield put(resetOfflineRiskAssessments())
		yield put(resetOfflineIncidentReports())
	}

	yield put(setEmailAddress(email))
}

export default [
	takeEvery(
		CHECK_AND_RESET_OFFLINE_ASSESSMENTS_AND_INCIDENTS,
		checkAndResetOfflineMode
	),
]
