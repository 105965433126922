import { SelectOptions } from '../../../components/Selector'
import { formatDate } from '../../../format/date'

export const getDates = (): SelectOptions => {
	const dateList: SelectOptions = []
	for (let day = 0; day > -5; day--) {
		const futureDate = new Date()
		futureDate.setDate(futureDate.getDate() + day)

		const formatted = formatDate(futureDate)
		dateList.push({ value: formatted, label: formatted })
	}
	return dateList
}

export const getIncidentDates = (date: string): string => {
	let selectedDate = getDates()[0].value
	const filteredDate = getDates().filter((d) => d.value === date)
	selectedDate = filteredDate.length > 0 ? filteredDate[0].value : selectedDate
	return selectedDate
}
