import React from 'react'

interface Props {
	status: StatusType
}

export enum StatusType {
	Submitted = 0,
	Pending = 1,
	PendingOffline = 2,
	Saved = 3,
	PendingError = 4,
}

export const Status: React.FunctionComponent<Props> = ({ status }: Props) => {
	switch (status) {
		case StatusType.Submitted: {
			return <div className="text-secondary-11 body-base">Sent</div>
		}
		case StatusType.Pending: {
			return <div className="text-primary-1 body-base">Pending</div>
		}
		case StatusType.PendingOffline: {
			return <div className="text-primary-1 body-base">Pending (offline)</div>
		}
		case StatusType.Saved: {
			return <div className="text-primary-1 body-base">Saved</div>
		}
		case StatusType.PendingError: {
			return <div className="text-error body-base">Pending (error)</div>
		}
		default:
			return <div className="text-primary-1 body-base">Unknown</div>
	}
}
