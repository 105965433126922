import React from 'react'
import { IconComponent, wrapIcon, calculateStrokeWidth } from './IconComponent'

const KeypadIcon: IconComponent = wrapIcon((props) => (
	<svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<circle
			cx="256"
			cy="448"
			r="32"
			style={{
				strokeMiterlimit: 10,
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<circle
			cx="256"
			cy="320"
			r="32"
			style={{
				strokeMiterlimit: 10,
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<path
			d="M288,192a32,32,0,1,1-32-32A32,32,0,0,1,288,192Z"
			style={{
				strokeMiterlimit: 10,
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<circle
			cx="256"
			cy="64"
			r="32"
			style={{
				strokeMiterlimit: 10,
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<circle
			cx="384"
			cy="320"
			r="32"
			style={{
				strokeMiterlimit: 10,
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<circle
			cx="384"
			cy="192"
			r="32"
			style={{
				strokeMiterlimit: 10,
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<circle
			cx="384"
			cy="64"
			r="32"
			style={{
				strokeMiterlimit: 10,
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<circle
			cx="128"
			cy="320"
			r="32"
			style={{
				strokeMiterlimit: 10,
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<circle
			cx="128"
			cy="192"
			r="32"
			style={{
				strokeMiterlimit: 10,
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
		<circle
			cx="128"
			cy="64"
			r="32"
			style={{
				strokeMiterlimit: 10,
				strokeWidth: calculateStrokeWidth(props.thickness),
			}}
		/>
	</svg>
))

export default KeypadIcon
