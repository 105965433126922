export const ADD_OFFLINE_RISK_ASSESSMENT = 'ADD_OFFLINE_RISK_ASSESSMENT'
export const DELETE_OFFLINE_RISK_ASSESSMENT = 'DELETE_OFFLINE_RISK_ASSESSMENT'
export const OFFLINE_RISK_ASSESSMENT_SUBMISSION_FAILED =
	'OFFLINE_RISK_ASSESSMENT_SUBMISSION_FAILED'
export const TRIGGER_ASSESSMENT_AUTO_SUBMISSION =
	'TRIGGER_ASSESSMENT_AUTO_SUBMISSION'
export const RESET_OFFLINE_RISK_ASSESSMENTS = 'RESET_OFFLINE_RISK_ASSESSMENTS'

export const UPSERT_SAVED_RISK_ASSESSMENT = 'UPSERT_SAVED_RISK_ASSESSMENT'
export const DELETE_SAVED_RISK_ASSESSMENT = 'DELETE_SAVED_RISK_ASSESSMENT'
