import { combineReducers } from 'redux'
import config from './config/reducer'
import details from './details/reducer'
import submission from './submission/reducer'
import recentIncidentReports from './recentIncidents/reducer'
import {
	SET_INCIDENT_STAGE,
	RESET_INCIDENT_STAGE,
	SET_INCIDENT_ID,
	RESET_INCIDENT_ID,
} from './actionTypes'
import { IncidentStage, INCIDENT_STAGE_CONFIG } from './IncidentStage'
import Action from '../Action'

const initialStage: IncidentStage = INCIDENT_STAGE_CONFIG
const initialId = ''

export const stage = (
	state = initialStage,
	action: Action<unknown>
): IncidentStage => {
	switch (action.type) {
		case SET_INCIDENT_STAGE: {
			return action.payload as IncidentStage
		}
		case RESET_INCIDENT_STAGE: {
			return initialStage
		}
		default: {
			return state
		}
	}
}

export const id = (state = initialId, action: Action<unknown>): string => {
	switch (action.type) {
		case SET_INCIDENT_ID: {
			return action.payload as string
		}
		case RESET_INCIDENT_ID: {
			return initialId
		}
		default: {
			return state
		}
	}
}

const incident = combineReducers({
	stage,
	id,
	config,
	details,
	submission,
	recentIncidentReports,
})

export default incident
