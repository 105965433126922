import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
	resetIncidentId,
	resetIncidentStage,
} from '../../redux/incident/actions'
import { resetIncidentConfig } from '../../redux/incident/config/actions'
import { resetIncidentDetails } from '../../redux/incident/details/actions'
import { selectSaveableIncidentReport } from '../../redux/incident/selectors'
import { upsertSavedIncidentReport } from '../../redux/offline/incident/action'

const AutoSaveIncidentReport: React.FunctionComponent = () => {
	const dispatch = useDispatch()
	const previousRoute = useRef('')
	const savedIncident = useSelector(selectSaveableIncidentReport)
	const currentRoute = useLocation().pathname

	useEffect(() => {
		const routeChanged =
			previousRoute.current === '/incident' && currentRoute !== '/incident'

		if (routeChanged) {
			const hasIncidentType = savedIncident.config.incidentType.length > 0

			if (hasIncidentType) {
				dispatch(upsertSavedIncidentReport(savedIncident))
				dispatch(resetIncidentStage())
				dispatch(resetIncidentId())
				dispatch(resetIncidentConfig())
				dispatch(resetIncidentDetails())
			}
		}

		previousRoute.current = currentRoute
	}, [currentRoute, dispatch, savedIncident])
	return null
}

export default AutoSaveIncidentReport
