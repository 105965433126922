import {
	getIncidentLocations,
	getIncidentSubLocations,
} from '../data/IncidentLocation'
import {
	IncidentType,
	incidentTypeList,
	IncidentTypeOrEmpty,
	incidentNatureAppearanceList,
} from './IncidentTypes'
import {
	getPersonInvolved,
	IncidentPersonInvolved,
} from '../data/PersonInvolved'
import { getIncidentIllnesses } from '../data/IncidentIllnesses'
import { getIncidentInjuryTypes } from './IncidentInjuryTypes'
import {
	validateLocation,
	validateSubLocation,
	validatePersonInvolved,
	validateNameOfPerson,
	validateInjuryType,
	validateNatureOfIncident,
	validateNatureOfIllness,
	validateAnswer,
	validatePhotos,
} from '../details/validation'
import { SelectOptions } from '../../../components/Selector'
import { getValue } from '../details/error'
import {
	DetailsState,
	DetailsStateKeys,
} from '../../../redux/incident/details/DetailsState'
import { IconComponent } from '../../../icons/IconComponent'
import LocationIcon from '../../../icons/LocationIcon'
import PersonIcon from '../../../icons/PersonIcon'

export enum QuestionType {
	Selector = 'FormField.Selector',
	Input = 'FormField.Input',
	Textual = 'Textual',
	Options = 'Options',
	Photos = 'Photos',
	UserSelect = 'UserSelect',
}

export interface Question {
	type: IncidentTypeOrEmpty[]
	component: QuestionType
	icon?: IconComponent
	name?: string
	placeholder?: string
	css: string
	prompt?: string
	data?: SourceCall
	availableOptions?: string[]
	value: DetailsStateKeys
	error: string
	validation?: ValidationAnswers
	conditionQuestion?: Condition
}

export interface MethodCall {
	param: string
}

export interface SourceCall extends MethodCall {
	method: (name: string) => SelectOptions
}

export interface ValidationAnswers extends MethodCall {
	method: (name: number) => string
}

export interface Condition {
	favourableAnswer: string
	equals?: string
	notEquals?: string
	offline?: boolean
}

export const getQuestions = (incidentType: IncidentTypeOrEmpty): Question[] =>
	incidentQuestionList.filter((f) => f.type.includes(incidentType))

export const getSelectionOptions = (
	question: Question,
	allValues: DetailsState,
	incidentType: string
): SelectOptions => {
	let options: SelectOptions = []
	if (question.data) {
		const paramValue =
			question.data?.param === 'incidentType'
				? incidentType
				: getValue(allValues, question.data?.param)
		options = question.data.method(paramValue)
	}

	return options
}

export const getNatureOfIncidentText = (incidentType: IncidentType): string => {
	switch (incidentType) {
		case IncidentType.MajorInjury:
		case IncidentType.MinorInjury:
		case IncidentType.Environmental:
			return 'Nature of incident'
		case IncidentType.NearMiss:
			return 'Nature of near miss'
		case IncidentType.DangerousOccurrence:
			return 'Nature of dangerous occurrence'
		default:
			return ''
	}
}

const incidentQuestionList: Question[] = [
	{
		type: incidentTypeList,
		component: QuestionType.Selector,
		icon: LocationIcon,
		name: 'location',
		placeholder: 'Location',
		css: 'pl-5 mt-0',
		data: { method: getIncidentLocations, param: '' },
		value: 'location',
		error: 'locationError',
		validation: { method: validateLocation, param: 'location' },
	},
	{
		type: incidentTypeList,
		component: QuestionType.Selector,
		icon: LocationIcon,
		name: 'subLocation',
		placeholder: 'Sub location',
		css: 'pl-5 mt-0',
		data: { method: getIncidentSubLocations, param: 'location' },
		value: 'subLocation',
		error: 'subLocationError',
		validation: { method: validateSubLocation, param: 'subLocation' },
		conditionQuestion: { favourableAnswer: 'location' },
	},
	{
		type: incidentTypeList,
		component: QuestionType.Selector,
		name: 'personInvolved',
		placeholder: 'Person involved',
		css: 'ml-3 pl-10',
		data: { method: getPersonInvolved, param: 'incidentType' },
		value: 'personInvolved',
		error: 'personInvolvedError',
		validation: { method: validatePersonInvolved, param: 'personInvolved' },
	},
	{
		type: incidentTypeList,
		component: QuestionType.UserSelect,
		icon: PersonIcon,
		name: 'personName',
		placeholder: 'Name of person',
		css: 'pl-5 mt-0',
		value: 'personName',
		error: 'personNameError',
		validation: { method: validateNameOfPerson, param: 'personName' },
		conditionQuestion: {
			favourableAnswer: 'personInvolved',
			equals: IncidentPersonInvolved.Employee,
			offline: false,
		},
	},
	{
		type: incidentTypeList,
		component: QuestionType.Input,
		icon: PersonIcon,
		name: 'personName',
		placeholder: 'Name of person',
		css: 'pl-5 mt-0',
		value: 'personName',
		error: 'personNameError',
		validation: { method: validateNameOfPerson, param: 'personName' },
		conditionQuestion: {
			favourableAnswer: 'personInvolved',
			equals: IncidentPersonInvolved.Employee,
			offline: true,
		},
	},
	{
		type: incidentTypeList,
		component: QuestionType.Input,
		icon: PersonIcon,
		name: 'personName',
		placeholder: 'Name of person',
		css: 'pl-5 mt-0',
		value: 'personName',
		error: 'personNameError',
		validation: { method: validateNameOfPerson, param: 'personName' },
		conditionQuestion: {
			favourableAnswer: 'personInvolved',
			notEquals: IncidentPersonInvolved.Employee,
		},
	},
	{
		type: incidentNatureAppearanceList,
		component: QuestionType.Textual,
		css: 'p-4 mt-2',
		value: 'natureOfIncident',
		error: 'natureOfIncidentError',
		validation: { method: validateNatureOfIncident, param: 'natureOfIncident' },
	},
	{
		type: [IncidentType.Illness],
		component: QuestionType.Selector,
		name: 'illness',
		placeholder: 'Illness',
		css: 'ml-3 pl-10',
		data: { method: getIncidentIllnesses, param: '' },
		value: 'natureOfIncident',
		error: 'natureOfIncident',
		validation: { method: validateNatureOfIllness, param: 'natureOfIncident' },
	},
	{
		type: [IncidentType.Environmental],
		component: QuestionType.Options,
		css: 'pl-4 pt-4',
		prompt: 'Is the environmental incident notifiable to regulatory authority?',
		availableOptions: ['Yes', 'No'],
		value: 'environmentalIncident',
		error: 'environmentalIncidentError',
		validation: { method: validateAnswer, param: 'environmentalIncident' },
	},
	{
		type: [IncidentType.MajorInjury, IncidentType.MinorInjury],
		component: QuestionType.Options,
		css: 'pl-4',
		prompt: 'Was there an injury?',
		availableOptions: ['Yes', 'No'],
		value: 'wasInjured',
		error: 'wasInjuredError',
		validation: { method: validateAnswer, param: 'wasInjured' },
	},
	{
		type: [IncidentType.MajorInjury, IncidentType.MinorInjury],
		component: QuestionType.Selector,
		name: 'injuryType',
		placeholder: 'Type of injury',
		css: 'ml-4 pl-10',
		data: { method: getIncidentInjuryTypes, param: '' },
		value: 'injuryType',
		error: 'injuryTypeError',
		validation: { method: validateInjuryType, param: 'injuryType' },
		conditionQuestion: { favourableAnswer: 'wasInjured', equals: 'Yes' },
	},
	{
		type: [IncidentType.MajorInjury, IncidentType.MinorInjury],
		component: QuestionType.Options,
		css: 'pl-4 pt-6',
		prompt: 'Was treatment required?',
		availableOptions: ['Yes', 'No'],
		value: 'treatmentRequired',
		error: 'treatmentRequiredError',
		validation: { method: validateAnswer, param: 'treatmentRequired' },
		conditionQuestion: { favourableAnswer: 'wasInjured', equals: 'Yes' },
	},
	{
		type: [IncidentType.Illness],
		component: QuestionType.Options,
		css: 'pl-4 pt-6',
		prompt: 'Was treatment required?',
		availableOptions: ['Yes', 'No'],
		value: 'treatmentRequired',
		error: 'treatmentRequiredError',
		validation: { method: validateAnswer, param: 'treatmentRequired' },
	},
	{
		type: [
			IncidentType.MajorInjury,
			IncidentType.MinorInjury,
			IncidentType.Illness,
		],
		component: QuestionType.Options,
		css: 'pl-4 pt-4',
		prompt: 'What treatment was required?',
		availableOptions: ['First aid', 'Paramedic', 'Hospital'],
		value: 'treatmentType',
		error: 'treatmentTypeError',
		validation: { method: validateAnswer, param: 'treatmentType' },
		conditionQuestion: { favourableAnswer: 'treatmentRequired', equals: 'Yes' },
	},
	{
		type: [
			IncidentType.MajorInjury,
			IncidentType.MinorInjury,
			IncidentType.Illness,
		],
		component: QuestionType.Options,
		css: 'pl-4 pt-4',
		prompt: 'Do you wish to upload photos?',
		availableOptions: ['Yes', 'No'],
		value: 'uploadPhoto',
		error: 'uploadPhotoError',
		validation: { method: validateAnswer, param: 'uploadPhoto' },
	},
	{
		type: [
			IncidentType.MajorInjury,
			IncidentType.MinorInjury,
			IncidentType.Illness,
		],
		component: QuestionType.Photos,
		css: 'pl-4',
		value: 'photos',
		error: 'photosError',
		validation: { method: validatePhotos, param: 'photos' },
		conditionQuestion: { favourableAnswer: 'uploadPhoto', equals: 'Yes' },
	},
	{
		type: [
			IncidentType.MajorInjury,
			IncidentType.MinorInjury,
			IncidentType.Illness,
		],
		component: QuestionType.Options,
		css: 'pl-4 pt-4',
		prompt: 'Have you recorded additional media?',
		availableOptions: [
			'Yes (This will need to be transferred separately)',
			'No',
		],
		value: 'additionalMedia',
		error: 'additionalMediaError',
		validation: { method: validateAnswer, param: 'additionalMedia' },
	},
	{
		type: incidentTypeList,
		component: QuestionType.Textual,
		css: 'p-4 mt-2',
		prompt: 'Additional Information',
		value: 'additionalInformation',
		error: 'additionalInformationError',
	},
]
