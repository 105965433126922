import { takeEvery, put, select, call, take, all } from 'redux-saga/effects'
import {
	CLOSE_LOCATION_PROMPT,
	SHOW_LOCATION_PROMPT,
	SUBMIT_RISK_ASSESSMENT,
	VALIDATE_RISK_ASSESSMENT_FORM,
} from './actionTypes'
import { validateRiskAssessmentSaga } from './submission/validationSaga'
import {
	setRiskAssessmentSubmissionError,
	showConfirmationPrompt,
	setRiskAssessmentSubmitting,
	showLocationPrompt,
} from './actions'
import RootState from '../../RootState'
import { RiskAssessmentFlowState } from './FlowState'
import generateAnswers from './submission/generateAnswers'
import RiskAssessmentSubmission from './submission/RiskAssessmentSubmission'
import { submitAssessment } from '../../../services/assessment/assessmentService'
import { MetadataValues } from '../metadata/MetadataState'
import { replace } from 'connected-react-router'
import { selectRiskAssessmentFlow, selectShouldStopWork } from './selectors'
import { serviceRequestSaga } from '../../services/sagas'
import { setAssessmentStage } from '../actions'
import { ASSESSMENT_STAGE_CONFIG } from '../AssessmentStage'
import {
	UserLocation,
	UserLocationResult,
} from '../../../services/location/locationService'
import { selectIsWorkingOffline } from '../../users/selectors'
import {
	addRiskAssessmentSubmittedOffline,
	deleteSavedRiskAssessment,
	upsertSavedRiskAssessment,
} from '../../offline/assessment/actions'
import RiskAssessmentOfflineSubmission from '../../offline/assessment/RiskAssessmentOffline'
import { SavedRiskAssessment } from '../../offline/assessment/RiskAssessmentsOfflineState'
import { selectSaveableRiskAssessment } from '../selectors'
import { logError } from '../../../services/Error/errorService'
import { selectMetadataValues } from '../metadata/selectors'
import { resetMetadataValues } from '../metadata/actions'
import { resetAssessmentStage } from '../actions'
import { getDate } from '../../../testing/date'

export function* submitRiskAssessmentSaga(): Generator<unknown, void, unknown> {
	yield put(setRiskAssessmentSubmitting(true))
	yield put(setRiskAssessmentSubmissionError(null))
	const stopWork = (yield select(selectShouldStopWork)) as boolean

	let hasError = false

	if (!stopWork) {
		yield* validateRiskAssessmentSaga((error) => {
			hasError = error
		})
	}

	if (hasError) {
		yield put(
			setRiskAssessmentSubmissionError('Please fill in the required fields')
		)

		yield put(setRiskAssessmentSubmitting(false))
		return
	}

	const {
		questionBlock,
		questions,
		documentation,
		additionalInformation,
		coordinates,
	} = (yield select(
		selectRiskAssessmentFlow
	) as unknown) as RiskAssessmentFlowState

	const metadataValues = (yield select(
		selectMetadataValues
	) as unknown) as MetadataValues

	const answers = generateAnswers(questions, documentation)
	const submission: RiskAssessmentSubmission = {
		answers,
		additionalInformation: additionalInformation.value,
		metadata: metadataValues,
		questionsId: questionBlock.id,
		coordinates,
	}

	const isOffline = (yield select(selectIsWorkingOffline) as unknown) as boolean

	try {
		if (isOffline) {
			const offlineSubmission: RiskAssessmentOfflineSubmission = {
				submissionDetails: submission,
				submissionDate: getDate(),
				stopWork,
			}

			yield put(addRiskAssessmentSubmittedOffline(offlineSubmission))
		} else {
			yield call(serviceRequestSaga as never, submitAssessment, submission)
		}

		yield put(deleteSavedRiskAssessment(submission.metadata.localId))
		yield all([put(resetMetadataValues()), put(resetAssessmentStage())])
		yield put(replace('/'))
	} catch (e) {
		logError({
			message: e.message,
			name: e.name,
		})
		const errorDescription = isOffline
			? 'Failed to save offline risk assessment - '
			: 'Failed to submit risk assessment - '
		yield put(setRiskAssessmentSubmissionError(errorDescription + e.message))
	} finally {
		yield put(setRiskAssessmentSubmitting(false))
	}
}

export function* closeLocationPromptSaga(): Generator<unknown, void, never> {
	yield all([take(SHOW_LOCATION_PROMPT), put(showLocationPrompt(false))])

	const savedAssessment = (yield select(
		selectSaveableRiskAssessment
	)) as SavedRiskAssessment

	yield put(upsertSavedRiskAssessment(savedAssessment))

	yield put(replace('/'))
	yield put(setAssessmentStage(ASSESSMENT_STAGE_CONFIG))
}

function* validateRiskAssessmentFormSaga() {
	yield put(setRiskAssessmentSubmissionError(null))

	let hasError = false

	yield* validateRiskAssessmentSaga((error) => {
		hasError = error
	})

	if (hasError) {
		yield put(
			setRiskAssessmentSubmissionError('Please fill in the required fields')
		)

		yield put(showConfirmationPrompt(false))
		return
	}

	const location = (yield select(
		(state) => (state as RootState).assessment.flow?.coordinates
	) as unknown) as UserLocation

	if (location.result === UserLocationResult.PermissionDenied) {
		yield put(showLocationPrompt(true))
		return
	}

	yield put(showConfirmationPrompt(true))
}

export default [
	takeEvery(CLOSE_LOCATION_PROMPT, closeLocationPromptSaga),
	takeEvery(SUBMIT_RISK_ASSESSMENT, submitRiskAssessmentSaga),
	takeEvery(VALIDATE_RISK_ASSESSMENT_FORM, validateRiskAssessmentFormSaga),
]
