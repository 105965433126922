import { SelectOptions } from '../../../components/Selector'

export const getIncidentTypes = (): SelectOptions =>
	incidentTypeList.map((incidentType) => ({
		value: incidentType,
		label: incidentType.length ? incidentType : 'Incident type',
	}))

export const displayIncidentCause = (currentType: string): boolean => {
	if (!Object.keys(IncidentType).includes(currentType) === undefined) {
		// Ideally everything that's an incident class should be of type IncidentClass, but refactoring everything at once
		// is almost always a bad idea so I'll stop here for now. This type check keeps changes so far from blowing up the compiler.
		throw new TypeError(
			`Parameter ${currentType} must be a valid IncidentClass.`
		)
	}

	return incidentCauseAppearanceList.includes(currentType as IncidentType)
}

export enum IncidentType {
	DangerousOccurrence = 'Dangerous Occurrence',
	Environmental = 'Environmental',
	Illness = 'Illness',
	MajorInjury = 'Major Injury',
	MinorInjury = 'Minor Injury',
	NearMiss = 'Near Miss',
	Observation = 'Observation',
}

export type IncidentTypeOrEmpty = IncidentType | ''

export const incidentTypeList: IncidentTypeOrEmpty[] = [
	'',
	...Object.values(IncidentType),
]

const incidentCauseAppearanceList: IncidentType[] = [
	IncidentType.DangerousOccurrence,
	IncidentType.Environmental,
	IncidentType.Illness,
	IncidentType.MajorInjury,
	IncidentType.MinorInjury,
	IncidentType.NearMiss,
]

export const incidentNatureAppearanceList: IncidentType[] = [
	IncidentType.DangerousOccurrence,
	IncidentType.Environmental,
	IncidentType.MajorInjury,
	IncidentType.MinorInjury,
	IncidentType.NearMiss,
]
