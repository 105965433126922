import Action from '../../Action'
import {
	ADD_OFFLINE_RISK_ASSESSMENT,
	DELETE_OFFLINE_RISK_ASSESSMENT,
	DELETE_SAVED_RISK_ASSESSMENT,
	OFFLINE_RISK_ASSESSMENT_SUBMISSION_FAILED,
	UPSERT_SAVED_RISK_ASSESSMENT,
	TRIGGER_ASSESSMENT_AUTO_SUBMISSION,
	RESET_OFFLINE_RISK_ASSESSMENTS,
} from './actionTypes'
import RiskAssessmentOfflineSubmission from './RiskAssessmentOffline'
import { SavedRiskAssessment } from './RiskAssessmentsOfflineState'

export const addRiskAssessmentSubmittedOffline = (
	assessment: RiskAssessmentOfflineSubmission
): Action<RiskAssessmentOfflineSubmission> => ({
	type: ADD_OFFLINE_RISK_ASSESSMENT,
	payload: assessment,
})

export const deleteRiskAssessmentSubmittedOffline = (
	localId: string
): Action<string> => ({
	type: DELETE_OFFLINE_RISK_ASSESSMENT,
	payload: localId,
})

export const offlineRiskAssessmentSubmissionFailed = (
	localId: string
): Action<string> => ({
	type: OFFLINE_RISK_ASSESSMENT_SUBMISSION_FAILED,
	payload: localId,
})

export const triggerAssessmentAutoSubmission = (): Action => ({
	type: TRIGGER_ASSESSMENT_AUTO_SUBMISSION,
})

export const resetOfflineRiskAssessments = (): Action => ({
	type: RESET_OFFLINE_RISK_ASSESSMENTS,
})

export const upsertSavedRiskAssessment = (
	assessment: SavedRiskAssessment
): Action<SavedRiskAssessment> => ({
	type: UPSERT_SAVED_RISK_ASSESSMENT,
	payload: assessment,
})

export const deleteSavedRiskAssessment = (localId: string): Action<string> => ({
	type: DELETE_SAVED_RISK_ASSESSMENT,
	payload: localId,
})
