import Profile from '../../screens/profile/Profile'
import { formSerialise } from '../../serialisation/formSerialise'
import { AuthTokenResult } from '../authentication/authenticate'
import { getEnvironmentVariables } from '../authentication/getEnvironmentVariables'
import { logError } from '../Error/errorService'
import { authenticatedFetch } from '../fetch/fetchService'
import getAPIUrl from '../getAPIUrl'

const getProfileUrl = () => getAPIUrl('profile')

export const insertProfile = async (
	profile: Profile,
	password: string
): Promise<void> => {
	const { audience, clientId, tokenUrl } = getEnvironmentVariables()

	const details = {
		grant_type: 'password',
		audience,
		client_id: clientId,
		username: profile.email,
		password,
		scope: 'offline_access',
	}

	const tokenResponse = await fetch(tokenUrl, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
		},
		body: formSerialise(details),
	})

	if (!tokenResponse.ok) {
		const { error_description } = await tokenResponse.json()
		logError({
			message: error_description.message,
			name: 'Insert Profile token Error',
		})
		throw new Error(error_description.message)
	}

	const { access_token } = (await tokenResponse.json()) as AuthTokenResult

	const response = await fetch(getAPIUrl('insertProfile'), {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${access_token}`,
		},
		body: JSON.stringify(profile),
	})

	if (!response.ok) {
		const responseJson = await response.json()
		logError({
			message: responseJson.message,
			name: 'Insert Profile Error',
		})
		throw new Error(responseJson.message)
	}
}

export const upsertProfile = async (profile: Profile): Promise<void> => {
	const response = await authenticatedFetch(getProfileUrl(), {
		method: 'POST',
		body: JSON.stringify(profile),
	})
	if (!response.ok) {
		const result = await response.json()
		logError({
			message: result.message,
			name: 'Upsert Profile Error',
		})
		throw new Error(result.message)
	}
}

export const getProfile = async (): Promise<Profile> => {
	const response = await authenticatedFetch(getProfileUrl(), {
		method: 'GET',
	})
	const json = await response.json()
	if (!response.ok) {
		throw new Error(json.message)
	}
	return json as Profile
}
